import * as Yup from "yup";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentRequestPaymentMethodEvent, PaymentRequest } from "@stripe/stripe-js";
import { SubscriptionGateway, SubscriptionPlan } from "authory-api-types/dist/enums";
import { BillingSessionQueryParams, CreatePaymentIntentBodyParams, PaymentIntentResponse } from "authory-api-types/dist/types";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { getSubscriptionPaymentIntent, getSubscriptionPaymentIntentNoSession } from "../api/payments";
import { CookieContext } from "../context/cookieContext";
import { logError } from "../logging/logSentryError";

export type V3PaymentFormik = {
    subscriptionPlan: SubscriptionPlan,
    subscriptionGateway?: SubscriptionGateway,
    cardnumber: string,
    expDate: string,
    cvc: string,
    stripeCardToken?: string,
    defaultPaymentMethod?: SubscriptionGateway | null,
    isPayPalMigrationFlow?: boolean,
    migrationFlowKey?: string,
}

export const useStripePayment = (
    userSlug: string,
    onFormSubmitHandler: (data: V3PaymentFormik) => Promise<null | undefined | void>,
    onRequestComplete?: () => void,
    defaultPaymentMethod: SubscriptionGateway | undefined | null = SubscriptionGateway.Stripe,
    isPayPalMigrationFlow = false,
    migrationFlowKey = "",
    isSignup = false,
    trackEvent: (eventName: string, data: any) => void = () => { }
) => {
    const stripe = useStripe();
    const token = useContext(CookieContext);
    const elements = useElements();
    const [fetchingStripeData, setFetchingStripeData] = useState(false);
    const [showApplePay, setShowApplePay] = useState(false);
    const [showGooglePay, setShowGooglePay] = useState(false);
    const [paymentOptionsLoading, setPaymentOptionsLoading] = useState(true);
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

    const formik = useFormik<V3PaymentFormik>({
        initialValues: {
            subscriptionPlan: SubscriptionPlan.yearly,
            subscriptionGateway: defaultPaymentMethod ? defaultPaymentMethod : undefined,
            cardnumber: "", // Used for displaying errors only, controlled by stripe
            expDate: "", // Used for displaying errors only, controlled by stripe
            cvc: "", // Used for displaying errors only, controlled by stripe
        },
        onSubmit: async (data, { setFieldError }) => {
            try {
                if (data.subscriptionGateway === SubscriptionGateway.ApplePay || data.subscriptionGateway === SubscriptionGateway.GooglePay) {
                    if (paymentRequest) paymentRequest.show();
                }
                else {
                    if (data.subscriptionGateway === SubscriptionGateway.Stripe && stripe !== null && elements !== null) {
                        const el = elements.getElement(CardNumberElement);

                        setFetchingStripeData(true)

                        if (el !== null) {
                            let chargeBeePaymentIntent: PaymentIntentResponse | undefined;

                            if (!isPayPalMigrationFlow) {
                                const createIntentParams = new CreatePaymentIntentBodyParams();
                                createIntentParams.subscriptionGateway = formik.values.subscriptionGateway!;

                                chargeBeePaymentIntent = await getSubscriptionPaymentIntent({
                                    token: token!,
                                    userSlug: userSlug,
                                    params: createIntentParams
                                });
                            } else {
                                const noSessionParams = new BillingSessionQueryParams();
                                noSessionParams.key = migrationFlowKey;

                                chargeBeePaymentIntent = await getSubscriptionPaymentIntentNoSession({ params: noSessionParams });
                            }

                            try {
                                // Confirm the PaymentIntent without handling potential next actions (yet).
                                const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                                    chargeBeePaymentIntent.clientSecret,
                                    { payment_method: { card: el }, setup_future_usage: "off_session" },
                                );

                                if (confirmError) {

                                    trackEvent("payment_set_up_error_frontend", {
                                        isSignup,
                                        code: confirmError.code,
                                        decline_code: confirmError.decline_code,
                                        message: confirmError.message,
                                        type: confirmError.type,
                                    });

                                    switch (confirmError.decline_code) {
                                        case "incomplete_number":
                                        case "invalid_number":
                                            setFieldError("cardnumber", confirmError.message!);
                                            break;
                                        case "incomplete_expiry":
                                        case "invalid_expiry_year_past":
                                            setFieldError("expDate", confirmError.message!);
                                            break;
                                        case "incomplete_cvc":
                                            setFieldError("cvc", confirmError.message!);
                                            break;
                                        default:
                                            setFieldError("cardnumber", confirmError.message!);
                                            break;
                                    }

                                    setFetchingStripeData(false);
                                } else {
                                    await onFormSubmitHandler({ ...formik.values, stripeCardToken: paymentIntent?.id });
                                    onRequestComplete && onRequestComplete();
                                }

                            } catch (e) {
                                setFetchingStripeData(false);
                                logError(e);
                            }
                        }
                    } else {
                        setFetchingStripeData(true);
                        await onFormSubmitHandler(data);
                    }
                }
            } catch (e) {
                logError(e);
                setFetchingStripeData(false);
            }
        },
        validationSchema: Yup.object().shape({
            subscriptionGateway: Yup.string().ensure().required("Please pick one payment method"),
        })
    });

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'DE',
                currency: 'usd',
                total: {
                    label: "Authory Subscription",
                    amount: 0,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (!result) {
                    setPaymentOptionsLoading(false);
                    return;
                }

                setPaymentRequest(pr);

                if (result.applePay) {
                    formik.setFieldValue("subscriptionGateway", SubscriptionGateway.ApplePay)
                    setShowApplePay(true);
                }

                if (result.googlePay) {
                    formik.setFieldValue("subscriptionGateway", SubscriptionGateway.GooglePay)
                    setShowGooglePay(true);
                }

                setPaymentOptionsLoading(false);
            });
        }
    }, [stripe]);

    useEffect(() => {
        if (!paymentRequest) return;

        const callback = async (ev: PaymentRequestPaymentMethodEvent) => {
            if (!stripe) return;

            setFetchingStripeData(true);

            try {
                let chargeBeePaymentIntent: PaymentIntentResponse | undefined;

                if (!isPayPalMigrationFlow) {
                    const createIntentParams = new CreatePaymentIntentBodyParams();
                    createIntentParams.subscriptionGateway = formik.values.subscriptionGateway!;

                    chargeBeePaymentIntent = await getSubscriptionPaymentIntent({
                        token: token!,
                        userSlug: userSlug,
                        params: createIntentParams
                    });
                } else {
                    const noSessionParams = new BillingSessionQueryParams();
                    noSessionParams.key = migrationFlowKey;

                    chargeBeePaymentIntent = await getSubscriptionPaymentIntentNoSession({ params: noSessionParams });
                }

                // Confirm the PaymentIntent without handling potential next actions (yet).
                const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                    chargeBeePaymentIntent.clientSecret,
                    { payment_method: ev.paymentMethod.id, setup_future_usage: "off_session" }
                );

                if (confirmError) {
                    // Report to the browser that the payment failed, prompting it to
                    // re-show the payment interface, or show an error message and close
                    // the payment interface.
                    ev.complete('fail');
                    setFetchingStripeData(false);

                } else {
                    // Report to the browser that the confirmation was successful, prompting
                    // it to close the browser payment method collection interface.
                    ev.complete('success');
                    await onFormSubmitHandler({ ...formik.values, stripeCardToken: paymentIntent?.id });
                    onRequestComplete && onRequestComplete();
                }
            } catch (e) {
                setFetchingStripeData(false);
                logError(e);
                ev.complete('fail');
            }
        };

        paymentRequest.on('paymentmethod', callback);

        return () => {
            paymentRequest.off('paymentmethod', callback);
        }
    }, [paymentRequest, formik]);

    return {
        fetchingStripeData,
        showApplePay,
        showGooglePay,
        paymentOptionsLoading,
        formik,
    }
}