import { EmbedTheme } from "../../utils/embeds";

export enum V3ProfileAvatarSizes {
    XS = "XS",
    S = "S",
    M = "M",
    L = "L",
    EMBED_NORMAL = "EMBED_NORMAL",
    EMBED_COMPACT = "EMBED_COMPACT"
}

export interface V3ProfileAvatarProps extends React.ImgHTMLAttributes<HTMLImageElement | HTMLOrSVGElement> {
    size: keyof typeof V3ProfileAvatarSizes
    embedTheme?: EmbedTheme,
    disabled?: boolean,
    authorName: string,
    hoverEffect?: boolean,
}
