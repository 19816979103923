import { AuthenticatedUserResponse, UserResponse } from "authory-api-types/dist/types";


export const getSearchFormattedSourcesOptions = (author: AuthenticatedUserResponse | UserResponse, collectionSlug?: string) => {

    let sourcesToFilter = author.sources.sources;

    if (collectionSlug) {
        const col = author.collections.find(col => col.canonicalSlug === collectionSlug);

        // Filter out sources that dont have results if not godmode
        if (!("isGodMode" in author) || !author.isGodMode) {
            sourcesToFilter = sourcesToFilter.filter(source => col?.sources.find(src => src === source.slug));
        }
    }

    let formattedSourcesOptions = sourcesToFilter.map(source => ({ value: source.slug, text: source.publicationName || "" }));
    formattedSourcesOptions = [{ value: "", text: "All Sources" }, ...formattedSourcesOptions];

    return formattedSourcesOptions;
}