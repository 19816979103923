import { useWindowSize } from "../../hooks/useWindowSize"
import { theme } from "../../theme/theme"
import { V3TertiaryLink } from "../Button"
import { OptionalLink } from "../ReusableStyledComponents/OptionalLink"
import { UnstyledLink } from "../ReusableStyledComponents/UnstyledLink"
import { V3CaptionGrey48 } from "../ReusableStyledComponents/V3CaptionGrey48"
import { VerticalSpacing } from "../VerticalSpacing"
import { CaptionCardWrapper, DashboardCardFakeCTA, DashboardCardHeadline, DashboardCardHeadlineWrapper, DashboardCardIcon, DashboardCardWrapper } from "./V3DashboardCard.styles"
import { V3DashboardCardVariants } from "./V3DashboardCard.types"
interface V3DashboardCardProps {
    variant?: V3DashboardCardVariants,
    icon: JSX.Element,
    background?: string,
    caption: string,
    headline: string,
    tertiaryCTAIcon?: JSX.Element,
    tertiaryCTAText?: string,
    tertiaryCTAHref?: string,
    useSmallSquare?: boolean,
    captionIcon?: JSX.Element,
    captionLink?: string,
    optionalRightPanelContent?: JSX.Element,
    noShadow?: boolean,
}

export const V3DashboardCard = ({ variant = V3DashboardCardVariants.primary, icon, background, caption, headline, tertiaryCTAIcon, tertiaryCTAText, tertiaryCTAHref, useSmallSquare, captionIcon, captionLink, optionalRightPanelContent, noShadow = false }: V3DashboardCardProps) => {
    const size = useWindowSize();
    const isMobile = size.width && size.width < theme.v3.layout.sidebarLayout.mobile || false;

    const isPrimary = variant === V3DashboardCardVariants.primary;

    const Caption = () => <CaptionCardWrapper>
        <V3CaptionGrey48><strong>{caption}</strong></V3CaptionGrey48>{captionIcon}
    </CaptionCardWrapper>;

    const isZero = parseInt(headline) === 0;
    const isMobileAndZero = isMobile && isZero;

    const Content = () => <DashboardCardWrapper $useSmallSquare={useSmallSquare} variant={variant} $noShadow={noShadow || isMobileAndZero}>
        {
            !!icon && <DashboardCardIcon background={background} $useSmallSquare={useSmallSquare}>
                {icon}
            </DashboardCardIcon>
        }
        <div>
            <VerticalSpacing bottom={2}>
                {
                    !!captionLink?.length ? <UnstyledLink target="_blank" href={captionLink}>
                        <Caption />
                    </UnstyledLink> : <Caption />
                }
            </VerticalSpacing>
            <DashboardCardHeadlineWrapper variant={variant}>
                <div>
                    <DashboardCardHeadline disabled={isZero}>{headline}</DashboardCardHeadline>
                </div>
                <div>
                    {
                        isPrimary && tertiaryCTAText && !!tertiaryCTAText.length && <V3TertiaryLink
                            href={tertiaryCTAHref}
                            icon={tertiaryCTAIcon}
                            text={tertiaryCTAText}
                        />
                    }
                    {!isPrimary && <DashboardCardFakeCTA><strong>{tertiaryCTAText}</strong></DashboardCardFakeCTA>}
                    {optionalRightPanelContent}
                </div>
            </DashboardCardHeadlineWrapper>
        </div>
    </DashboardCardWrapper>

    return <div>
        <OptionalLink href={isPrimary || isMobileAndZero ? undefined : tertiaryCTAHref}>
            <Content />
        </OptionalLink>
    </div>
}