import React, { useEffect, useState } from "react"
import { useWindowSize } from "../../hooks/useWindowSize";
import { APPLICATION_ROUTES } from "../../types/routes";
import { CTASize, CTAVariant, MarketingCTA } from "../MarketingCTA";
import { DropdownMenuPanel } from "./DropdownMenuPanel";
import { MarketingLogo, MenuItemText, MenuNavWrapper, MenuPaneContentWrapper, RightSideMenuWrapper, MarketingMenuWrapper, DesktopMenu, S_BREAKPOINT, MMShowMobile, MMShowDesktop, MenuUseCaseContentWrapper, MarketingHeaderUL, MarketingHeaderTry, MarketingHeaderLi, SimplifiedMenu, MarketingSimpleHeaderButton } from "./MarketingMenu.styles"
import { SmallMenuLink } from "./SmallMenuLink";
import { MobileMenu } from "./MobileMenuPanel";
import { MarketingUseCasePanel } from "../MarketingUseCasePanel";
import { FTMARKETINGPAGES } from "../../utils/featuresMarketingPage";
import { SmallMenuPanel } from "./SmallMenuLink.styles";
import { InviteType, MarketingInviteBar } from "../MarketingInviteBar";
import { parseCookies } from "nookies";
import { CookieKeys } from "../../types/cookie-keys";
import { scrollShadowHook } from "../../hooks/scrollShadowHook";
import { Discounts } from "authory-api-types/dist/lib/Discounts";
import { UCMARKETINGPAGES } from "../../utils/useCasesMarketingPages";
import { MOREMARKETINGPAGES } from "../../utils/moreMarketingPages";
import { OptionalNextLink } from "../ReusableStyledComponents/OptionalNextLink";
import { DEFAULT_TRIAL_PERIOD } from "../../types/trial_period";
import { V3Body } from "../Typography";
import { V3Button } from "../Button";
import { V3ButtonColor } from "../Button/V3Button.types";

const ArrowSVG = () => <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5918 7L8.00298 12.5888C7.61245 12.9793 6.97928 12.9793 6.58876 12.5888L0.999939 7" stroke="#6B7380" strokeWidth="1.2" />
</svg>

interface useNextRouter {
    useNextRouter?: boolean,
    signupUTMTags?: string,
    customScrollBannerUTMTag?: string,
    customScrollBanner?: boolean,
}

export const MarketingMenu = ({ useNextRouter = true, signupUTMTags = "", customScrollBannerUTMTag = "blog", customScrollBanner = false }: useNextRouter) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const size = useWindowSize();
    const [showShadow, setShowShadow, scrollCheck] = scrollShadowHook();
    const [refererName, setRefererName] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [refererUser, setRefererUser] = useState("");
    const trialsDays = refererUser ? Discounts.getTrialPeriodForReferrer(refererUser) : DEFAULT_TRIAL_PERIOD;

    const customScrollContentEnabled = customScrollBanner && showShadow;

    useEffect(() => {
        const cookies = parseCookies();
        const name = cookies[CookieKeys.referral_name];
        const businessName = cookies[CookieKeys.business_name];

        if (name) setRefererName(name);
        if (businessName) setBusinessName(businessName);

        const userName = cookies[CookieKeys.referral_user];

        if (userName) setRefererUser(userName);
    }, []);

    if (size.width && size.width > S_BREAKPOINT && menuOpen) {
        setMenuOpen(false);
    }

    useEffect(() => {
        if (!document) return;

        const body = document.querySelector('body');
        if (body) {
            body.style.overflow = menuOpen ? "hidden" : "initial";
        }

        if (!menuOpen) setTimeout(() => scrollCheck(), 40);

        // In case this component is unmounted with menu open, we cleanup the overflow
        return () => {
            const body = document.querySelector('body');
            if (body) body.style.overflow = "initial";
        }
    }, [menuOpen]);

    const inviteType = refererName ? InviteType.REGULAR : businessName ? InviteType.BUSINESS : undefined;
    const discount = Discounts.getDiscountForReferrer(refererUser);

    return <>
        <MarketingInviteBar
            inviteType={inviteType}
            name={refererName || businessName}
            discount={discount}
            trialsDays={trialsDays}
        />
        <MarketingMenuWrapper $showShadow={showShadow} $customScrollContentEnabled={customScrollContentEnabled}>
            <div>
                {
                    customScrollContentEnabled ? <SimplifiedMenu>
                        <div>
                            <MarketingHeaderTry>Ready to get started with Authory?</MarketingHeaderTry>
                        </div>
                        <div>
                            <MarketingHeaderUL>
                                <MarketingHeaderLi as="li"><img src="/authory_static_files/images/checkmark.svg" /><V3Body>Self-updating portfolio</V3Body></MarketingHeaderLi>
                                <MarketingHeaderLi as="li"><img src="/authory_static_files/images/checkmark.svg" />Automated content backups</MarketingHeaderLi>
                                <MarketingHeaderLi as="li"><img src="/authory_static_files/images/checkmark.svg" />3 minute setup</MarketingHeaderLi>
                            </MarketingHeaderUL>
                        </div>
                        <MarketingSimpleHeaderButton>
                            <div>
                                <OptionalNextLink href={`${APPLICATION_ROUTES.SIGNUP}?utm_source=${customScrollBannerUTMTag}&utm_medium=signup_prompt`} useNextRouter={useNextRouter}>
                                    <V3Button
                                        text="Try Authory free"
                                        autoWidth
                                        minWidth={100}
                                    />
                                </OptionalNextLink>
                            </div>
                            <div>
                                <OptionalNextLink href={`${APPLICATION_ROUTES.HOMEPAGE}?utm_source=${customScrollBannerUTMTag}&utm_medium=learn_more`} useNextRouter={useNextRouter}>
                                    <V3Button
                                        text="Learn more"
                                        autoWidth
                                        minWidth={100}
                                        color={V3ButtonColor.secondary}
                                    />
                                </OptionalNextLink>
                            </div>
                        </MarketingSimpleHeaderButton>
                    </SimplifiedMenu> :
                        <DesktopMenu showShadow={showShadow}>
                            <div>
                                <OptionalNextLink href={APPLICATION_ROUTES.HOMEPAGE} useNextRouter={useNextRouter}>
                                    <div>
                                        <MarketingLogo src="/authory_static_files/images/authory_marketing_logo_complete.svg" alt="Authory Logo" />
                                    </div>
                                </OptionalNextLink>
                            </div>
                            <MenuNavWrapper>
                                <div>
                                    <DropdownMenuPanel
                                        tooltipContent={
                                            <MenuPaneContentWrapper>
                                                {
                                                    FTMARKETINGPAGES.map(({ iconPath, primaryText, secondaryText, route }) => <div key={route}>
                                                        <MarketingUseCasePanel
                                                            icon={<img src={`/authory_static_files/images/icons/${iconPath}_m_active.svg`} alt={`${iconPath} menu icon`} />}
                                                            primaryText={primaryText}
                                                            secondaryText={secondaryText}
                                                            href={route}
                                                            secondaryTextMaxWidth={150}
                                                            useNextRouter={useNextRouter}
                                                        />
                                                    </div>)
                                                }
                                            </MenuPaneContentWrapper>
                                        }
                                        referenceContent={<MenuItemText>
                                            Features
                                            <ArrowSVG />
                                        </MenuItemText>}
                                        maxWidth={930}
                                    />
                                </div>
                                <div>
                                    <DropdownMenuPanel
                                        tooltipContent={
                                            <MenuUseCaseContentWrapper>
                                                {
                                                    UCMARKETINGPAGES.map(uc => {
                                                        return <div key={uc.route}>
                                                            <MarketingUseCasePanel
                                                                icon={<img src={`/authory_static_files/images/icons/${uc.iconPath}_m_active.svg`} alt={`${uc.iconPath} menu icon`} />}
                                                                primaryText={uc.primaryText}
                                                                secondaryText={uc.secondaryText}
                                                                href={uc.route}
                                                                useNextRouter={useNextRouter}
                                                            />
                                                        </div>
                                                    })
                                                }
                                            </MenuUseCaseContentWrapper>
                                        }
                                        referenceContent={<MenuItemText>
                                            Use cases
                                            <ArrowSVG />
                                        </MenuItemText>}
                                        maxWidth={850}
                                    />
                                </div>
                                <div>
                                    <OptionalNextLink href={APPLICATION_ROUTES.PRICING} useNextRouter={useNextRouter}>
                                        <MenuItemText underline>Pricing</MenuItemText>
                                    </OptionalNextLink>
                                </div>
                                <div>
                                    <DropdownMenuPanel
                                        maxWidth={300}
                                        tooltipContent={
                                            <SmallMenuPanel>
                                                {
                                                    MOREMARKETINGPAGES.map(item => <SmallMenuLink
                                                        imgPath={item.iconPath}
                                                        title={item.primaryText}
                                                        href={item.href}
                                                        useLink={(item.href !== APPLICATION_ROUTES.BLOG && item.href !== APPLICATION_ROUTES.EXAMPLES) && useNextRouter}
                                                        key={item.href}
                                                    />)
                                                }
                                            </SmallMenuPanel>
                                        }
                                        referenceContent={<MenuItemText>
                                            More
                                            <ArrowSVG />
                                        </MenuItemText>}
                                    />
                                </div>
                            </MenuNavWrapper>
                            <RightSideMenuWrapper>
                                <div>
                                    <OptionalNextLink href={APPLICATION_ROUTES.LOGIN} useNextRouter={useNextRouter}>
                                        <MMShowMobile>
                                            <MarketingCTA
                                                size={CTASize.S}
                                                variant={CTAVariant.Secundary}
                                                text="Login"
                                            />
                                        </MMShowMobile>
                                        <MMShowDesktop>
                                            <MarketingCTA
                                                size={CTASize.M}
                                                variant={CTAVariant.Secundary}
                                                text="Login"
                                            />
                                        </MMShowDesktop>
                                    </OptionalNextLink>
                                </div>
                                <div>
                                    <OptionalNextLink href={`${APPLICATION_ROUTES.SIGNUP}${signupUTMTags}`} useNextRouter={useNextRouter}>
                                        <MMShowMobile>
                                            <MarketingCTA
                                                size={CTASize.S}
                                                text={"Try free"}
                                            />
                                        </MMShowMobile>
                                        <MMShowDesktop>
                                            <MarketingCTA
                                                size={CTASize.M}
                                                text={inviteType === InviteType.BUSINESS ? "Try a year for free" : `Get started for free`}
                                            />
                                        </MMShowDesktop>
                                    </OptionalNextLink>
                                </div>
                                <div>
                                    {
                                        !menuOpen ?
                                            <svg
                                                width="32"
                                                height="20"
                                                viewBox="0 0 32 20"
                                                fill="none"
                                                onClick={() => {
                                                    setMenuOpen(!menuOpen);
                                                }}
                                            >
                                                <path d="M0 1H32" stroke="#2E77FF" strokeWidth="2" />
                                                <line y1="10" x2="32" y2="10" stroke="#5892FF" strokeWidth="2" />
                                                <line y1="19" x2="32" y2="19" stroke="#82AEFF" strokeWidth="2" />
                                            </svg>
                                            :
                                            <svg
                                                onClick={() => {
                                                    setMenuOpen(!menuOpen);
                                                }}
                                                width="32"
                                                height="32" viewBox="0 0 24 24" fill="none" stroke="#5892FF"
                                                strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                                            >
                                                <line x1="20" y1="2" x2="2" y2="20"></line>
                                                <line x1="2" y1="2" x2="20" y2="20"></line>
                                            </svg>
                                    }
                                </div>
                            </RightSideMenuWrapper>
                            <MobileMenu open={menuOpen} setShowShadow={setShowShadow} useNextRouter={useNextRouter} />
                        </DesktopMenu>
                }
            </div>
        </MarketingMenuWrapper>
    </>
}