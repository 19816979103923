import { css } from "styled-components";

export type ClampMixinType = {
    $numLines?: number
}

export const ClampMixin = css<ClampMixinType>`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${({ $numLines }) => $numLines ? $numLines : 3};
    overflow: hidden;
`;