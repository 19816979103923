import { RequestHost } from "../types/headers";

const NEXT_JS_DEFAULT_PORT = 3000;

/**
 * Gets the absolute URL to route to.
 * @param href the path we want to route to
 * @param host the host we want to get the url for. Defaults to the main host.
 * @returns
 */
export function getAbsoluteUrl(href: string = "", host?: string) {
    const protocol: string =
        process.env.NODE_ENV === "development" ? "http" : "https";

    if (!host) {
        host = process.env.NEXT_PUBLIC_MAIN_HOST ?? "localhost";
    }

    return `${protocol}://${host}${href}`;
}

/**
 * Gets the url for the root domain based on a subdomain. Helps with redirection.
 * @param secondaryDomain secondary domain.
 * @param path path we want to navigate to.
 */
export function getPrimaryDomainUrl(
    secondaryDomain: string,
    path: string = ""
) {
    let host = secondaryDomain.substring(secondaryDomain.indexOf(".") + 1);

    // Edge Case: Add port number if we're running the application locally
    if (process.env.NODE_ENV === "development") {
        host = `${host}:${NEXT_JS_DEFAULT_PORT}`;
    }

    return getAbsoluteUrl(path, host);
}

/**
 * Checks whether we are on the main domain or not.
 * @param requestHostHeader
 * @returns
 */
export function isMainDomain(requestHostHeader: RequestHost) {
    return requestHostHeader === RequestHost.MAINDOMAIN;
}

/**
 * 
 * @param host Formats an url with protocol
 * @returns 
 */

export function getCustomDomainURL(host: string) {
    const protocol: string =
        process.env.NODE_ENV === "development" ? "http" : "https";

    if (!host) {
        host = process.env.NEXT_PUBLIC_MAIN_HOST ?? "localhost";
    }

    return `${protocol}://${host}`;
}