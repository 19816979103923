import { SourceType, UserAccountType } from "authory-api-types/dist/enums";
import { getSourceStatus } from "../pagelayouts/analytics/utils/getSourceStatus";
import { AuthenticatedUser } from "../types/user";

export const getAnalyticsStatus = (user: AuthenticatedUser) => {
    const isBusinessParent = user?.type === UserAccountType.BusinessParent;
    const webSources = isBusinessParent ? { hasSrc: true, srcInProgress: false } : getSourceStatus(user?.sources.sources || [], [SourceType.Web]);

    const isWebInactive = !webSources.hasSrc || webSources.srcInProgress;

    return { isWebInactive }
}