import { SubscriptionPlan } from "authory-api-types/dist/enums";
import { useState } from "react";
import { PaymentPlansSelector } from "../PaymentPlansSelector";
import { V3ModalBase } from "../V3ModalBase";
import { PaymentPlanSelectorModalWrapper } from "./V3ModalChoosePlan.styles";
import { RightAlignPanel } from "../ReusableStyledComponents/RightAlignPanel";
import { VerticalSpacing } from "../VerticalSpacing";
import { V3Button } from "../Button";
import { V3ButtonColor } from "../Button/V3Button.types";

interface V3ModalChoosePlanProps {
    modalOpen: boolean,
    onCloseHandler: () => void,
    onChoosePlan: (plan: SubscriptionPlan) => Promise<any>,
    activePlan: SubscriptionPlan | null | undefined,
    isLegacyPaypal?: boolean,
    onBackHandler?: () => void,
    hideCloseCTA?: boolean,
    isTrial?: boolean,
    isFreePlanOnTrial?: boolean,
}

export const V3ModalChoosePlan = ({ modalOpen, onCloseHandler, onChoosePlan, activePlan, isLegacyPaypal, onBackHandler, hideCloseCTA, isTrial = false, isFreePlanOnTrial = false }: V3ModalChoosePlanProps) => {
    const [planSubmitting, setPlanSubmitting] = useState(false);

    const submitPlan = async (plan: SubscriptionPlan) => {
        try {
            setPlanSubmitting(true);
            await onChoosePlan(plan);
            setPlanSubmitting(false);
            onCloseHandler();
        } catch {
            setPlanSubmitting(false);
        }
    }

    return <V3ModalBase
        isOpen={modalOpen}
        onClose={onCloseHandler}
        title="Change plan"
        width="650px"
        hideCloseCTA={hideCloseCTA}
    >
        <PaymentPlanSelectorModalWrapper>
            <PaymentPlansSelector
                billingAreaVariant
                currentPlan={activePlan}
                isLegacyPaypal={isLegacyPaypal}
                onPlanSelect={submitPlan}
                submitting={planSubmitting}
                isTrial={isTrial}
                paymentPlanShortLabel=""
                isFreePlanOnTrial={isFreePlanOnTrial}
            />
        </PaymentPlanSelectorModalWrapper>
        {
            onBackHandler && <VerticalSpacing top={28}>
                <RightAlignPanel>
                    {
                        !!onBackHandler && <div>
                            <V3Button
                                text="Back"
                                color={V3ButtonColor.secondary}
                                onClick={onBackHandler}
                            />
                        </div>
                    }
                </RightAlignPanel>
            </VerticalSpacing>
        }
    </V3ModalBase >
}