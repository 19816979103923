import Link from "next/link"
import { UnstyledLink } from "./UnstyledLink"

interface OptionalLinkProps {
    href?: string,
    target?: string
}

export const OptionalLink: React.FunctionComponent<React.PropsWithChildren<OptionalLinkProps>> = ({ href, children, target }) => {
    if (href) {
        return <Link href={href} passHref legacyBehavior>
            <UnstyledLink target={target}>
                {children}
            </UnstyledLink>
        </Link>
    }

    return <>
        {children}
    </>
}