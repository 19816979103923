import React, { ReactNode } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router';

type LayoutProps = {
    children: ReactNode,
    title?: string,
    skipAuthorytitle?: boolean
}

const Layout = ({ children, title, skipAuthorytitle = false }: LayoutProps) => {

    const router = useRouter();

    const isPrivateRoute = router.pathname.startsWith("/app");
    const isNotProd = process.env.NEXT_PUBLIC_SENTRY_ENV !== 'production';
    const skipSEO = isPrivateRoute || isNotProd;
    const finalTitle = `${title} ${!skipAuthorytitle ? "- Authory" : ""}`;

    return <>
        <Head>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
            {finalTitle && <title>{finalTitle}</title>}
            {skipSEO && <meta name="robots" content="noindex" />}
        </Head>
        {children}
    </>
};

export default Layout
