import styled from "styled-components";

export const ErrorContentLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
`;

export const ErrorPageContent = styled.div`
    max-width: 730px;
    margin: 0 auto;
    width: 100%;
    padding: 0 12px;
`;