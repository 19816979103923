import { QueryClient, useMutation } from "@tanstack/react-query";
import { setPayment } from "../../../api/payments";
import { V3SetPaymentErrorHandler } from "../../../api/error-handler";
import toast from "react-hot-toast";
import { Queries, getAuthenticatedUserQuerie } from "../../../types/queries";
import { destroyCookie } from "nookies";
import { CookieKeys } from "../../../types/cookie-keys";
import { NotificationTargets } from "../../../types/notification";

export const getSetPaymentMutation = (
    queryClient: QueryClient,
    token: string,
) => useMutation({
    mutationFn: setPayment,
    onError: V3SetPaymentErrorHandler(toast.error),
    onSuccess: (user, variables) => {
        // Update user query
        queryClient.setQueryData(getAuthenticatedUserQuerie(token), user);

        // Invalidate billing data
        queryClient.invalidateQueries({ queryKey: [Queries.BillingData] });
        queryClient.invalidateQueries({ queryKey: [Queries.BillingInvoices] });

        // Legacy users being migrated - destroy plan cookie
        destroyCookie(null, CookieKeys.subscription_plan, { path: "/" });

        if (variables.target === NotificationTargets.UserPlanUpdated) {
            toast.success("Your plan has been changed");
        } else if (variables.target === NotificationTargets.UserPaymentUpdated) {
            toast.success("Payment method updated");
        }
    }
});