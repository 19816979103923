import { CurrencyCode, InvoiceStatus, SubscriptionPlan, SubscriptionPlanKey, UserAccountType } from "authory-api-types/dist/enums";
import { PaymentSourceBrand, PaymentType } from "authory-api-types/dist/enums";
import { AuthenticatedUser } from "../../../types/user";
import { AUTHORY_2023_PLAN_TIERS } from "../../../utils/pricingPerks";

const getSourceBrand = (source: PaymentSourceBrand) => {
    switch (source) {
        case PaymentSourceBrand.Visa:
            return "Visa";
        case PaymentSourceBrand.AmericanExpress:
            return "American Express";
        case PaymentSourceBrand.Bancontact:
            return "BanContact";
        case PaymentSourceBrand.DinersClub:
            return "DinersClub";
        case PaymentSourceBrand.Discover:
            return "Discover";
        case PaymentSourceBrand.Mastercard:
            return "Mastercard";
        case PaymentSourceBrand.Other:
        default:
            return "Card";
    }
}

export const getPaymentMethod = (
    method: PaymentType | null | undefined,
    paymentSourceLastFourDigits: string | null | undefined,
    paymentSourceBrand: PaymentSourceBrand | null | undefined
) => {
    if (method === PaymentType.ChargebeePaypal) return "Paypal";

    if (method === PaymentType.ChargebeeStripe) {
        if (paymentSourceBrand && paymentSourceLastFourDigits) return `${getSourceBrand(paymentSourceBrand)} ending on ${paymentSourceLastFourDigits}`;
        return "Card";
    }

    if (method === PaymentType.ChargebeeApplePay) return "Apple Pay";
    if (method === PaymentType.ChargebeeGooglePay) return "Google Pay";

    return "-";
}

export const getPlanDuration = (planKey: SubscriptionPlanKey, isOnTrial: boolean) => {
    switch (planKey) {
        case SubscriptionPlanKey.DiscountedUSDMonthly:
        case SubscriptionPlanKey.USDMonthly:
        case SubscriptionPlanKey.USDAuthorySub2023Monthly:
        case SubscriptionPlanKey.USDAuthoryProSub2023Monthly:
            return "Monthly";
        case SubscriptionPlanKey.DiscountedUSDYearly:
        case SubscriptionPlanKey.USDYearly:
        case SubscriptionPlanKey.USDAuthorySub2023Yearly:
        case SubscriptionPlanKey.USDAuthoryProSub2023Yearly:
            return "Yearly";
        case SubscriptionPlanKey.USDAuthoryFreeProSub2023Monthly:
        case SubscriptionPlanKey.USDAuthoryFreeSub2023Monthly:
            return isOnTrial ? "Monthly" : "";
        case SubscriptionPlanKey.USDAuthoryFreeSub2023Yearly:
        case SubscriptionPlanKey.USDAuthoryFreeProSub2023Yearly:
            return isOnTrial ? "Yearly" : "";
        default:
            return "-";
    }
}

export const getPlanDurationPeriod = (planKey: SubscriptionPlanKey) => {
    switch (planKey) {
        case SubscriptionPlanKey.DiscountedUSDMonthly:
        case SubscriptionPlanKey.USDMonthly:
        case SubscriptionPlanKey.USDAuthorySub2023Monthly:
        case SubscriptionPlanKey.USDAuthoryProSub2023Monthly:
        case SubscriptionPlanKey.USDAuthoryFreeProSub2023Monthly:
        case SubscriptionPlanKey.USDAuthoryFreeSub2023Monthly:
            return "per month";
        case SubscriptionPlanKey.DiscountedUSDYearly:
        case SubscriptionPlanKey.USDYearly:
        case SubscriptionPlanKey.USDAuthorySub2023Yearly:
        case SubscriptionPlanKey.USDAuthoryProSub2023Yearly:
        case SubscriptionPlanKey.USDAuthoryFreeProSub2023Yearly:
        case SubscriptionPlanKey.USDAuthoryFreeSub2023Yearly:
            return "per year";
        default:
            return "-";
    }
}

export const getV3PlanAmount = (
    subscriptionAmountCents: number | null | undefined,
    subscriptionCurrency: CurrencyCode | null | undefined
) => {
    if (subscriptionAmountCents && subscriptionCurrency) return `${subscriptionCurrency} ${subscriptionAmountCents / 100}`;
    return "";
}

export const getInvoiceStatus = (status: InvoiceStatus) => {
    switch (status) {
        case InvoiceStatus.Not_paid:
            return "Unpaid";
        case InvoiceStatus.Paid:
            return "Paid";
        case InvoiceStatus.Payment_due:
            return "Payment Due";
        case InvoiceStatus.Posted:
            return "Posted";
        case InvoiceStatus.Voided:
            return "Voided";
        case InvoiceStatus.pending:
            return "Pending";
        default:
            return "-"
    }
}

export const getPaymentAddressLabel = (
    billingAddressLineOne: string | null | undefined,
    billingAddressLineTwo: string | null | undefined,
    billingAddressState: string | null | undefined,
    billingAddressCountry: string | null | undefined,
    billingAddressZip: string | null | undefined
) => {
    const address = `${billingAddressLineOne || ""} ${billingAddressLineTwo || ""} ${billingAddressState || ""} ${billingAddressCountry || ""} ${billingAddressZip || ""}`;
    const trimmedAddress = address.substring(0, 40);
    return trimmedAddress.trim().length ? `${trimmedAddress.trim()}...` : "No billing address set";
}

export const V3HasAccessToBillingPage = (user: AuthenticatedUser) => {
    const isBusinessParent = user?.type === UserAccountType.BusinessParent;
    const isBusinessSeat = user?.type === UserAccountType.BusinessSeat;
    const isBusiness = isBusinessParent || isBusinessSeat;

    return !isBusiness;
}

export const getPlan = (planKey: SubscriptionPlanKey | null | undefined, isOnTrial: boolean) => {
    switch (planKey) {
        case SubscriptionPlanKey.USDAuthorySub2023Monthly:
            return SubscriptionPlan.monthly;
        case SubscriptionPlanKey.USDAuthoryFreeSub2023Monthly:
            return isOnTrial ? SubscriptionPlan.monthly : undefined;
        case SubscriptionPlanKey.USDAuthorySub2023Yearly:
            return SubscriptionPlan.yearly;
        case SubscriptionPlanKey.USDAuthoryFreeSub2023Yearly:
            return isOnTrial ? SubscriptionPlan.yearly : undefined;
        case SubscriptionPlanKey.USDAuthoryProSub2023Monthly:
            return SubscriptionPlan.proMonthly;
        case SubscriptionPlanKey.USDAuthoryFreeProSub2023Monthly:
            return isOnTrial ? SubscriptionPlan.proMonthly : undefined;
        case SubscriptionPlanKey.USDAuthoryProSub2023Yearly:
            return SubscriptionPlan.proYearly;
        case SubscriptionPlanKey.USDAuthoryFreeProSub2023Yearly:
            return isOnTrial ? SubscriptionPlan.proYearly : undefined;
        default:
            return;
    }
}

export const mapPaidPlanToFreePlan = (plan: SubscriptionPlan) => {
    switch (plan) {
        case SubscriptionPlan.monthly:
            return SubscriptionPlan.freeMonthly;
        case SubscriptionPlan.yearly:
            return SubscriptionPlan.freeYearly;
        case SubscriptionPlan.proMonthly:
            return SubscriptionPlan.freeProMonthly;
        case SubscriptionPlan.proYearly:
            return SubscriptionPlan.freeProYearly;
    }

    return SubscriptionPlan.freeMonthly;
}

export const mapFreePlanToPaidPlan = (plan: SubscriptionPlanKey | null | undefined) => {
    switch (plan) {
        case SubscriptionPlanKey.USDAuthoryFreeSub2023Monthly:
            return SubscriptionPlan.monthly;
        case SubscriptionPlanKey.USDAuthoryFreeSub2023Yearly:
            return SubscriptionPlan.yearly;
        case SubscriptionPlanKey.USDAuthoryFreeProSub2023Monthly:
            return SubscriptionPlan.proMonthly;
        case SubscriptionPlanKey.USDAuthoryFreeProSub2023Yearly:
            return SubscriptionPlan.proYearly;
    }
}

export const getIsFreePlanKey = (user: AuthenticatedUser) => !!user && !!user.subscriptionPlanKey && [
    SubscriptionPlanKey.USDAuthoryFreeSub2023Monthly,
    SubscriptionPlanKey.USDAuthoryFreeSub2023Yearly,
    SubscriptionPlanKey.USDAuthoryFreeProSub2023Monthly,
    SubscriptionPlanKey.USDAuthoryFreeProSub2023Yearly
].includes(user.subscriptionPlanKey)

export const getPlanMetrics = (planSub?: SubscriptionPlan) => {

    const isProMonthly = planSub === SubscriptionPlan.proMonthly;
    const isProYearly = planSub === SubscriptionPlan.proYearly;
    const isStandardMonthly = planSub === SubscriptionPlan.monthly;
    const isPro = isProMonthly || isProYearly;
    const isYearly = !isStandardMonthly && !isProMonthly;
    const tier = isPro ? AUTHORY_2023_PLAN_TIERS.PRO : AUTHORY_2023_PLAN_TIERS.STANDARD;

    return {
        tier,
        isYearly
    }
}