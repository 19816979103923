import * as React from 'react'

type AddElementSkeletonTrackerType = {
    onScreen: boolean,
    setOnScreen: React.Dispatch<React.SetStateAction<boolean>>,
    addContentOpen: boolean,
    setAddContentOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const V3AddElementSkeletonTracker = React.createContext<AddElementSkeletonTrackerType>({
    onScreen: false,
    setOnScreen: () => { },
    addContentOpen: false,
    setAddContentOpen: () => { },
});

const AddElementSkeletonTracker: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
    const [onScreen, setOnScreen] = React.useState(false);
    const [addContentOpen, setAddContentOpen] = React.useState(false);

    const value = { onScreen, setOnScreen, addContentOpen, setAddContentOpen }

    return <V3AddElementSkeletonTracker.Provider value={value}>{children}</V3AddElementSkeletonTracker.Provider>
}

function useAddElementSkeletonTracker() {
    const context = React.useContext(V3AddElementSkeletonTracker)
    return context;
}

export { AddElementSkeletonTracker, useAddElementSkeletonTracker }