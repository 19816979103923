import styled from "styled-components";
import { media, theme } from "../../theme/theme";
import { V3HeadlineH3 } from "../Typography"

export const NSRWrapper = styled.div < { $customPadding?: string, $skipExtendMobile?: boolean }>`
    width: ${({ $skipExtendMobile }) => !$skipExtendMobile ? "calc(100% + 36px)" : undefined};
    margin-left: ${({ $skipExtendMobile }) => !$skipExtendMobile ? "-18px" : undefined};
    background: ${({ theme }) => theme ? theme.v3.colors.grey96 : undefined};
    padding: ${({ $customPadding }) => $customPadding ? $customPadding : "30px 0 90px"};
    padding-left: 18px;
    padding-right: 18px;
    display: flex;
    align-items: center;
    justify-content:center;
    height: auto;
    border-radius: 8px;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        width: 100%;
        margin-left: initial;
    }
`;

export const NSTTitle = styled(V3HeadlineH3)`
    color: ${({ theme }) => theme ? theme.v3.colors.grey35 : undefined};
`;

export const NSRContent = styled.div<{ $maxWidth?: number }>`
    max-width: ${({ $maxWidth }) => $maxWidth ? `${$maxWidth}px` : "322px"};
    margin: 0 auto;
    text-align: center;
`;

export const EmptyStateBtnPlacer = styled.div`
    display: flex;
    justify-content: center;
`;