import { FormikProps } from "formik";
import { PCFIconWrapper } from "./FilterOps.style";
import { ActiveIconToggle } from "../ReusableStyledComponents/ActiveFilterToggle";
import { V3Tooltip } from "../Tooltip";
import { useWindowSize } from "../../hooks/useWindowSize";
import { theme } from "../../theme/theme";

const Elem = ({ icon, name, opts, active, value, formik }: FilterOptProps) => <>
    <select
        value={value}
        name={name}
        onChange={(e) => {
            formik.handleChange(e);
            formik.submitForm();
        }}
        onBlur={formik.handleBlur}
    >
        {opts.map(opt => <option value={opt.value} key={opt.value}>{opt.text}</option>)}
    </select>
    <ActiveIconToggle
        icon={icon}
        active={active}
    />
</>

interface FilterOptProps {
    active: boolean,
    icon: JSX.Element,
    name: string,
    opts: { value: string, text: string }[],
    value?: string,
    formik: FormikProps<any>,
    tooltipContent?: string
};

export const FilterOpt = (props: FilterOptProps) => {

    const size = useWindowSize();
    const isMobile = size.width && size.width < theme.v3.layout.sidebarLayout.mobile || false;

    return <>
        <div>
            <PCFIconWrapper>
                {
                    isMobile || !props.tooltipContent ? <Elem {...props} /> : <V3Tooltip
                        referenceContent={<Elem {...props} />}
                        tooltipContent={props.tooltipContent}
                    />
                }
            </PCFIconWrapper>
        </div >
    </>
}