const COUNT_ABBRS = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

const formatCount = (count: number, withAbbr = false, decimals = 2) => {
    const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
    let result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals)).toString();

    if (withAbbr) {
        result += `${COUNT_ABBRS[i]}`;
    }
    return result;
}

export const formatNumber = (count: number | null | undefined) => {
    if (typeof (count) !== "number") return "-";
    if (count < 1000) return count.toLocaleString();
    if (count > 1000 && count <= 9999) return formatCount(count, true, 1);
    return formatCount(count, true, 0);
}