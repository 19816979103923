export default class LocalStore {
    key: string;

    constructor(key: string) {
        if (typeof window === "undefined") {
            throw new Error("Attempting to use local storage on server side.");
        }
        this.key = key;
    }

    /**
     * Set value to local storage on client side.
     */
    set(value: string) {
        try {
            if (window.localStorage) window.localStorage.setItem(this.key, value);
        } catch { }
    }

    /**
     * Get value from local storage on client side.
     */
    get() {
        try {
            if (window.localStorage) return window.localStorage.getItem(this.key);
        } catch { }
    }

    /**
     * Delete value from local storage on client side.
     */
    unset() {
        try {
            if (window.localStorage) window.localStorage.removeItem(this.key);
        } catch { }
    }
}

export const LocalStorageKeys = {
    tracking_utm_source: "bhUViDLTgW",
    tracking_utm_medium: "PGmeoLvuYZ",
    tracking_utm_campaign: "llhEfbrqfz",
    tracking_utm_term: "rUwCOJZSDN",
    tracking_utm_content: "rxbiwbWXhm",
    tracking_referrer: "GjMISOymsS",
    tracking_dedup_facebook: "039vTDV1Sh",
    tracking_dedup_twitter: "N2oMR079W6",
    editor_article_title: "editor_article_title",
    editor_article_html: "editor_article_html",
    tracking_fbclid: "fb_clid",
};
