import { AuthenticatedUser } from '../../types/user';
import { BaseTracker } from '../BaseTracker';

// This class is a utility helper that should be used to call MixPanel tracking methods
export class MixpanelTrackerMock extends BaseTracker {
    constructor(user: AuthenticatedUser) {
        super(user, process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);
    }

    protected initializeInternal() {
        console.log("Mock - Mixpanel initializeInternal");
    }

    protected trackPageViewInternal() {
        console.log("Mock - Mixpanel trackPageViewInternal");
    }

    protected trackEventInternal(key: string, data: any) {
        console.log("Mock - Mixpanel trackEventInternal", key, data);
    }

    protected shutdownInternal() {
        console.log("Mock - Mixpanel shutdownInternal");
    }

    identify(communicationId?: string) {
        if (this.user?.communicationId) {
            console.log("Mock - identify", this.user?.communicationId);
        } else if (communicationId) {
            console.log("Mock - identify", communicationId);
        }
    }

    getDistinctId() {
        console.log("Mock - getDistinctId");
        return "foo";
    }
}
