import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router';
import { destroyCookie } from "nookies";
import { useContext } from 'react';
import { CookieContext } from '../context/cookieContext';
import LocalStore, { LocalStorageKeys } from "../localstorage/LocalStorage";
import IntercomTracker from "../tracking/Intercom";
import MixpanelTracker from "../tracking/Mixpanel";
import { CookieKeys, COOKIE_TOKEN_PATH } from "../types/cookie-keys";
import { getAuthenticatedUserClient } from '../utils/getAuthenticatedUser';

export const useSignOut = () => {
    const router = useRouter();
    const token = useContext(CookieContext);
    const { authenticatedUser: { data } } = getAuthenticatedUserClient(token, router, true);

    return () => {
        // Delete all cookies known to the application.
        for (const key of Object.keys(CookieKeys)) {
            destroyCookie(null,
                //@ts-ignore
                CookieKeys[key], {
                path: COOKIE_TOKEN_PATH
            });
        }

        // Remove all local storage stuff known to the application.
        for (const key of Object.keys(LocalStorageKeys)) {
            new LocalStore(
                //@ts-ignore
                LocalStorageKeys[key]
            ).unset();
        }

        // Shutdown intercom
        const intercomTracker = new IntercomTracker(data, true);
        intercomTracker.shutdown();

        // Shutdown mixpanel
        const mixpanelTracker = new MixpanelTracker(data);
        mixpanelTracker.shutdown();

        // Clear Sentry scope
        Sentry.configureScope(scope => scope.setUser(null));

        let path = "/";

        document.location.href = path;
    }
}