import { NextPageContext } from "next";
import { NextRouter } from "next/router";
import * as Sentry from '@sentry/nextjs'
import { parseCookies } from "nookies";
import { useQuery, QueryClient } from '@tanstack/react-query';
import { getAuthenticatedUser } from "../api/user";
import { logError } from "../logging/logSentryError";
import { userRedirects } from "../redirects/userRedirects";
import { getAuthenticatedUserQuerie, Token } from "../types/queries";
import { Errors } from "../types/api";
import { AxiosError } from "axios";

// Status codes that will trigger a cookie clear
const clearTokenStates = [Errors.AccessDenied, Errors.NotFound];

/**
 * This method gets the authenticated user on the react component lifecycle and checks for redirects
 * @param token the auth token
 * @param router next router object
 * @param skipRedirect wether to check for redirect or not
 */
export const getAuthenticatedUserClient = (token: Token, router: NextRouter, skipRedirect = false) => {
    // Get the user
    const authenticatedUser = useQuery({
        queryKey: getAuthenticatedUserQuerie(token),
        queryFn: () => getAuthenticatedUser(token),
        throwOnError: false,
        meta: { logNotFound: true }
    });

    let busy = authenticatedUser.isFetching;

    if (token && authenticatedUser.data) {
        Sentry.setUser({
            username: `${authenticatedUser.data?.firstName} ${authenticatedUser.data?.lastName}`,
            id: authenticatedUser.data?.communicationId
        });
    }

    // Important: Only allow the redirect mechanism to kick in for certain status codes
    // the redirect system will take care of clearing the token and redirecting the user
    if (authenticatedUser.error && !clearTokenStates.includes((authenticatedUser.error as AxiosError)?.response?.status as number)) {
        throw Error(`Unable to get user`);
    }

    // If this url is protected and we have fetched the user, ran validation checks
    if (!skipRedirect && authenticatedUser.isFetched) {
        busy = userRedirects(authenticatedUser.data, undefined, router) || false;
    }

    return { authenticatedUser, busy }
}

/**
 * This method gets the authenticated user for SRR on node, and checks for redirects
 * @param queryClient react query query client
 * @param ctx next ctx context
 * @param skipRedirect  wether to check for redirect or not
 */
export const getAuthenticatedUserServer = async (queryClient: QueryClient, ctx: NextPageContext, skipRedirect = false) => {
    const cookies = parseCookies(ctx);

    try {
        const user = await queryClient.fetchQuery({
            queryKey: getAuthenticatedUserQuerie(cookies.token),
            queryFn: () => getAuthenticatedUser(cookies.token),
        });

        if (cookies.token && user) {
            Sentry.setUser({
                username: `${user?.firstName} ${user?.lastName}`,
                id: user?.communicationId
            });
        }

        if (!skipRedirect) userRedirects(user, ctx);

        return user;

    } catch (error: any) {

        // Important: Only allow the redirect mechanism to kick in for certain status codes
        // the redirect system will take care of clearing the token and redirecting the user
        if (clearTokenStates.includes(ctx.res && error?.response?.status)) {
            if (!skipRedirect) userRedirects(undefined, ctx);
            return;
        }

        // Otherwise we log the error and we reply with a something went wrong page
        logError(error);
    }
}