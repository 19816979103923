import { APPLICATION_ROUTES } from "../types/routes";

export const FTMARKETINGPAGES = [
    {
        iconPath: "present",
        primaryText: "Portfolio",
        secondaryText: "Get a self-updating portfolio page.",
        route: APPLICATION_ROUTES.PORTFOLIO,
    },
    {
        iconPath: "protect",
        primaryText: "Archive",
        secondaryText: "Get automated backups of all content you create.",
        route: APPLICATION_ROUTES.ARCHIVE,
    },
    {
        iconPath: "analyze",
        primaryText: "Analytics",
        secondaryText: "Learn how your content performs on social media.",
        route: APPLICATION_ROUTES.ANALYTICS_MARKETING,
    },
    {
        iconPath: "track",
        primaryText: "Tracking",
        secondaryText: "Find out where and when your content is published.",
        route: APPLICATION_ROUTES.TRACKING,
    },
    {
        iconPath: "curate",
        primaryText: "Collections",
        secondaryText: "Curate your content privately or share it.",
        route: APPLICATION_ROUTES.COLLECTIONS_MARKETING,
    },
    {
        iconPath: "share",
        primaryText: "Newsletter",
        secondaryText: "Let your subscribers know about your new content.",
        route: APPLICATION_ROUTES.NEWSLETTER_MARKETING,
    },
]