interface V3IconImageProps extends React.SVGProps<SVGSVGElement> {
    stroke?: string;
}

export const V3IconImage = ({ stroke = "#ABB0BA", ...rest }: V3IconImageProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M12.375 1h-8.75A2.625 2.625 0 0 0 1 3.625v8.75A2.625 2.625 0 0 0 3.625 15h8.75A2.625 2.625 0 0 0 15 12.375v-8.75A2.625 2.625 0 0 0 12.375 1Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="m1 10.294 3.333-2.821c.38-.31.811-.473 1.25-.473.44 0 .87.163 1.25.473L11 11" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="m10 8.502-.439-.609-.01.009.449.6ZM11.5 8v.75V8Zm1.5.502.45-.6-.011-.009-.439.609Zm1.55 2.098a.75.75 0 0 0 .9-1.2l-.9 1.2Zm-5.1-.749 1-.749-.9-1.2-1 .749.9 1.2Zm.989-.74c.337-.244.703-.361 1.061-.361v-1.5c-.695 0-1.364.23-1.939.643l.878 1.217ZM11.5 8.75c.358 0 .724.117 1.061.36l.878-1.217A3.308 3.308 0 0 0 11.5 7.25v1.5Zm1.05.352 2 1.498.9-1.2-2-1.498-.9 1.2Z" fill={stroke} />
    <circle cx="10" cy="5" r="1" fill={stroke} />
</svg>
