import { SpecialDiscounts } from "authory-api-types/dist/enums";

export const get2023PlanPrice = (tierPrice: number, isYearly: boolean, yearlyDiscountFactor: number, discountPercentage?: number) => {
    const finalPrice = tierPrice * (isYearly ? yearlyDiscountFactor : 1) * (discountPercentage ? ((100 - discountPercentage) / 100) : 1);

    return Math.round(finalPrice * 100) / 100;
}

export const getDiscountPercentage = (discount?: SpecialDiscounts, isYearly = false) => {

    if (discount === SpecialDiscounts.Forever30 || (discount === SpecialDiscounts.OneYear30)) {
        return 30;
    }

    if (discount === SpecialDiscounts.OneYear20) {
        return 20;
    }

    if (discount === SpecialDiscounts.GWAA) {
        return isYearly ? 70 : 0;
    }

    return 0;
}