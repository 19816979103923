export const V3IconPinterest = ({ ...rest }: React.SVGProps<SVGSVGElement>) => <svg width="16" height="16" {...rest} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3001_23014)">
        <path d="M15.9987 7.99938C15.9987 12.4173 12.4173 15.9987 7.99934 15.9987C3.58144 15.9987 0 12.4173 0 7.99938C0 3.58144 3.58144 0 7.99934 0C12.4173 0 15.9987 3.58144 15.9987 7.99938Z" fill="white" />
        <path d="M7.99994 0.015625C3.58169 0.015625 0 3.59741 0 8.01556C0 11.4048 2.10859 14.3022 5.08459 15.4678C5.01459 14.8348 4.95144 13.8614 5.11237 13.1703C5.25769 12.546 6.05044 9.19384 6.05044 9.19384C6.05044 9.19384 5.81109 8.71466 5.81109 8.00609C5.81109 6.89372 6.45584 6.06325 7.25869 6.06325C7.94116 6.06325 8.27091 6.57572 8.27091 7.19016C8.27091 7.87659 7.83391 8.90275 7.60831 9.85375C7.41988 10.6501 8.00766 11.2994 8.79297 11.2994C10.2149 11.2994 11.3079 9.80016 11.3079 7.636C11.3079 5.72059 9.93147 4.38134 7.96625 4.38134C5.68997 4.38134 4.35384 6.08866 4.35384 7.85306C4.35384 8.54069 4.61869 9.27794 4.94922 9.67872C5.01453 9.75794 5.02416 9.82741 5.00472 9.90819C4.94394 10.1609 4.80903 10.7042 4.7825 10.8154C4.74759 10.9619 4.66653 10.993 4.51497 10.9224C3.51578 10.4573 2.89112 8.99659 2.89112 7.82325C2.89112 5.29966 4.72463 2.98216 8.177 2.98216C10.9522 2.98216 13.1089 4.95962 13.1089 7.60247C13.1089 10.3596 11.3705 12.5785 8.95759 12.5785C8.14697 12.5785 7.38484 12.1573 7.12403 11.6599C7.12403 11.6599 6.72284 13.1873 6.62559 13.5617C6.44491 14.2566 5.95741 15.1276 5.63128 15.6588C6.37988 15.8906 7.17531 16.0156 7.99994 16.0156C12.4183 16.0156 16 12.434 16 8.01556C16 3.59741 12.4183 0.015625 7.99994 0.015625Z" fill="#E71D27" />
    </g>
    <defs>
        <clipPath id="clip0_3001_23014">
            <rect width="16" height="16" fill="white" />
        </clipPath>
    </defs>
</svg>