import { useRouter } from "next/router";
import toast from "react-hot-toast";
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { CookieContext } from "../../context/cookieContext";
import { buildContentFilterQueryParams } from "../../pagelayouts/content/utils/buildContentFilterQueryParams";
import { getAuthenticatedUserClient } from "../../utils/getAuthenticatedUser";
import { getContentInfiniteQuerie } from "../../utils/query-utils/contentInfiniteQuerie";
import { ArticlePreviewResponse, ArticleSearchResultResponse, AuthenticatedUserResponse, Collection, FREE_PLAN_ITEM_LIMIT, UserResponse } from "authory-api-types/dist/types";
import { VerticalSpacing } from "../VerticalSpacing";
import { V3Loader } from "../Loader";
import { InfinitePaginationFooter } from "../InfinitePaginationFooter";
import React from "react";
import { V3NoSearchResults } from "../V3EmptyStateScreen";
import { MSCFilterWrapper, MSCItemDateSourceWrapper, MSCScrollWrapper, MSCSourceLabel, MSCTitle, ManualSelectItemsWraper } from "./ManualSelectContent.styles";
import { V3Button } from "../Button";
import { V3ButtonColor } from "../Button/V3Button.types";
import { ItemImage } from "../ItemImage";
import { getItemDisplayTitle } from "authory-api-types/dist/helpers/contentItem";
import { V3BodyGrey48 } from "../ReusableStyledComponents/V3BodyGrey48";
import { renderArticleDate } from "../../utils/articleDateParser";
import { getContentData } from "../../utils/article-text-utils";
import { V3TextInput } from "../TextInput/V3TextInput";
import { V3IconFilter, V3IconMagnifier, V3IconSources } from "../Icons";
import { FormikProps, useFormik } from "formik";
import { FilterOpt } from "../FilterOpt";
import { getSearchFormattedTypesOptions } from "../../utils/getSearchFormattedTypesOptions";
import { getSearchFormattedSourcesOptions } from "../../utils/getSearchFormattedSourcesOptions";
import { useCustomScrollShadows } from "../../hooks/customScrollShadows";
import { RightAlignPanel } from "../ReusableStyledComponents/RightAlignPanel";
import { getAddRemoveItemFromCollectionMutation } from "../../utils/mutation-utils/collections/AddRemoveItemFromCollection";
import { useQueryClient } from "@tanstack/react-query";
import { ModalLoadingSpinner } from "../ReusableStyledComponents/ModalLoadingSpinner";
import { Queries } from "../../types/queries";
import { AuthenticatedUser } from "../../types/user";

interface ManualSelectItemProps {
    item: ArticlePreviewResponse,
    addOrRemoveItem: (add: boolean, canonicalSlug: string) => Promise<void>,
    itemIsActive: boolean,
}

const ManualSelectItem = ({ item, itemIsActive, addOrRemoveItem }: ManualSelectItemProps) => {
    const [loading, setLoading] = useState(false);

    return <VerticalSpacing bottom={6} style={{ margin: "0 -10px 6px" }} key={item.slug} >
        <ManualSelectItemsWraper key={item.slug} onClick={async () => {
            if (!item.canonicalSlug) return;
            try {
                setLoading(true);
                await addOrRemoveItem(!itemIsActive, item.canonicalSlug);
                setLoading(false);
            } catch {
                setLoading(false);
            }
        }} >
            <ItemImage item={item} />
            <div>
                <MSCTitle $numLines={2}>{getItemDisplayTitle(item)}</MSCTitle>
                <MSCItemDateSourceWrapper>
                    <V3BodyGrey48 $mobileSmallVariant>{renderArticleDate(item.date, "MMM dd, yyyy")}</V3BodyGrey48>
                    <MSCSourceLabel $mobileSmallVariant>{getContentData(item.type).label} at <strong >{item.sourceName}</strong></MSCSourceLabel>
                </MSCItemDateSourceWrapper>
            </div>
            <div style={{ minWidth: 106, display: "flex", justifyContent: "center" }}>
                {
                    loading ? <ModalLoadingSpinner skipLabel /> : <V3Button
                        text={itemIsActive ? "Remove" : "Add"}
                        autoWidth
                        color={itemIsActive ? V3ButtonColor.primary : V3ButtonColor.secondary}
                    />
                }
            </div>
        </ManualSelectItemsWraper>
    </VerticalSpacing>
}

export type ManualSelectFormik = {
    text?: string,
    source?: string,
    type?: string,
}

interface ManualSelectContentProps {
    items: ArticleSearchResultResponse[],
    itemsLoading: boolean,
    hasNoResults: boolean,
    itemsLoadmore: () => void,
    itemsCanFetchmore: boolean,
    formik: FormikProps<ManualSelectFormik>,
    author: AuthenticatedUserResponse | UserResponse,
    onCloseHandler: () => void,
    onBackHandler: () => void,
    addOrRemoveItem: (add: boolean, canonicalSlug: string) => Promise<void>,
    getIsItemActive: (item: ArticlePreviewResponse) => boolean,
    isEndOfTrialFlow?: boolean,
    confirmSelection?: () => Promise<void>,
}

export const ManualSelectContent = ({ items, itemsLoading, hasNoResults, itemsLoadmore, itemsCanFetchmore, formik, author, onCloseHandler, onBackHandler, addOrRemoveItem, getIsItemActive, isEndOfTrialFlow = false, confirmSelection }: ManualSelectContentProps) => {

    const [submitting, setSubmitting] = useState(false);
    const { onScroll, ref, style: { boxShadow } } = useCustomScrollShadows();

    const allowedSourceTypes = getSearchFormattedTypesOptions(author.typeCounts);

    const sourceFilterOptions = getSearchFormattedSourcesOptions(author);

    const IDLE_STATE = hasNoResults && !itemsLoading;

    const collScrollRef = useRef();

    useEffect(() => {
        //@ts-ignore
        if (collScrollRef.current && collScrollRef.current.recalculate) collScrollRef.current.recalculate();
    }, [collScrollRef.current]);

    return <>
        <MSCFilterWrapper>
            <div>
                <V3TextInput
                    placeholder={"Search..."}
                    isRounded
                    icon={<V3IconMagnifier onClick={formik.submitForm} />}
                    value={formik.values.text}
                    onChange={formik.handleChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            formik.submitForm();
                        }
                    }}
                    name="text"
                />
            </div>
            <div>
                <FilterOpt
                    icon={<V3IconFilter />}
                    active={formik.values.type !== undefined}
                    name="type"
                    opts={allowedSourceTypes}
                    value={formik.values.type}
                    formik={formik}
                />
            </div>
            <div>
                <FilterOpt
                    icon={<V3IconSources />}
                    active={formik.values.source !== undefined}
                    name={"source"}
                    opts={sourceFilterOptions}
                    value={formik.values.source}
                    formik={formik}
                />
            </div>
        </MSCFilterWrapper>
        <MSCScrollWrapper
            forceVisible="y"
            autoHide={false}
            scrollableNodeProps={{ onScroll, ref }}
            $boxShadow={boxShadow}
            //@ts-ignore
            ref={collScrollRef}
        >
            {!hasNoResults && <>
                {
                    items.map((group, i) => (
                        <React.Fragment key={i}>
                            {group.articles.map(item => {

                                if (!item.slug) return;

                                return <ManualSelectItem
                                    key={item.canonicalSlug}
                                    item={item}
                                    itemIsActive={getIsItemActive(item)}
                                    addOrRemoveItem={addOrRemoveItem}
                                />
                            })}
                        </React.Fragment>)
                    )
                }
            </>}
            {
                IDLE_STATE && <V3NoSearchResults customPadding={"12px"} />
            }
            {itemsLoading && <VerticalSpacing top={32} bottom={32}>
                <V3Loader justify="center" />
            </VerticalSpacing>}
            <VerticalSpacing bottom={10}>
                <InfinitePaginationFooter
                    contentLoading={itemsLoading}
                    numPagesLoaded={items.length}
                    contentCanFetchmore={itemsCanFetchmore}
                    loadMore={itemsLoadmore}
                />
            </VerticalSpacing>
        </MSCScrollWrapper>
        <VerticalSpacing top={28} style={{ paddingRight: 26 }}>
            <RightAlignPanel>
                {
                    !!onBackHandler && <div>
                        <V3Button
                            text="Back"
                            color={V3ButtonColor.secondary}
                            onClick={onBackHandler}
                            disabled={submitting}
                        />
                    </div>
                }
                <div>
                    {submitting ? <V3Loader height={42} /> :
                        <V3Button
                            text={isEndOfTrialFlow ? "Continue" : "Finish"}
                            autoWidth
                            onClick={async () => {
                                if (!isEndOfTrialFlow) {
                                    toast.success("Collection updated!");
                                    onCloseHandler();
                                } else {
                                    setSubmitting(true);

                                    try {
                                        if (confirmSelection) await confirmSelection();
                                    } catch {
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        />
                    }
                </div>
            </RightAlignPanel>
        </VerticalSpacing>
    </>
}

interface ManualSelectContentCollectionContainerProps {
    onCloseHandler: () => void,
    onBackHandler: () => void,
    collection?: Collection,
}

export const ManualSelectContentCollectionContainer = ({ onCloseHandler, onBackHandler, collection }: ManualSelectContentCollectionContainerProps) => {
    const router = useRouter();
    const token = useContext(CookieContext);
    const queryClient = useQueryClient();
    const { authenticatedUser } = getAuthenticatedUserClient(token, router, true);

    // Add item to collection mutation
    const addItemToCollectionMutation = getAddRemoveItemFromCollectionMutation(queryClient);

    const addOrRemoveItemManuallyFromCollection = async (add: boolean, canonicalSlug: string) => {
        if (!token || !authenticatedUser.data?.slug || !collection) return;

        // Add item to collection
        await addItemToCollectionMutation.mutateAsync({
            token,
            userSlug: authenticatedUser.data?.slug as string,
            collectionSlug: collection.canonicalSlug,
            articleIds: [canonicalSlug],
            add: add,
        })

        queryClient.invalidateQueries({ queryKey: [Queries.User] });
    }

    const [searchState, setSearchState] = useState<ManualSelectFormik>({
        text: undefined,
        source: undefined,
        type: undefined,
    });

    const formik = useFormik<ManualSelectFormik>({
        initialValues: searchState,
        enableReinitialize: true,
        onSubmit: (data) => setSearchState({
            text: !!data.text?.length ? data.text : undefined,
            source: !!data.source?.length ? data.source : undefined,
            type: !!data.type?.length ? data.type : undefined,
        })
    });

    const params = buildContentFilterQueryParams(searchState);
    const items = getContentInfiniteQuerie(token, authenticatedUser.data?.slug, params);

    const contentLength = items.data?.pages.length ? items.data?.pages[0].filteredCount : 0;
    const contentCanFetchmore = items.hasNextPage || false;
    const HAS_NO_RESULTS = contentLength === 0 && !contentCanFetchmore;

    return <ManualSelectContent
        items={items.data?.pages || []}
        itemsLoading={items.isFetching}
        hasNoResults={HAS_NO_RESULTS}
        itemsLoadmore={items.fetchNextPage}
        itemsCanFetchmore={items.hasNextPage || false}
        formik={formik}
        author={authenticatedUser.data!}
        onCloseHandler={onCloseHandler}
        onBackHandler={onBackHandler}
        getIsItemActive={(item: ArticlePreviewResponse) => collection ? item.collections.includes(collection.canonicalSlug) : false}
        addOrRemoveItem={addOrRemoveItemManuallyFromCollection}
    />
}

interface ManualSelectContentTrialContainerProps {
    onBackHandler: () => void,
    onCommitFreePlan: (articles?: string[] | undefined) => void,
    user?: AuthenticatedUser,
    formik: FormikProps<ManualSelectFormik>,
    items: ArticleSearchResultResponse[]
    itemsLoading: boolean,
    itemsLoadmore: () => void,
    itemsCanFetchmore: boolean,
    selectedItems: string[],
    setSelectedItems: Dispatch<SetStateAction<string[]>>
}

export const ManualSelectContentTrialContainer = ({ user, formik, items, selectedItems, setSelectedItems, itemsLoading, itemsLoadmore, itemsCanFetchmore, onBackHandler, onCommitFreePlan }: ManualSelectContentTrialContainerProps) => {
    const contentLength = items.length ? items[0].filteredCount : 0;
    const contentCanFetchmore = itemsCanFetchmore || false;
    const HAS_NO_RESULTS = contentLength === 0 && !contentCanFetchmore;

    if (!user) return <></>;

    return <ManualSelectContent
        items={items}
        itemsLoading={itemsLoading}
        hasNoResults={HAS_NO_RESULTS}
        itemsLoadmore={itemsLoadmore}
        itemsCanFetchmore={contentCanFetchmore}
        formik={formik}
        author={user}
        onCloseHandler={() => { }}
        onBackHandler={onBackHandler}
        addOrRemoveItem={async (add, canonicalSlug) => {
            console.log("AQUUII", add, canonicalSlug);

            if (selectedItems.length >= FREE_PLAN_ITEM_LIMIT && add) return;

            if (add) {
                setSelectedItems(selectedItems.concat(canonicalSlug));
            } else {
                setSelectedItems(selectedItems.filter(it => it !== canonicalSlug));
            }
        }}
        getIsItemActive={(item: ArticlePreviewResponse) => selectedItems.includes(item.canonicalSlug!)}
        isEndOfTrialFlow
        confirmSelection={async () => {
            await onCommitFreePlan(selectedItems);
        }}
    />
}