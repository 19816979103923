import styled, { css } from "styled-components";
import { media, theme } from "../../theme/theme";
import SimpleBar from 'simplebar-react';

// Layout important vars
const SBL_LEFT_SIDE_WIDTH = 270;
export const LEFT_SIDE_MENU_MOBILE_HEIGHT = 60;
export const TOP_MENU_HEIGHT = 70;
export const LEFT_SIDE_MENU_WIDTH = 78;

export const SidebarLayoutShowMobile = styled.div<{ mobileDisplay?: string }>`
    display: ${({ mobileDisplay }) => mobileDisplay ? mobileDisplay : "block"};

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        display: none;
    }
`;

export const SidebarLayoutShowDesktop = styled.div<{ $desktopDisplay?: string }>`
    display: none;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        display: ${({ $desktopDisplay }) => $desktopDisplay ? $desktopDisplay : "block"};
    }
`;

export const SBLLeftPanel = styled(SimpleBar).attrs<{ $boxShadow?: string }>(
    ({ $boxShadow }) => ({
        style: {
            boxShadow: $boxShadow ? $boxShadow : undefined
        },
    })
)`
    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        max-height: calc(100vh - ${TOP_MENU_HEIGHT + 2}px);
        overflow-x: hidden;
    }
`;

export const SidebarLayoutBottomStickyPanel = styled.div`
    position: sticky;
    z-index: 1;
    bottom: 0;
    width: calc(100% - 40px);
    max-width: 1060px;
    margin: 0 auto;
`;

const LayoutPaddings = css`
    padding: 30px 20px 0;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        padding: 0px 24px 0;
    }
`;

export const LeftNavGridTopBar = styled.div<{ $showShadow: boolean }>`
    grid-area: TopBar;
    position: sticky;
    top: ${LEFT_SIDE_MENU_MOBILE_HEIGHT}px;
    z-index: 2;
    background: white;
    box-shadow: ${({ $showShadow }) => $showShadow ? "0px 6px 12px rgba(0, 0, 0, 0.05);" : null};
    border-bottom: 1px solid ${({ theme }) => theme ? theme.v3.colors.grey88 : null};

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        top: 0;
    }

    @media print {
        display: none;
    }
`;

export const LeftNavGridLeftBar = styled.div`
    grid-area: LeftBar;
    position: sticky;
    top: 0;
    border-bottom: 1px solid ${({ theme }) => theme ? theme.v3.colors.grey88 : null};
    z-index: 3;
            
    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        border-right: 1px solid ${({ theme }) => theme ? theme.v3.colors.grey88 : null};
    }

    @media print {
        display: none;
    }
`;

export const LeftNavGridMainContent = styled.div`
    grid-area: MainContent;
`;

export const LeftNavGridContainer = styled.div`
    display: grid;
    min-height: 100vh;
    grid-template-columns: 100%;
    align-items: start;
    grid-template-rows: max-content max-content auto;
    grid-template-areas:
        'LeftBar'
        'TopBar'
        'MainContent';

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        grid-template-areas:
        'LeftBar TopBar'
        'LeftBar MainContent';
        grid-template-columns: min-content auto;
        grid-template-rows: min-content 1fr;
    }
`;

export const LeftNavNarrowWithPanelContainer = styled.div<{ $hideLeftBarDesktop: boolean }>`
    display: flex;
    width: 100%;
    align-items: flex-start;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        max-width: calc(100vw - ${LEFT_SIDE_MENU_WIDTH}px);
    }

    > div {
        &:first-child {
            ${LayoutPaddings}
            width: ${SBL_LEFT_SIDE_WIDTH}px;
            display: none;
            z-index: 2;

            ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
                display: ${({ $hideLeftBarDesktop }) => $hideLeftBarDesktop ? "none" : "block"};
                position: sticky;
                top: ${TOP_MENU_HEIGHT}px;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &:nth-child(2) {
            width: 100%;
            max-width: calc(100%); 

            ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
                max-width: ${({ $hideLeftBarDesktop }) => $hideLeftBarDesktop ? undefined : `calc(100% - ${SBL_LEFT_SIDE_WIDTH}px)`};
            }

            > div {
                margin: 0 auto 0 0;

                ${media.custom(1538)} {
                    margin: 0 auto;
                }
            }
        }
    }
`;