import styled from "styled-components";
import { MarketingLinkHoverEffect } from "../ReusableStyledComponents/MarketingLinkHover";
import { H4 } from "../Typography";

export const SmallMenuWrapper = styled.div`
    display: flex;
    align-items: center;

    > img {
        width: 24px;
        height: 24px;
        display: block;
        margin-right: 14px;
    }

    > div {
        &:nth-child(2) {
            margin-right: 13px;
            position: relative;
            top: -2px;
        }
    }
`;

export const SmallMenuText = styled(H4)`
    color:  ${({ theme, color }) => color ? color : `${theme.marketing.colors.dodgerBlue100}`};
`;

export const SmallMenuPanel = styled.div`
    padding: 30px;
    ${MarketingLinkHoverEffect}

    a {
        &:hover {
            ${SmallMenuText} {
                color:  ${({ theme }) => theme ? theme.marketing.colors.chathamsBlue : null};
            }
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
`;