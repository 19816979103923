import styled, { css, DefaultTheme } from "styled-components";
import { V3Body } from "../Typography";
import { V3TertiaryCTAIconPosition, V3TertiaryCTAColor } from "./V3TertiaryCTA.types";

const hoverFn = ({ $ctaColor, theme }: { $ctaColor: V3TertiaryCTAColor, theme: DefaultTheme }) => {
    switch ($ctaColor) {
        case V3TertiaryCTAColor.color:
            return theme.v3.colors.darkBlue;
        case V3TertiaryCTAColor.gray:
            return theme.v3.colors.grey35;
        case V3TertiaryCTAColor.red:
            return theme.v3.colors.red;
    }
}

const colorFn = ({ $ctaColor, theme }: { $ctaColor: V3TertiaryCTAColor, theme: DefaultTheme }) => {
    switch ($ctaColor) {
        case V3TertiaryCTAColor.color:
            return theme.v3.colors.blue;
        case V3TertiaryCTAColor.gray:
            return theme.v3.colors.grey48;
        case V3TertiaryCTAColor.red:
            return theme.v3.colors.red;
        case V3TertiaryCTAColor.white:
            return "white";
    }
}

const sharedCTAMixin = css<{ $ctaColor: V3TertiaryCTAColor }>`
    &:hover {
        cursor: pointer;
        ${V3Body} {
            color: ${hoverFn};
        }

        svg > path {
            stroke: ${hoverFn};
        }
    }
    
    ${V3Body} {
        color: ${colorFn};
    }

    svg > path {
        stroke: ${colorFn};
    }
`;

export const V3TertiaryCTABtn = styled.button<{ $ctaColor: V3TertiaryCTAColor }>`
    border: 0;
    padding: 0;
    background: none;
    ${sharedCTAMixin};

    &:disabled {
        cursor: initial;

        &:hover {
            ${V3Body} {
                color: ${({ theme }) => theme.v3.colors.grey48};
            }

            svg > path {
                stroke: ${({ theme }) => theme.v3.colors.grey48};
            }
        }
    }
`;

export const V3TertiaryCTALink = styled.a<{ $ctaColor: V3TertiaryCTAColor }>`
    display: inline-block;
    text-decoration: none;
    ${sharedCTAMixin};
`;

export const TCTACommonWrapper = styled.div<{
    $iconPosition: V3TertiaryCTAIconPosition
}>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > div {
        display: inline-flex;

        &:not(:last-child) {
            margin-right: ${({ $iconPosition, theme }) => {
        switch ($iconPosition) {
            case V3TertiaryCTAIconPosition.left:
                return `${theme.v3.spacings.spacing2}px`;
            case V3TertiaryCTAIconPosition.right:
                return "0";
        }
    }};
            margin-left: ${({ $iconPosition, theme }) => {
        switch ($iconPosition) {
            case V3TertiaryCTAIconPosition.left:
                return "0";
            case V3TertiaryCTAIconPosition.right:
                return `${theme.v3.spacings.spacing2}px`;
        }
    }};
        }

        &:first-child {
            order:  ${({ $iconPosition }) => {
        switch ($iconPosition) {
            case V3TertiaryCTAIconPosition.left:
                return "0";
            case V3TertiaryCTAIconPosition.right:
                return "1";
        }
    }};
        }
    }
`;