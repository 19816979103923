import styled from "styled-components";

export const WrapperWithTooltip = styled.div`
    display: flex;
    align-items: center;

    > div {
        display: inline-flex;

        &:first-child {
            margin-right: 8px;
        }
    }
`;