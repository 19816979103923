import { V3Button } from "../Button";
import { V3ButtonColor } from "../Button/V3Button.types";
import { V3EmptyState } from "./V3EmptyStateScreen";
import { EmptyStateBtnPlacer } from "./V3EmptyStateScreen.styles";

interface V3NoSearchResultsProps {
    onClearHandler?: () => void,
    customPadding?: string,
}

export const V3NoSearchResults = ({ onClearHandler, customPadding }: V3NoSearchResultsProps) => <V3EmptyState
    customPadding={customPadding}
    title="No results found"
    description="We have not found what you were looking for. Try changing the search query or the filters."
    svgIcon={<svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43 50.5c9.941 0 18-8.059 18-18s-8.059-18-18-18-18 8.059-18 18 8.059 18 18 18Z" fill="#DDDFE3" />
        <path d="m64 53.5-10-10" stroke="#DDDFE3" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M37 52.5c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15Z" stroke="#ABB0BA" strokeWidth="3" />
        <path d="m32 32.5 10 10M42 32.5l-10 10" stroke="#ABB0BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="m58 58.5-10-10" stroke="#ABB0BA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>}
>
    {
        !!onClearHandler && <EmptyStateBtnPlacer>
            <V3Button
                color={V3ButtonColor.secondary}
                text="Clear search/filters"
                onClick={onClearHandler}
                autoWidth
                minWidth={160}
            />
        </EmptyStateBtnPlacer>
    }
</V3EmptyState>