import styled from "styled-components";

const IconDotWrapper = styled.div`
    position: absolute;
    top: -6px;
    right: -3px;
`;

const IconFilterWraper = styled.div`
    position: relative;
`;

const ActiveDot = () => <IconDotWrapper>
    <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="4.5" fill="#2E77FF" stroke="#fff" />
    </svg>
</IconDotWrapper>

interface ActiveIconToggleProps {
    active: boolean,
    icon: JSX.Element
}

export const ActiveIconToggle = ({ active, icon }: ActiveIconToggleProps) => <IconFilterWraper>
    {icon}
    {
        active && <ActiveDot />
    }
</IconFilterWraper>