import { useState } from "react";
import { V3Button } from "../../../components/Button";
import { V3IconDownload, V3IconPlus } from "../../../components/Icons";
import { V3Loader } from "../../../components/Loader";
import { V3ContentLargeWrapper } from "../../../components/ReusableStyledComponents/V3ContentLargeWrapper";
import {
  AddSubsFormik,
  V3AddSubscribers,
} from "../../../components/V3AddSubscribers";
import { V3NewsletterLinksPanel } from "../../../components/V3LinksPanel";
import { V3SimpleNavbar } from "../../../components/V3SimpleNavbar";
import { VerticalSpacing } from "../../../components/VerticalSpacing";
import {
  SidebarLayout,
  SidebarLayoutShowDesktop,
} from "../../../layout/SideBarLayout";
import { SubscribersType } from "../../../mocks/subscribers";
import { V3ButtonColor } from "../../../components/Button/V3Button.types";
import { V3LightPanel } from "../../../components/V3LightPanel";
import { V3CaptionGrey48 } from "../../../components/ReusableStyledComponents/V3CaptionGrey48";
import { V3HeadlineH1 } from "../../../components/Typography";
import { formatNumber } from "../../../utils/largeNumberFormatting";
import { SubscribersHistoryData } from "../../../types/subscribers_history";
import { InfinitePaginationFooter } from "../../../components/InfinitePaginationFooter";
import { SidebarCustomizedSelect } from "../../../components/SidebarCustomizedSelect";
import { formattedSubscriberSortOptions } from "./V3NewsletterSubscribersPageLayout.utils";
import { useFormik } from "formik";
import { DropdownOptionCTA } from "../../../components/DropdownOptionCTA";
import { NSPSearchWrapper } from "./V3NewsletterSubscribersPageLayout.styles";
import { subDays } from "date-fns";
import dynamic from "next/dynamic";
import { V3Tooltip } from "../../../components/Tooltip";
import { NotificationInlineLink } from "../../../components/ReusableStyledComponents/NotificationInlineLink";
import { MAX_MANUAL_ADDED_FOLLOWERS } from "authory-api-types/dist/types";

const V3SubscribersAreaChart = dynamic(
  () =>
    import("../../../components/V3SubscribersAreaChart/V3SubscribersAreaChart"),
  { ssr: false }
);

const V3TableNewsletterSubscribers = dynamic(
  () => import("../../../components/V3Table/V3TableNewsletterSubscribers"),
  { ssr: false }
);

export interface SearchSubscribersFormik {
  orderBy: string;
}

export const DEFAULT_SUBSCRIBERS__SEARCH_PARAMS = {
  ORDERBY: formattedSubscriberSortOptions[0].value,
};

interface V3NewsletterSubscribersPageLayoutProps {
  subscribers?: SubscribersType[];
  subcribersLoading: boolean;
  subscribersLoadMore: () => void;
  subscribersCanFetchMore: boolean;
  onAddSubsSubmit: (data: AddSubsFormik) => Promise<void>;
  onCSVDownloadHandler: () => void;
  subscriberHistory?: SubscribersHistoryData;
  subscriberHistoryLoading?: boolean;
  searchArgs: any;
  onSearchFormSubmit: (e: SearchSubscribersFormik) => void;
  onDeleteSubscriber: (email: string) => void;
  isOnTrial: boolean;
  isFreePlan: boolean;
  manuallyAddedFollowerCount: number;
  isGodMode: boolean;
}

export const V3NewsletterSubscribersPageLayout = ({
  subscribers,
  subcribersLoading,
  subscribersLoadMore,
  subscribersCanFetchMore,
  onAddSubsSubmit,
  onCSVDownloadHandler,
  subscriberHistory,
  subscriberHistoryLoading,
  searchArgs,
  isOnTrial,
  isFreePlan,
  onSearchFormSubmit,
  onDeleteSubscriber,
  manuallyAddedFollowerCount,
  isGodMode = false,
}: V3NewsletterSubscribersPageLayoutProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const formik = useFormik<SearchSubscribersFormik>({
    initialValues: {
      orderBy:
        searchArgs?.orderBy || DEFAULT_SUBSCRIBERS__SEARCH_PARAMS.ORDERBY,
    },
    enableReinitialize: true,
    onSubmit: onSearchFormSubmit,
  });

  const isAboveLimit =
    manuallyAddedFollowerCount >= MAX_MANUAL_ADDED_FOLLOWERS && !isGodMode;
  const isOnTrialOrFreePlan = (isOnTrial || isFreePlan) && !isGodMode;

  const Btn = () => (
    <V3Button
      icon={<V3IconPlus />}
      text="Add subscribers"
      minWidth={180}
      onClick={() => setModalOpen(true)}
      disabled={isOnTrialOrFreePlan || isAboveLimit}
    />
  );

  return (
    <SidebarLayout
      footerVisible={false}
      disableFadeIn
      title="Newsletter"
      leftPanel={<V3NewsletterLinksPanel />}
      topNavBarContent={
        <V3SimpleNavbar
          title="Newsletter"
          rightSideContent={
            <SidebarLayoutShowDesktop>
              {isOnTrialOrFreePlan ? (
                <V3Tooltip
                  referenceContent={<Btn />}
                  tooltipMaxWidth={300}
                  tooltipContent={
                    <>
                      This feature will be available on the Standard on
                      Professional plan after your free trial is over.
                    </>
                  }
                />
              ) : isAboveLimit ? (
                <V3Tooltip
                  referenceContent={<Btn />}
                  tooltipMaxWidth={300}
                  tooltipContent={
                    <>
                      You've reached the maximum number of subscribers that you
                      can import manually. Please{" "}
                      <NotificationInlineLink
                        href={
                          "mailto:hello@authory.com?subject=Manually add followers"
                        }
                        as="a"
                        target="_blank"
                      >
                        contact us
                      </NotificationInlineLink>{" "}
                      if you'd like to import more.
                    </>
                  }
                />
              ) : (
                <Btn />
              )}
            </SidebarLayoutShowDesktop>
          }
        />
      }
      rightPanel={
        <V3ContentLargeWrapper>
          <V3SimpleNavbar title="Subscribers" withTitle />
          <VerticalSpacing bottom={48}>
            <V3LightPanel
              hideMobileFrame
              panels={[
                <>
                  <VerticalSpacing bottom={6}>
                    <V3CaptionGrey48>
                      <strong>Total subscribers</strong>
                    </V3CaptionGrey48>
                  </VerticalSpacing>
                  <VerticalSpacing bottom={32}>
                    <V3HeadlineH1>
                      {formatNumber(
                        !!subscribers?.length ? subscribers[0].count : undefined
                      )}
                    </V3HeadlineH1>
                  </VerticalSpacing>
                  <V3SubscribersAreaChart
                    data={
                      subscriberHistory?.length
                        ? subscriberHistory
                        : ([
                            { date: subDays(new Date(), 29), count: 0 },
                            { date: new Date(), count: 0 },
                          ] as unknown as SubscribersHistoryData)
                    }
                    dataLoading={subscriberHistoryLoading}
                  />
                </>,
              ]}
            />
          </VerticalSpacing>
          <VerticalSpacing top={54}>
            <V3SimpleNavbar
              title="Your subscribers"
              withTitle
              rightSideContent={
                <NSPSearchWrapper>
                  <div>
                    <SidebarCustomizedSelect
                      optionTitle={
                        <>
                          <strong>Sort by</strong>{" "}
                          {
                            formattedSubscriberSortOptions.filter(
                              (opt) => opt.value === formik.values.orderBy
                            )[0].text
                          }
                        </>
                      }
                      mobileTitle="Pick a style"
                      renderPopperElement={(_active, setShowPopper) => (
                        <div style={{ minWidth: 130 }}>
                          {formattedSubscriberSortOptions.map((opt) => (
                            <DropdownOptionCTA
                              key={opt.value}
                              label={opt.text}
                              onClick={() => {
                                formik.setFieldValue("orderBy", opt.value);
                                formik.submitForm();
                                setShowPopper(false);
                              }}
                              active={formik.values.orderBy === opt.value}
                            />
                          ))}
                        </div>
                      )}
                    />
                  </div>
                  <div>
                    <V3Button
                      color={V3ButtonColor.secondary}
                      icon={<V3IconDownload />}
                      text="Download CSV"
                      minWidth={155}
                      onClick={onCSVDownloadHandler}
                    />
                  </div>
                </NSPSearchWrapper>
              }
            />
          </VerticalSpacing>
          {!!subscribers?.length && (
            <>
              <V3TableNewsletterSubscribers
                subscribers={subscribers}
                onDeleteSubscriber={onDeleteSubscriber}
              />
              <InfinitePaginationFooter
                contentLoading={subcribersLoading}
                numPagesLoaded={subscribers.length}
                contentCanFetchmore={subscribersCanFetchMore}
                loadMore={subscribersLoadMore}
              />
            </>
          )}
          {subcribersLoading && (
            <VerticalSpacing top={20} bottom={20}>
              <V3Loader />
            </VerticalSpacing>
          )}
          <V3AddSubscribers
            modelOpen={modalOpen}
            onCloseHandler={() => setModalOpen(false)}
            onAddSubsSubmit={onAddSubsSubmit}
          />
        </V3ContentLargeWrapper>
      }
    />
  );
};
