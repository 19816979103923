export enum V3ButtonColor {
    primary = "primary",
    secondary = "secondary",
    error = "error",
}

export enum V3ButtonVariant {
    small = "small",
    large = "large",
}

export enum V3ButtonIconPosition {
    left = "left",
    right = "right",
}

export interface V3ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: keyof typeof V3ButtonColor;
    variant?: keyof typeof V3ButtonVariant;
    icon?: JSX.Element;
    text?: string;
    iconPosition?: V3ButtonIconPosition;
    className?: string;
    autoWidth?: boolean;
    minWidth?: number;
    skipIconStyling?: boolean
    relativePosition?: boolean
}

export interface V3PaypalButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: keyof typeof V3ButtonColor;
    variant?: keyof typeof V3ButtonVariant;
    iconPosition?: V3ButtonIconPosition;
    icon?: JSX.Element;
    text?: string;
}

