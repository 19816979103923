import { QueryClient, useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { V3GenericErrorHandler } from "../../../api/error-handler";
import { Queries, getAuthenticatedUserQuerie } from "../../../types/queries";
import { commitFreePlan } from "../../../api/billing";
import { CommitFreePlanBodyParams } from "authory-api-types/dist/types";
import { useRouter } from "next/router";
import { APPLICATION_ROUTES } from "../../../types/routes";

export const getCommitFreePlan = (queryClient: QueryClient, userSlug: string, token: string | null, existingItems: string[], isReactivation = false) => {
    const router = useRouter();

    const getCommitFreePlanMutation = useMutation({
        mutationFn: commitFreePlan,
        onError: V3GenericErrorHandler(toast.error),
        onSuccess: (data) => {
            queryClient.setQueryData(getAuthenticatedUserQuerie(token), data);
            queryClient.invalidateQueries({ queryKey: [Queries.Content] });
        }
    });

    const onCommitFreePlan = async (articles?: string[]) => {
        if (!token) return null;

        const params = new CommitFreePlanBodyParams();

        if (articles && articles.length) {
            params.articleIds = articles;
        } else {
            params.articleIds = existingItems;
        }

        await getCommitFreePlanMutation.mutateAsync({
            token,
            userSlug,
            params
        });

        if (isReactivation) {
            await queryClient.invalidateQueries({ queryKey: [Queries.User] });
            await router.push(APPLICATION_ROUTES.PORTFOLIO_APP).then(() => window.scrollTo(0, 0));
        }
    }

    return onCommitFreePlan;
}

