import React from "react"
import Link from "next/link";
import { SmallMenuText, SmallMenuWrapper } from "./SmallMenuLink.styles";
import { OptionalNextLink } from "../ReusableStyledComponents/OptionalNextLink";

type SmallMenuLinkProps = {
    title: string
    href: string
    imgPath: string,
    target?: string,
    useLink?: boolean
}

export const SmallMenuLink = ({ title, href, imgPath, target, useLink = true }: SmallMenuLinkProps) => {

    const Content = () =>
        <SmallMenuWrapper>
            <img src={imgPath} />
            <SmallMenuText>
                {title}
            </SmallMenuText>
            <div>
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6.70122 6.32613C7.09959 6.6983 7.09959 7.3017 6.70122 7.67387L1 13" stroke="#2E77FF" />
                </svg>
            </div>
        </SmallMenuWrapper>;

    if (useLink) return <Link href={href} passHref target={target}>
        <Content />
    </Link>

    return <OptionalNextLink href={href} target={target} useNextRouter={false}><Content /></OptionalNextLink>
}