import { APPLICATION_ROUTES } from "../../types/routes"
import { getAbsoluteUrl } from "../../utils/domainRouting"
import { V3TertiaryLink } from "../Button"
import { V3TertiaryCTAColor } from "../Button/V3TertiaryCTA.types"
import { UnstyledInlineLink } from "../ReusableStyledComponents/UnstyledLink"
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35"
import { V3CaptionGrey48 } from "../ReusableStyledComponents/V3CaptionGrey48"
import { VerticalSpacing } from "../VerticalSpacing"
import { BuiltFooterBr, DynamicLogoSectionPublicWrapper, V3FooterBlogLink, V3FooterBLogLinks, V3FooterContainer, V3FooterWrapper, V3LeftS, V3RightS } from "./V3Footer.styles"

const DynamicLogoSection = ({ showMarketingLinks = false }: { showMarketingLinks?: boolean }) => <V3LeftS>
    <div>
        <img src="/authory_static_files/images/icons/authory_logo_expanded_internal_footer.svg" />
    </div>
    <div>
        <V3CaptionGrey48>{showMarketingLinks ? <>Built with Authory. <BuiltFooterBr />
            <UnstyledInlineLink target="_blank" href={getAbsoluteUrl(`${APPLICATION_ROUTES.HOMEPAGE}?utm_source=portfolio&utm_medium=footer`)}>
                Get your own portfolio now!
            </UnstyledInlineLink></> : "© Authory 2024. All rights reserved."}</V3CaptionGrey48>
    </div>
</V3LeftS>

interface V3FooterProps {
    maxWidth?: string,
    verticalSpace?: boolean,
    showMarketingLinks?: boolean
}

export const V3Footer = ({ maxWidth, verticalSpace = true, showMarketingLinks = false }: V3FooterProps) => <VerticalSpacing top={verticalSpace ? 40 : 0}>
    <V3FooterContainer>
        {
            showMarketingLinks && <DynamicLogoSectionPublicWrapper><DynamicLogoSection showMarketingLinks={showMarketingLinks} /></DynamicLogoSectionPublicWrapper>
        }
        <V3FooterWrapper $maxWidth={maxWidth}>
            {
                showMarketingLinks ? <div>
                    <V3CaptionGrey35 as="div">
                        <V3FooterBLogLinks>
                            <li>Resources: </li>
                            <li><V3FooterBlogLink href={getAbsoluteUrl('/blog/writing-portfolio/')} target="_blank">Writing Portfolio,</V3FooterBlogLink></li>
                            <li><V3FooterBlogLink href={getAbsoluteUrl('/blog/journalism-portfolio/')} target="_blank">Journalism Portfolio,</V3FooterBlogLink></li>
                            <li><V3FooterBlogLink href={getAbsoluteUrl('/blog/content-marketing-portfolio/')} target="_blank">Content Marketing Portfolio,</V3FooterBlogLink></li>
                            <li><V3FooterBlogLink href={getAbsoluteUrl('/blog/social-media-portfolio/')} target="_blank">Social Media Portfolio</V3FooterBlogLink></li>
                        </V3FooterBLogLinks>
                    </V3CaptionGrey35>
                </div> : <DynamicLogoSection />
            }
            <V3RightS $showMarketingLinks={showMarketingLinks}>
                <div>
                    <V3TertiaryLink
                        text="About"
                        ctaColor={V3TertiaryCTAColor.gray}
                        href={getAbsoluteUrl(APPLICATION_ROUTES.ABOUT)}
                    />
                </div>
                <div>
                    <V3TertiaryLink
                        text="Imprint"
                        ctaColor={V3TertiaryCTAColor.gray}
                        href={getAbsoluteUrl(APPLICATION_ROUTES.IMPRINT)}
                    />
                </div>
                <div>
                    <V3TertiaryLink
                        text="Privacy Policy"
                        ctaColor={V3TertiaryCTAColor.gray}
                        href={getAbsoluteUrl(APPLICATION_ROUTES.PRIVACY)}
                    />
                </div>
                <div>
                    <V3TertiaryLink
                        text="Terms"
                        ctaColor={V3TertiaryCTAColor.gray}
                        href={getAbsoluteUrl(APPLICATION_ROUTES.TERMS)}
                    />
                </div>
            </V3RightS>
        </V3FooterWrapper>
    </V3FooterContainer>
</VerticalSpacing>