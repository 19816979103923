import * as Sentry from '@sentry/nextjs'
import { AxiosError } from "axios";

const SENTRY_FLUSH_WAIT_TIME = 2000;

/**
 * This method logs and Axios Type Error to Sentry
 * @param error Axios Error
 */
export const logAxiosError = async (error: AxiosError) => {
  Sentry.setExtra("toJSON()", error.toJSON());
  Sentry.setExtra("toJSON()", error.response);
  Sentry.captureException(error.message);
  await Sentry.flush(SENTRY_FLUSH_WAIT_TIME);
};

/**
 * This method logs an unexpected Error, hence any type to Entry
 * @param error Any Error
 */
export const logError = async (error: any) => {
  Sentry.captureException(error);
  await Sentry.flush(SENTRY_FLUSH_WAIT_TIME);
};
