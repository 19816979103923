import { useContext } from "react";
import { useQuery } from '@tanstack/react-query';
import { getAuthenticatedUser } from "../api/user";
import { getAuthenticatedUserQuerie } from "../types/queries";
import { TrackPageView } from "./TrackPageView";
import MixpanelTracker from "../tracking/Mixpanel";
import GoogleAnalyticsTracker from "../tracking/GoogleAnalytics";
import { useRouter } from "next/router";
import { APPLICATION_ROUTES } from "../types/routes";
import { CookieContext } from "../context/cookieContext";
import { parseCookies } from "nookies";
import { getAbTestCookies } from "../utils/getABTestingCookies";

const getPageType = (url: string) => {
    switch (url) {
        case APPLICATION_ROUTES.PORTFOLIO:
        case APPLICATION_ROUTES.TRACKING:
        case APPLICATION_ROUTES.ARCHIVE:
        case APPLICATION_ROUTES.COLLECTIONS_MARKETING:
        case APPLICATION_ROUTES.NEWSLETTER_MARKETING:
        case APPLICATION_ROUTES.ANALYTICS_MARKETING:
        case APPLICATION_ROUTES.USE_CASE_WRITERS:
        case APPLICATION_ROUTES.USE_CASE_THOUGHT_LEADERS:
        case APPLICATION_ROUTES.USE_CASE_CONTENT_MARKETERS:
        case APPLICATION_ROUTES.USE_CASE_JOURNALISTS:
        case APPLICATION_ROUTES.ABOUT:
        case APPLICATION_ROUTES.REVIEWS:
        case APPLICATION_ROUTES.OWNERSHIP:
        case APPLICATION_ROUTES.PRIVACY:
        case APPLICATION_ROUTES.TERMS:
        case APPLICATION_ROUTES.DMCA:
        case APPLICATION_ROUTES.PRICING:
            return "marketing";
        case APPLICATION_ROUTES.HOMEPAGE:
        case APPLICATION_ROUTES.HOMEPAGE_VARIANT:
            return "homepage";
        case APPLICATION_ROUTES.BLOG:
        case APPLICATION_ROUTES.BLOG_POST:
            return "blog";
        case APPLICATION_ROUTES.EXAMPLES:
        case APPLICATION_ROUTES.EXAMPLES_CATEGORIES:
        case APPLICATION_ROUTES.EXAMPLES_PROFESSION:
        case APPLICATION_ROUTES.EXAMPLES_PROFESSION_CATEGORIES:
        case APPLICATION_ROUTES.EXAMPLES_SOURCE:
        case APPLICATION_ROUTES.EXAMPLES_JOB:
            return "example_pages";
        case APPLICATION_ROUTES.AUTHOR_PAGE:
            return "profile";
        case APPLICATION_ROUTES.CONTENT:
        case APPLICATION_ROUTES.PORTFOLIO_APP:
        case APPLICATION_ROUTES.COLLECTIONS:
        case APPLICATION_ROUTES.COLLECTION:
        case APPLICATION_ROUTES.NEWSLETTER:
        case APPLICATION_ROUTES.NEWSLETTER_SUBSCRIBERS:
        case APPLICATION_ROUTES.NEWSLETTER_REPORT:
        case APPLICATION_ROUTES.NEWSLETTER_SETTINGS:
        case APPLICATION_ROUTES.ANALYTICS:
        case APPLICATION_ROUTES.ANALYTICS_WEB:
        case APPLICATION_ROUTES.SETTINGS_ACCOUNT:
        case APPLICATION_ROUTES.SETTINGS_BILLING:
        case APPLICATION_ROUTES.SETTINGS_REFERRAL:
        case APPLICATION_ROUTES.SETTINGS_SOURCES:
        case APPLICATION_ROUTES.SETTINGS_NOTIFICATIONS:
        case APPLICATION_ROUTES.WRITE:
        case APPLICATION_ROUTES.SIGNUP:
        case APPLICATION_ROUTES.SIGNUP_ANIMATION:
        case APPLICATION_ROUTES.SIGNUP_COMPLETE_NAME:
        case APPLICATION_ROUTES.SIGNUP_CHOOSE_PLAN:
        case APPLICATION_ROUTES.SIGNUP_DEACTIVATED:
        case APPLICATION_ROUTES.LOGIN:
        case APPLICATION_ROUTES.LOGIN_FORGOTTEN_PASSWORD:
            return "app";
        default:
            return undefined;
    }
}

export const TrackPageViewManager = () => {
    const token = useContext(CookieContext);
    const router = useRouter();
    const cookies = parseCookies();
    const abCookies = getAbTestCookies(cookies);

    // Get the user
    const authenticatedUser = useQuery({
        queryKey: getAuthenticatedUserQuerie(token),
        queryFn: () => getAuthenticatedUser(token),
        throwOnError: false,
        meta: { logNotFound: true }
    });

    // Initialize trackers
    if (authenticatedUser.isSuccess) {
        const mixpanelTracker = new MixpanelTracker(authenticatedUser.data);
        const googleAnalyticsTracker = new GoogleAnalyticsTracker(authenticatedUser.data);

        return (<>
            <TrackPageView onRouteChange={() => {
                mixpanelTracker.trackPageView({ page_type: getPageType(router.pathname), ...abCookies },);
            }} />
            <TrackPageView onRouteChange={googleAnalyticsTracker.trackPageView} />
        </>)
    }

    return null;
};