import styled from "styled-components";

export const DropdownContentWrapper = styled.div<{ $maxWidth?: number }>`
    max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : "1376px;")}; 
    box-shadow: 0 2px 6px rgb(13 23 75 / 25%);
    border-radius: 4px;
    background: white;
    z-index: 3;
`

export const DropdownMenuArrow = styled.span`
    &:before {
        left: 10px;
        transform-origin: 0 0;
        transform: rotate(135deg);
        position: absolute;
        width: 0;
        height: 0;
        content: "";
        box-sizing: border-box;
        border: 1em solid black;
        border-color: transparent transparent white white;
        box-shadow: -3px 3px 4px 0 rgba(0, 0, 0, 0.08);
    }
`;