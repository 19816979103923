import { WebsiteResponse } from "authory-api-types/dist/types";
import { SourceType } from "authory-api-types/dist/enums";

export const getSourceStatus = (sources: WebsiteResponse[], types: SourceType[]) => {

    const src = sources.filter(source => types.includes(source.type));
    const hasSrc = !!src.length;
    const srcInProgress = hasSrc && src.every(source => source.inProgress);

    return { hasSrc, srcInProgress };
}