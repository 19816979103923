import { AxiosError } from "axios";
import { UnstyledLink } from "../components/ReusableStyledComponents/UnstyledLink";
import { logAxiosError } from "../logging/logSentryError";
import { Errors } from "../types/api";

export type NotificationHandler = (message: string | JSX.Element, options?: any) => string;

const ERROR_MESSAGE = "An unexpected error occurred";

export const V3GenericErrorHandler = (toastHandler: NotificationHandler) => async (error: AxiosError) => {
    if (error?.response?.status === Errors.InternalServerError) {
        toastHandler(ERROR_MESSAGE);
    } else if (error?.response?.data?.message) {
        toastHandler(error?.response?.data?.message);
    } else {
        toastHandler(ERROR_MESSAGE);
    }

    logAxiosError(error);
};

export const V3SetPaymentErrorHandler = (toastHandler: NotificationHandler) => async (error: AxiosError) => {
    if (error?.response?.status === Errors.State) {
        toastHandler(<span>There was a problem trying to update your payment method, please reach us on <UnstyledLink textDecoration="underline" href={"mailto:hello@authory.com?subject=Paypal payment update"}>hello@authory.com</UnstyledLink>.</span>, { duration: 4000 });
    } else if (error?.response?.status === Errors.Validation) {
        toastHandler(error?.response?.data?.message);
    } else {
        toastHandler(ERROR_MESSAGE);
        logAxiosError(error);
    }
};

export const V3SetPaymentAddressErrorHandler = (toastHandler: NotificationHandler) => async (error: AxiosError) => {
    if (error?.response?.status === Errors.Validation) {
        toastHandler(error?.response?.data?.message);
    } else {
        toastHandler(ERROR_MESSAGE);
        logAxiosError(error);
    }
};