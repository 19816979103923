export const getPageViewPayload = (data?: any) => {
    if (process.browser) {
        return {
            referrer: document.referrer,
            url: document.location.href,
            ...data
        }
    } else {
        return {}
    }
}