import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SubscriptionGateway } from "authory-api-types/dist/enums";
import { AuthenticatedUserResponse } from "authory-api-types/dist/types";
import { useStripePayment, V3PaymentFormik } from "../../hooks/useStripePayment";
import { V3Button } from "../Button";
import { V3Loader } from "../Loader";
import { V3RadioButton } from "../OptionInput";
import { RightAlignPanel } from "../ReusableStyledComponents/RightAlignPanel";
import { PayPalSVG } from "../SignUpImages/paypal";
import { V3StripeInput } from "../TextInput/V3TextInput";
import { VerticalSpacing } from "../VerticalSpacing";
import { CardsCVCDateWrapper, ModalPaymentOptions } from "./V3ModalPayment.styles";
import { Fragment } from "react";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID!);

const stripeStyle = {
    style: {
        base: {
            backgroundColor: "#F4F4F6",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: "28px",
            "::placeholder": {
                color: "#6E7687"
            },
        },
    }
}

export interface PaymentOptionsProps {
    onCloseHandler: () => void,
    user: AuthenticatedUserResponse,
    onPaymentUpdateHandler: (data: V3PaymentFormik) => Promise<any>,
    trackEvent: (eventName: string, data: any) => void,
    isEndOfTrialFlow?: boolean,
}

const PaymentOptions = ({ onCloseHandler, user, onPaymentUpdateHandler, trackEvent, isEndOfTrialFlow = false }: PaymentOptionsProps) => {

    const {
        fetchingStripeData,
        showApplePay,
        showGooglePay,
        paymentOptionsLoading,
        formik,
    } = useStripePayment(user?.slug!, onPaymentUpdateHandler, onCloseHandler, undefined, undefined, undefined, undefined, trackEvent);

    const WrapperElem = isEndOfTrialFlow ? Fragment : RightAlignPanel;

    return <>
        {
            paymentOptionsLoading ? <VerticalSpacing top={32} bottom={32}>
                <V3Loader />
            </VerticalSpacing>
                : <>
                    <VerticalSpacing bottom={24}>
                        <ModalPaymentOptions>
                            {
                                showApplePay && <div>
                                    <V3RadioButton
                                        id="apple_pay"
                                        value={SubscriptionGateway.ApplePay}
                                        name="apple_pay"
                                        label={"Apple Pay"}
                                        checked={formik.values.subscriptionGateway === SubscriptionGateway.ApplePay}
                                        onChange={() => {
                                            if (formik.values.subscriptionGateway !== SubscriptionGateway.ApplePay) {
                                                formik.setFieldValue("subscriptionGateway", SubscriptionGateway.ApplePay);
                                            }
                                        }}
                                        disabled={fetchingStripeData}
                                    />
                                </div>
                            }
                            {
                                showGooglePay && <div>
                                    <V3RadioButton
                                        id="google_pay"
                                        value={SubscriptionGateway.GooglePay}
                                        name="google_pay"
                                        label={"Google Pay"}
                                        checked={formik.values.subscriptionGateway === SubscriptionGateway.GooglePay}
                                        onChange={() => {
                                            if (formik.values.subscriptionGateway !== SubscriptionGateway.GooglePay) {
                                                formik.setFieldValue("subscriptionGateway", SubscriptionGateway.GooglePay);
                                            }
                                        }}
                                        disabled={fetchingStripeData}
                                    />
                                </div>
                            }
                            <div>
                                <V3RadioButton
                                    id="pay_card"
                                    value={SubscriptionGateway.Stripe}
                                    name="subscriptionGateway"
                                    label={"Credit Card"}
                                    checked={formik.values.subscriptionGateway === SubscriptionGateway.Stripe}
                                    onChange={() => {
                                        if (formik.values.subscriptionGateway !== SubscriptionGateway.Stripe) {
                                            formik.setFieldValue("subscriptionGateway", SubscriptionGateway.Stripe);
                                        }
                                    }}
                                    disabled={fetchingStripeData}
                                />
                            </div>
                            <div>
                                <V3RadioButton
                                    id="paypal"
                                    value={SubscriptionGateway.Paypal}
                                    name="subscriptionGateway"
                                    label={"PayPal"}
                                    checked={formik.values.subscriptionGateway === SubscriptionGateway.Paypal}
                                    onChange={() => {
                                        if (formik.values.subscriptionGateway !== SubscriptionGateway.Paypal) {
                                            formik.setFieldValue("subscriptionGateway", SubscriptionGateway.Paypal);
                                        }
                                    }}
                                    disabled={fetchingStripeData}
                                />
                            </div>
                        </ModalPaymentOptions>
                    </VerticalSpacing>
                    <form onSubmit={formik.handleSubmit}>
                        {
                            formik.values.subscriptionGateway === SubscriptionGateway.Stripe && <>
                                <VerticalSpacing bottom={16}>
                                    <V3StripeInput
                                        label="Credit card number:"
                                        name="cardnumber"
                                        inputField={<CardNumberElement
                                            options={{ ...stripeStyle, disabled: fetchingStripeData }}
                                        />}
                                        error={formik.errors.hasOwnProperty("cardnumber") ? formik.errors.cardnumber : undefined}
                                    />
                                </VerticalSpacing>
                                <VerticalSpacing bottom={24}>
                                    <CardsCVCDateWrapper>
                                        <div>
                                            <V3StripeInput
                                                label="Expiration:"
                                                name="expDate"
                                                inputField={<CardExpiryElement
                                                    options={{ ...stripeStyle, disabled: fetchingStripeData }}
                                                />}
                                                error={formik.errors.hasOwnProperty("expDate") ? formik.errors.expDate : undefined}
                                            />
                                        </div>
                                        <div>
                                            <V3StripeInput
                                                label="CVC code:"
                                                name="cvc"
                                                inputField={<CardCvcElement
                                                    options={{ ...stripeStyle, disabled: fetchingStripeData }}
                                                />}
                                                error={formik.errors.hasOwnProperty("cvc") ? formik.errors.cvc : undefined}
                                            />
                                        </div>
                                    </CardsCVCDateWrapper>
                                </VerticalSpacing>
                            </>
                        }
                        <WrapperElem>
                            {
                                fetchingStripeData ? <V3Loader height={42} /> : <>
                                    {
                                        formik.values.subscriptionGateway === SubscriptionGateway.Paypal ? <V3Button
                                            text=""
                                            autoWidth
                                            type="submit"
                                            disabled={fetchingStripeData}
                                            icon={<span style={{ color: "white", }}><span style={{ marginRight: 6, position: "relative", top: -3 }}>Go to</span> <PayPalSVG white /></span>}
                                        /> : <V3Button
                                            text={isEndOfTrialFlow ? "Save and continue" : "Save"}
                                            minWidth={isEndOfTrialFlow ? 180 : undefined}
                                            autoWidth
                                            disabled={fetchingStripeData}
                                            type="submit"
                                        />
                                    }
                                </>
                            }
                        </WrapperElem>
                    </form>
                </>
        }
    </>
}

export const PaymentOptionsContainer = (props: PaymentOptionsProps) => {
    return <Elements stripe={stripePromise}>
        <PaymentOptions
            {...props}
        />
    </Elements>
}