import Link from "next/link"
import { useRouter } from "next/router";
import { V3Tooltip } from "../Tooltip";
import { LeftNavBodyLink, LeftNavbarLinkWrapper } from "./LeftNavBar.styles";
import { Placement } from "@floating-ui/react";

interface LeftNavBarLinkProps extends React.PropsWithChildren {
    href: string,
    active?: boolean,
    title: string | JSX.Element,
    placement?: Placement,
    inactive?: boolean,
    tooltipMaxWidth?: number,
}

export const LeftNavBarLink = ({ href, children, active, title, placement = "right", inactive = false, tooltipMaxWidth }: LeftNavBarLinkProps) => {
    const router = useRouter();

    return <V3Tooltip
        delay={0}
        tooltipMaxWidth={tooltipMaxWidth}
        placement={placement}
        noCursor={inactive}
        referenceContent={<LeftNavbarLinkWrapper $inactive={inactive}>
            <Link href={href} passHref legacyBehavior>
                <LeftNavBodyLink as="a" $active={active || router.route === href} $inactive={inactive}>
                    {children}
                </LeftNavBodyLink >
            </Link>
        </LeftNavbarLinkWrapper>}
        tooltipContent={title}
    />
}