import { logError } from "../../logging/logSentryError";

export const PAGE_VIEW_EVENT = "PageView";
const SIGNUP_URL = "/signup/welcome";

/**
 * This function ensures that certain events are called only once, per business requirements
 * @param localStorageKey local storage key
 * @param event the event name
 * @param track the track function to call
 */
export const dedupedTrack = (localStorageKey: string, event: string, track: () => void) => {
    try {
        const trackedEvents = JSON.parse(window.localStorage.getItem(localStorageKey) || `[]`);
        const currentUrl = `${window.location.host}${window.location.pathname}`;

        // Allow all "PageView" events multiple times, except those one /signup/welcome
        if (event === PAGE_VIEW_EVENT && currentUrl !== `${window.location.host}${SIGNUP_URL}`) {
            track();
            return;
        }

        // All other events, only allow once
        if (!trackedEvents.includes(event)) {
            track();
            trackedEvents.push(event);
            window.localStorage.setItem(localStorageKey, JSON.stringify(trackedEvents));
        }

    } catch (err) {
        logError({
            error: "Error on deduped track",
            localStorageKey,
            event: event,
            err
        });
    }
}