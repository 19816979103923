import { BaseTracker } from '../BaseTracker';
import MixPanel from 'mixpanel-browser';
import { getPageViewPayload } from '../utils/getPageViewData';
import { logError } from '../../logging/logSentryError';
import { AuthenticatedUser } from '../../types/user';

/**
 * MixpanelTracker class responsible for tracking mixpanel events
 */
export class MixpanelTracker extends BaseTracker {
    constructor(user: AuthenticatedUser) {
        super(user, process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);
        this.identify = this.identify.bind(this);
    }

    protected initializeInternal() {
        MixPanel.init(this.providerToken!);
        this.identify();
    }

    protected trackPageViewInternal(data?: any) {
        MixPanel.track("page_view", getPageViewPayload(data));
    }

    protected trackEventInternal(key: string, data: any) {
        MixPanel.track(key, data);
    }

    protected shutdownInternal() {
        MixPanel.reset();
    }

    identify(communicationId?: string) {
        if (this.providerCanRun()) {
            try {
                if (this.user?.communicationId) {
                    MixPanel.identify(this.user.communicationId);
                } else if (communicationId) {
                    MixPanel.identify(communicationId);
                }
            } catch (err) {
                logError({
                    error: "Error on MixpanelTracker - identify",
                    err
                });
            }
        }
    }

    getDistinctId() {
        if (this.providerCanRun()) {
            try {
                return MixPanel.get_distinct_id();
            } catch (err) {
                logError({
                    error: "Error on MixpanelTracker - get distinct id",
                    err
                });
            }
        }
    }
}
