import styled from "styled-components";

export const FadeInContainer = styled.div<{ $disableFadeIn?: boolean, $customDuration?: string }>`
    animation-duration: ${({ $customDuration }) => $customDuration || "300ms"};
    animation-fill-mode: both;
    opacity: ${({ $disableFadeIn }) => $disableFadeIn ? 1 : 0};
    animation-name: ${({ $disableFadeIn }) => !$disableFadeIn ? "fadeIn" : undefined};
    animation-timing-function: ease-out;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;