import styled from "styled-components";

interface LogoSVGProps {
    $hasOpacity: boolean
}

const LogoSVG = styled.svg<LogoSVGProps>`
    opacity:  ${({ $hasOpacity }) => $hasOpacity ? '0.5' : '1'};
    &:hover {
        opacity: 1;
        cursor: ${({ $hasOpacity }) => $hasOpacity ? 'pointer' : undefined};
    }
`;

interface V3AuthoryLogoProps {
    color?: string,
    hideCopy?: boolean,
    hasOpacity?: boolean,
    width?: string
    height?: string
}

export const V3AuthoryLogo = ({ color = "#2E77FF", hideCopy, hasOpacity = false, ...rest }: V3AuthoryLogoProps) => <LogoSVG
    width={hideCopy ? 42 : 127}
    height="42"
    viewBox={hideCopy ? "0 0 42 42" : "0 0 127 42"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    $hasOpacity={hasOpacity}
    {...rest}
>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.97754 20.9998C1.97754 31.4896 10.5114 40.0236 21.0011 40.0236C31.4907 40.0236 40.0246 31.4896 40.0246 20.9998C40.0246 10.5106 31.4907 1.97656 21.0011 1.97656C10.5114 1.97656 1.97754 10.5106 1.97754 20.9998ZM0 20.9998C0 9.40218 9.40207 0 21 0C32.5979 0 42 9.40218 42 20.9998C42 32.5978 32.5979 42 21 42C9.40207 42 0 32.5978 0 20.9998ZM20.9999 16.6776C21.8172 16.6776 22.4823 16.0125 22.4823 15.1953C22.4823 14.378 21.8172 13.7129 20.9999 13.7129C20.1827 13.7129 19.5176 14.378 19.5176 15.1953C19.5176 16.0125 20.1827 16.6776 20.9999 16.6776ZM25.4088 19.866L23.845 17.1572C23.2204 18.0605 22.1783 18.6539 20.9998 18.6539C19.8214 18.6539 18.7798 18.0605 18.1547 17.1572L16.5908 19.866C17.7782 20.9872 19.3505 21.6187 20.9998 21.6187C22.6497 21.6187 24.222 20.9872 25.4088 19.866ZM11.1578 29.2766H8.87598L21.0001 8.27637L33.1243 29.2766H30.8425L26.4186 21.6142C24.9131 22.8856 23.0003 23.5952 21.0001 23.5952C19.0004 23.5952 17.0872 22.8851 15.5821 21.6142L11.1578 29.2766Z" fill={color} />
    {
        !hideCopy && <>
            <path fillRule="evenodd" clipRule="evenodd" d="M52 26.7987H53.8063L55.089 23.9807C55.8499 24.0899 56.6207 24.1526 57.4004 24.1526C58.1901 24.1526 58.9703 24.0878 59.7406 23.9765L60.9675 26.7987H62.803L57.4982 15L52 26.7987ZM57.4007 22.4782C56.8673 22.4782 56.3376 22.4484 55.8131 22.392L57.4698 18.7532L59.049 22.3852C58.5047 22.4463 57.9545 22.4782 57.4007 22.4782Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M97.2793 17.1714C98.4209 16.0497 99.7931 15.4892 101.395 15.4892C102.979 15.4892 104.336 16.055 105.469 17.186C106.607 18.3171 107.174 19.676 107.174 21.2626C107.174 22.8586 106.604 24.2128 105.462 25.3245C104.315 26.4415 102.931 27 101.31 27C99.8747 27 98.5867 26.5042 97.4451 25.5116C96.188 24.4139 95.5589 22.9762 95.5589 21.1978C95.5589 19.6352 96.1325 18.2931 97.2793 17.1714ZM101.331 25.4327C102.497 25.4327 103.48 25.0299 104.281 24.2248C105.083 23.4098 105.483 22.4177 105.483 21.248C105.483 20.0642 105.088 19.0721 104.296 18.2717C103.509 17.4661 102.535 17.0638 101.374 17.0638C100.218 17.0638 99.2413 17.4661 98.4448 18.2717C97.6484 19.0674 97.2504 20.0501 97.2504 21.2193C97.2504 22.4465 97.663 23.4558 98.4882 24.2463C99.3087 25.0372 100.256 25.4327 101.331 25.4327Z" fill={color} />
            <path d="M71.2002 22.3839C71.2002 23.3519 71.0443 24.0494 70.7327 24.4762C70.2667 25.1136 69.6094 25.4323 68.7607 25.4323C67.9161 25.4323 67.2614 25.1136 66.7954 24.4762C66.4843 24.0353 66.3279 23.3378 66.3279 22.3839V15.6972H64.6514V22.8441C64.6514 24.0139 65.0158 24.9773 65.7448 25.7343C66.5606 26.578 67.5657 26.9996 68.7607 26.9996C69.9556 26.9996 70.9628 26.578 71.7833 25.7343C72.5123 24.9773 72.8767 24.0139 72.8767 22.8441V15.6972H71.2002V22.3839Z" fill={color} />
            <path d="M75.2584 17.2718H77.813V26.7985H79.4901V17.2718H82.0373V15.6972H75.2584V17.2718Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M115.717 20.9819C116.168 20.4161 116.393 19.7406 116.393 18.9543C116.393 17.9476 116.033 17.1378 115.313 16.5245C114.661 15.9728 113.6 15.6975 112.132 15.6975H110.168V26.7987H111.845V22.2546H112.147L115.321 26.7987H117.372L113.932 22.0675C114.67 21.9092 115.266 21.5477 115.717 20.9819ZM111.845 20.795V17.2722H112.442C113.987 17.2722 114.759 17.8401 114.759 18.9764C114.759 20.189 113.965 20.795 112.377 20.795H111.845Z" fill={color} />
            <path d="M122.524 20.1335L125.071 15.6975H127L123.358 22.0028V26.7987H121.682V22.0028L118.055 15.6975H119.983L122.524 20.1335Z" fill={color} />
            <path d="M88.4922 19.9895C89.3023 19.9895 90.1024 19.9154 90.8894 19.7863V15.6972H92.566V26.7985H90.8894V21.4795C90.1008 21.5966 89.3012 21.664 88.4922 21.664C87.6838 21.664 86.8842 21.5966 86.0961 21.4801V26.7985H84.4195V15.6972H86.0961V19.7863C86.8826 19.9159 87.6822 19.9895 88.4922 19.9895Z" fill={color} />
        </>
    }
</LogoSVG>

export default V3AuthoryLogo; 