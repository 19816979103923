import { SubscriptionPlan } from "authory-api-types/dist/enums";
import { Discounts } from "authory-api-types/dist/lib/Discounts";
import { useState } from "react";
import { get2023PlanPrice, getDiscountPercentage } from "../../utils/getPlanPrice";
import { AUTHORY_2023_PLAN_TIERS } from "../../utils/pricingPerks";
import { V3Button } from "../Button";
import { V3IconHelpCircle } from "../Icons";
import { V3Loader } from "../Loader";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { V3CaptionGrey48 } from "../ReusableStyledComponents/V3CaptionGrey48";
import { V3Tooltip } from "../Tooltip";
import { V3Paragraph } from "../Typography";
import { VerticalSpacing } from "../VerticalSpacing";
import { PlanTooltipPos, PPCard, PPCardDivider, PPCardPrice, PPCardPricePerk, PPerkWrapper, PPPriceSaveText } from "./PaymentPlansSelector.styles";

interface PaymentPlanCardProps {
    active?: boolean,
    onClick?: () => void,
    isPro?: boolean,
    isYearly?: boolean,
    referringUser?: string,
    billingAreaVariant?: boolean,
    isLegacyPaypal?: boolean,
    submitting?: boolean,
    currentPlan?: SubscriptionPlan | null,
    onPlanSelect?: (plan: SubscriptionPlan) => Promise<void>,
    isTrial?: boolean,
}

export const PaymentPlanCard = ({ active, onClick, isPro, isYearly = false, referringUser, billingAreaVariant = false, isLegacyPaypal = false, submitting, currentPlan, onPlanSelect, isTrial = false }: PaymentPlanCardProps) => {
    const tier = isPro ? AUTHORY_2023_PLAN_TIERS.PRO : AUTHORY_2023_PLAN_TIERS.STANDARD;

    const discount = referringUser ? Discounts.getDiscountForReferrer(referringUser) : undefined;
    const priceCutPercentage = getDiscountPercentage(discount, isYearly);

    const isCurrentPlan = (currentPlan === SubscriptionPlan.monthly && !isYearly && !isPro)
        || (currentPlan === SubscriptionPlan.yearly && isYearly && !isPro)
        || (currentPlan === SubscriptionPlan.proMonthly && !isYearly && isPro)
        || (currentPlan === SubscriptionPlan.proYearly && isYearly && isPro);

    const hasNoPlan = !currentPlan && billingAreaVariant;

    const [clickedPlan, setClickedPlan] = useState(false);

    return <PPCard $active={active} onClick={onClick} $billingAreaVariant={billingAreaVariant} data-testid={`plan-selector-${isPro ? "pro" : "standard"}`}>
        <VerticalSpacing bottom={14}>
            <V3Paragraph>{tier.label}</V3Paragraph>
        </VerticalSpacing>
        {
            isYearly && <VerticalSpacing bottom={12}>
                <PPPriceSaveText><strong><span>normally ${tier.price}/mon</span></strong></PPPriceSaveText>
            </VerticalSpacing>
        }
        <VerticalSpacing bottom={12}>
            <PPCardPrice>${get2023PlanPrice(tier.price, isYearly, tier.yearlyDiscountFactor, priceCutPercentage)}<span>/month</span></PPCardPrice>
        </VerticalSpacing>
        <VerticalSpacing bottom={18}>
            <V3CaptionGrey35>paid {isYearly ? "yearly" : "monthly"}</V3CaptionGrey35>
        </VerticalSpacing>
        <VerticalSpacing bottom={18}>
            <PPCardDivider />
        </VerticalSpacing>
        {
            isPro && <VerticalSpacing bottom={18} style={{ alignSelf: "flex-start", paddingLeft: 12 }}>
                <V3CaptionGrey35 >Everything in Standard, plus:</V3CaptionGrey35>
            </VerticalSpacing>
        }
        <PPerkWrapper>
            {
                tier.perks.map((perk, index) => <PPCardPricePerk key={index}>
                    <V3CaptionGrey35>{perk.label}<PlanTooltipPos>
                        <V3Tooltip
                            referenceContent={<V3IconHelpCircle />}
                            tooltipMaxWidth={250}
                            tooltipContent={perk.tooltip}
                        />
                    </PlanTooltipPos>
                    </V3CaptionGrey35>
                </PPCardPricePerk>)
            }
        </PPerkWrapper>
        {
            referringUser && discount && !!priceCutPercentage && <VerticalSpacing top={24} bottom={8}>
                <V3CaptionGrey48>{`${priceCutPercentage}% ${referringUser} discount included`}</V3CaptionGrey48>
            </VerticalSpacing>
        }
        {
            billingAreaVariant && <VerticalSpacing top={10}>
                {
                    submitting && clickedPlan ? <V3Loader background="transparent" height={42} /> : <V3Button
                        text={isCurrentPlan ? isTrial ? "Trialing currently" : "Your current plan" : (isLegacyPaypal || hasNoPlan) ? "Select" : isTrial ? "Trial this plan" : "Switch to this plan"}
                        autoWidth
                        disabled={isCurrentPlan || submitting}
                        onClick={async () => {
                            let selectedPlan: SubscriptionPlan;

                            if (isYearly) {
                                selectedPlan = isPro ? SubscriptionPlan.proYearly : SubscriptionPlan.yearly;
                            } else {
                                selectedPlan = isPro ? SubscriptionPlan.proMonthly : SubscriptionPlan.monthly;
                            }
                            setClickedPlan(true);
                            onPlanSelect && await onPlanSelect(selectedPlan);
                            setClickedPlan(false);
                        }}
                    />
                }
            </VerticalSpacing>
        }
    </PPCard>
}