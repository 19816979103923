import { SubscriptionPlan } from "authory-api-types/dist/enums"
import { useEffect, useState } from "react"
import { AUTHORY_2023_PLAN_TIERS } from "../../utils/pricingPerks"
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35"
import { V3ToggleSwitch } from "../ToggleSwitch"
import { VerticalSpacing } from "../VerticalSpacing"
import { PPCardWrapper, PPToggleComp, PPToggleWrapper, V3PricePeriodCaption } from "./PaymentPlansSelector.styles"
import { PaymentPlanCard } from "./PPCard"

interface PaymentPlansSelectorProps {
    referringUser?: string,
    currentPlan?: SubscriptionPlan | null,
    isLegacyPaypal?: boolean,
    billingAreaVariant?: boolean,
    onPlanSelect: (plan: SubscriptionPlan) => Promise<void>,
    submitting?: boolean,
    isTrial?: boolean,
    paymentPlanShortLabel?: string,
    isFreePlanOnTrial?: boolean,
}

export const PaymentPlansSelector = ({ referringUser, billingAreaVariant = false, isLegacyPaypal, onPlanSelect, submitting, currentPlan, isTrial = false, paymentPlanShortLabel = "Choose plan", isFreePlanOnTrial = false }: PaymentPlansSelectorProps) => {
    const isStandardMonthly = currentPlan === SubscriptionPlan.monthly;
    const isProMonthly = currentPlan === SubscriptionPlan.proMonthly;
    const isProYearly = currentPlan === SubscriptionPlan.proYearly;

    const [isYearly, setisYearly] = useState(!isStandardMonthly && !isProMonthly);
    const [isPro, setIsPro] = useState(isProYearly || isProMonthly);

    useEffect(() => {
        if (billingAreaVariant) return;

        let selectedPlan: SubscriptionPlan;

        if (isYearly) {
            selectedPlan = isPro ? SubscriptionPlan.proYearly : SubscriptionPlan.yearly;
        } else {
            selectedPlan = isPro ? SubscriptionPlan.proMonthly : SubscriptionPlan.monthly;
        }

        onPlanSelect(selectedPlan);
    }, [isYearly, isPro]);

    return <>
        <VerticalSpacing bottom={20}>
            <PPToggleWrapper>
                <div>
                    <V3CaptionGrey35><strong>{paymentPlanShortLabel}</strong></V3CaptionGrey35>
                </div>
                <div>
                    <PPToggleComp>
                        <div><V3PricePeriodCaption $active={!isYearly}><strong>Paid monthly</strong></V3PricePeriodCaption></div>
                        <div><V3ToggleSwitch
                            labelDataId="signup-plan-time-period-toggle"
                            name="planPeriodSelection"
                            checked={isYearly}
                            onChange={() => setisYearly(!isYearly)}
                        /></div>
                        <div><V3PricePeriodCaption $active={isYearly}><strong>Paid yearly (-20%)</strong></V3PricePeriodCaption></div>
                    </PPToggleComp>
                </div>
            </PPToggleWrapper>
        </VerticalSpacing>
        <PPCardWrapper>
            {
                Object.entries(AUTHORY_2023_PLAN_TIERS).map(([key]) => {
                    return <div key={key}>
                        <PaymentPlanCard
                            active={!billingAreaVariant ? (isPro && key === "PRO" || !isPro && key === "STANDARD") : undefined}
                            onClick={() => setIsPro(key === "STANDARD" ? false : true)}
                            isYearly={isYearly}
                            isPro={key !== "STANDARD"}
                            referringUser={referringUser}
                            billingAreaVariant={billingAreaVariant}
                            isLegacyPaypal={isLegacyPaypal}
                            submitting={submitting}
                            currentPlan={currentPlan}
                            onPlanSelect={onPlanSelect}
                            isTrial={isTrial}
                        />
                    </div>
                })
            }
        </PPCardWrapper>
        {
            isFreePlanOnTrial && <VerticalSpacing top={25}>
                <V3CaptionGrey35><strong>Looking for the Free plan?</strong> If you don't enter any payment details before your trial is over, you'll be automatically downgraded to the Free plan. Please note that the Free plan is limited to 10 content items, meaning all additional items in your account will be deleted.</V3CaptionGrey35>
            </VerticalSpacing>
        }
    </>
}