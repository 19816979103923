import styled from "styled-components";
import { V3Paragraph } from "../Typography";
import { media, theme } from "../../theme/theme";

export const SimpleNavbarWrapper = styled.div<{ $withTitle?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: ${({ $withTitle }) => $withTitle ? "25px" : undefined};
    padding: ${({ $withTitle }) => !$withTitle ? "10px 18px" : undefined};
    
    ${media.custom(theme.breakpoints.phone)} {
        height: ${({ $withTitle }) => !$withTitle ? "70px" : undefined};
    }

    ${V3Paragraph} {
        line-height: ${({ $withTitle }) => $withTitle ? "20px" : undefined};
    }

    > div {
        &:first-child {
            margin-right: ${({ $withTitle }) => $withTitle ? "24px" : undefined};
        }

        &:nth-child(2) {
            margin-bottom: ${({ $withTitle }) => $withTitle ? "12px" : undefined};
            margin-left: ${({ $withTitle }) => $withTitle ? "auto" : undefined};
        }
    }
`;

export const SNLeftContent = styled.div`
    display: flex;
    align-items: flex-end;

    > div {
        &:first-child {
            margin-right: 12px;
        }
    }
`;

export const SimpleNavbarTitleTooltip = styled.div`
    display: flex;
    align-items: center;

    > div {
        &:first-child {
            margin-right: 8px;
        }

        &:nth-child(2) {
            position: relative;
            top: 2px;
        }
    }
`;