import { useFormik } from 'formik';
import * as Yup from "yup";
import { useState } from 'react';
import { V3Button } from '../Button';
import { V3Loader } from '../Loader';
import { RightAlignPanel } from '../ReusableStyledComponents/RightAlignPanel';
import { V3CaptionGrey35 } from '../ReusableStyledComponents/V3CaptionGrey35';
import { V3TextArea } from '../TextInput/V3TextInput';
import { V3ModalBase } from "../V3ModalBase"
import { VerticalSpacing } from '../VerticalSpacing';
import { MAX_MANUAL_ADDED_FOLLOWERS } from 'authory-api-types/dist/types';

const getEmailErrorMessages = (errors: any) => {
    if (!Array.isArray(errors)) return errors;

    let concatEmails = errors.join(", ");

    return errors.length === 1 ? `${concatEmails} is not a valid email address` : `${concatEmails} are not valid email addresses`;
}


export interface AddSubsFormik {
    emails: string
}

interface V3AddSubscribersProps {
    modelOpen: boolean,
    onCloseHandler: () => void,
    onAddSubsSubmit: (data: AddSubsFormik) => Promise<void>,
}

export const V3AddSubscribers = ({ modelOpen, onCloseHandler, onAddSubsSubmit }: V3AddSubscribersProps) => {

    const [submitting, setSubmitting] = useState(false);

    const formik = useFormik<AddSubsFormik>({
        initialValues: {
            emails: '',
        },
        onSubmit: async (data) => {
            try {
                setSubmitting(true);
                await onAddSubsSubmit(data);
                setSubmitting(false);
                onCloseHandler();
                formik.resetForm();
            } catch {
                setSubmitting(false);
            }
        },
        validationSchema: Yup.object().shape({
            emails: Yup.array()
                .transform(function (value, originalValue) {
                    if (this.isType(value) && value !== null) {
                        return value;
                    }
                    return originalValue ? originalValue.split(/[\s,]+/) : [];
                })
                .required("Please add at least one email")
                .of(Yup.string().email(({ value }) => value))
        })
    });

    return <V3ModalBase
        isOpen={modelOpen}
        onClose={onCloseHandler}
        title={`Add subscribers`}
        bottomPanel={
            <RightAlignPanel>
                <div style={{ minHeight: 30 }}>
                    {submitting ? <V3Loader /> : <V3Button
                        text="Add"
                        autoWidth
                        onClick={() => {
                            formik.submitForm();
                        }}
                    />}
                </div>
            </RightAlignPanel>
        }
    >
        <VerticalSpacing top={-15} bottom={24}>
            <V3CaptionGrey35>Enter up to a maximum of {MAX_MANUAL_ADDED_FOLLOWERS} email addresses separated by comma or new line.</V3CaptionGrey35>
        </VerticalSpacing>
        <form onSubmit={formik.handleSubmit}>
            <VerticalSpacing top={18}>
                <V3TextArea
                    name="emails"
                    placeholder="e.g. jane.doe@gmail.com, john.doe@gmail.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.emails}
                    error={formik.errors.hasOwnProperty("emails") && formik.touched.hasOwnProperty("emails") ? getEmailErrorMessages(formik?.errors?.emails) : undefined}
                />
            </VerticalSpacing>
        </form>
    </V3ModalBase >
}