import { format, startOfMonth, subDays, subMonths } from "date-fns";

export const formattedNewsletterTimeFilterOptions = [
    { value: "all_time", text: "All Time" },
    { value: format(subDays(new Date(), 7), "yyyy-MM-dd"), text: "Last 7 days" },
    { value: format(subDays(new Date(), 29), "yyyy-MM-dd"), text: "Last 30 days" },
    { value: format(startOfMonth(subMonths(new Date(), 3)), "yyyy-MM-dd"), text: "Last 3 months" }
];

export const formattedNewsletterSortOptions = [
    { value: "timestamp_DESC", text: "Date" },
    { value: "openedCount_DESC", text: "Opened" },
    { value: "clickedCount_DESC", text: "Clicked" }
];