import { V3ToggleSwitch } from "../ToggleSwitch/V3ToggleSwitch"
import { V3SubtitleMedium } from "../Typography"
import { LPToggleTittle, V3LightPanelItem, V3LightPanelWrapper } from "./V3LightPanel.styles"

interface V3LightPanelProps {
    panels: JSX.Element[],
    withToggle?: boolean,
    toggleValue?: boolean,
    toggleTitle?: string,
    onToggleChange?: () => void,
    hideMobileFrame?: boolean,
    minHeight?: string,
}

export const V3LightPanel = ({ panels, withToggle = false, toggleValue, toggleTitle, onToggleChange, hideMobileFrame, minHeight }: V3LightPanelProps) => {
    return <V3LightPanelWrapper $hideMobileFrame={hideMobileFrame} $minHeight={minHeight}>
        {
            (withToggle || toggleValue) && <LPToggleTittle>
                <div>
                    <V3SubtitleMedium>{toggleTitle}</V3SubtitleMedium>
                </div>
                <div>
                    <V3ToggleSwitch checked={toggleValue} onChange={onToggleChange} />
                </div>
            </LPToggleTittle>
        }
        {
            (!withToggle || toggleValue) && panels.map((panel, index) => <V3LightPanelItem key={index}>
                {panel}
            </V3LightPanelItem>)
        }
    </V3LightPanelWrapper>
}