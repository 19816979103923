import styled from "styled-components";
import { media, theme } from "../../theme/theme";

export const PCFIconWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        width: 33px;
    }

    &:focus,
    &:hover {
        svg {
            path {
                stroke: ${({ theme }) => theme ? theme.v3.colors.ink : null};
            }
        }
    }

    select {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        &:focus {
            & + div {
                svg {
                    path {
                        stroke: ${({ theme }) => theme ? theme.v3.colors.ink : null};
                    }
                }
            }
        }
    }
`;