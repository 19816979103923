import { useState } from 'react';
import { useRouter } from 'next/router';

type TrackPageViewProps = {
    onRouteChange: () => void
}

export const TrackPageView = ({ onRouteChange }: TrackPageViewProps) => {
    const router = useRouter();
    const [lastRoute, setLastRoute] = useState("");

    if (router === null) return null;

    const routeChanged = lastRoute !== router.asPath;

    if (routeChanged) {
        setLastRoute(router.asPath);
        onRouteChange();
    }

    return null;
};