import { APPLICATION_ROUTES } from "../../types/routes"
import { UnstyledLink } from "../ReusableStyledComponents/UnstyledLink"
import { V3EmptyState } from "./V3EmptyStateScreen"

export const V3BusinessParentEmpty = ({ }) => {
    return <V3EmptyState
        customPadding="90px 20px"
        title={"No accounts connected yet"}
        description={<>Use your <UnstyledLink href={APPLICATION_ROUTES.ACCOUNTS} textDecoration="underline" >invite link</UnstyledLink> to connect accounts</>}
        svgIcon={<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M68 15.6667H58V12.3333C58 11.4493 57.6488 10.6014 57.0237 9.97631C56.3986 9.35119 55.5507 9 54.6667 9H21.3333C20.4493 9 19.6014 9.35119 18.9763 9.97631C18.3512 10.6014 18 11.4493 18 12.3333V52.3333C18 54.9855 19.0536 57.529 20.9289 59.4044C22.8043 61.2798 25.3478 62.3333 28 62.3333H64.6667C64.6667 62.3333 68.1305 61.6309 69.3807 60.3807C70.6309 59.1305 71.3333 57.4348 71.3333 55.6667V19C71.3333 18.1159 70.9821 17.2681 70.357 16.643C69.7319 16.0179 68.884 15.6667 68 15.6667Z" fill="#DDDFE3" />
            <path d="M26.6665 26.6667H39.9998" stroke="#ABB0BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.6665 40H39.9998" stroke="#ABB0BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.6665 53.3333H39.9998" stroke="#ABB0BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M53.3335 19.9999H63.3335M66.6668 23.3333V59.9999C66.6668 61.768 65.9644 63.4637 64.7142 64.714C63.464 65.9642 61.7683 66.6666 60.0002 66.6666H23.3335C20.6813 66.6666 18.1378 65.613 16.2624 63.7376C14.3871 61.8623 13.3335 59.3187 13.3335 56.6666V16.6666M55.2861 64.714C54.0359 63.4637 53.3335 61.768 53.3335 59.9999V16.6666C53.3335 15.7825 52.9823 14.9347 52.3572 14.3096C51.7321 13.6844 50.8842 13.3333 50.0002 13.3333H16.6668" stroke="#ABB0BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6" />
        </svg>}
        maxWidth={600}>
    </V3EmptyState >
}