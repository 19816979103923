import { useEffect } from "react";
import { useAddElementSkeletonTracker } from "../../context/addElementSkeletonContext";
import { V3Button } from "../Button";
import { V3EmptyState } from "./V3EmptyStateScreen"
import { EmptyStateBtnPlacer } from "./V3EmptyStateScreen.styles";

const getEmptyCollectionTitle = (isCollection: boolean, isPrivate: boolean, collectionName: string, isDefaultCollection: boolean) => {
    if (isPrivate) {
        if (isDefaultCollection) {
            return "Get started by adding your work samples";
        } else {
            return <>Add work samples to collection <br /> "{collectionName}"</>;
        }
    }
    return `This ${isCollection ? "collection" : "portfolio"} is work in progress, please check back later`;
}

const getEmptyCollectionDescription = (isPrivate: boolean) => {
    if (isPrivate) return "Authory will automatically import your online content, or upload it straight from your device";
    return "If you are the owner, then you can add content items now.";
}


interface V3ProfileEmptyCollectionProps {
    isCollection?: boolean
    isPrivate?: boolean
    isDefaultCollection?: boolean
    collectionName: string,
}

export const V3ProfileEmptyCollection = ({ isCollection = false, isPrivate = false, isDefaultCollection = false, collectionName }: V3ProfileEmptyCollectionProps) => {

    const { setOnScreen, setAddContentOpen } = useAddElementSkeletonTracker();

    useEffect(() => {
        setOnScreen(true);
    }, []);

    return <V3EmptyState
        title={getEmptyCollectionTitle(isCollection, isPrivate, collectionName, isDefaultCollection)}
        description={getEmptyCollectionDescription(isPrivate)}
        svgIcon={<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="80" height="80" fill="#F4F4F6" />
            <path d="M27 26.3333V19.6667C27 17.8986 27.7024 16.2029 28.9526 14.9526C30.2029 13.7024 31.8986 13 33.6667 13H60.3333C62.1014 13 63.7971 13.7024 65.0474 14.9526C66.2976 16.2029 67 17.8986 67 19.6667V46.3333C67 48.1015 66.2976 49.7971 65.0474 51.0474C63.7971 52.2976 62.1014 53 60.3333 53H53.6667" fill="#DDDFE3" />
            <rect x="13" y="18.5" width="48" height="48" rx="8" fill="#F4F4F6" />
            <path d="M50.3333 22.5H23.6667C19.9848 22.5 17 25.4848 17 29.1667V55.8333C17 59.5152 19.9848 62.5 23.6667 62.5H50.3333C54.0152 62.5 57 59.5152 57 55.8333V29.1667C57 25.4848 54.0152 22.5 50.3333 22.5Z" stroke="#ABB0BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="8 8" />
            <path d="M30 49L37 37L44 49" stroke="#ABB0BA" strokeWidth="2" />
        </svg>}
        maxWidth={600}>
        {
            isPrivate && <EmptyStateBtnPlacer>
                <span data-intercom-target={isDefaultCollection ? "DefaultCollectionAddCTA" : undefined}>
                    <V3Button
                        text={`Add work samples${isDefaultCollection ? "" : " to this collection"}`}
                        onClick={() => setAddContentOpen(true)}
                        autoWidth
                        minWidth={isDefaultCollection ? 160 : 280}
                    />
                </span>
            </EmptyStateBtnPlacer>
        }
    </V3EmptyState >
}