interface V3IconGrabProps extends React.SVGProps<SVGSVGElement> {
    size?: number
}

export const V3IconGrab = ({ size = 16 }: V3IconGrabProps) => <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.66667 8.99935C6.03486 8.99935 6.33333 8.70087 6.33333 8.33268C6.33333 7.96449 6.03486 7.66602 5.66667 7.66602C5.29848 7.66602 5 7.96449 5 8.33268C5 8.70087 5.29848 8.99935 5.66667 8.99935Z" fill="#ABB0BA" stroke="#ABB0BA" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.33366 8.99935C9.70185 8.99935 10.0003 8.70087 10.0003 8.33268C10.0003 7.96449 9.70185 7.66602 9.33366 7.66602C8.96547 7.66602 8.66699 7.96449 8.66699 8.33268C8.66699 8.70087 8.96547 8.99935 9.33366 8.99935Z" fill="#ABB0BA" stroke="#ABB0BA" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.66667 13.6663C6.03486 13.6663 6.33333 13.3679 6.33333 12.9997C6.33333 12.6315 6.03486 12.333 5.66667 12.333C5.29848 12.333 5 12.6315 5 12.9997C5 13.3679 5.29848 13.6663 5.66667 13.6663Z" fill="#ABB0BA" stroke="#ABB0BA" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.33366 13.6663C9.70185 13.6663 10.0003 13.3679 10.0003 12.9997C10.0003 12.6315 9.70185 12.333 9.33366 12.333C8.96547 12.333 8.66699 12.6315 8.66699 12.9997C8.66699 13.3679 8.96547 13.6663 9.33366 13.6663Z" fill="#ABB0BA" stroke="#ABB0BA" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.66667 4.33333C6.03486 4.33333 6.33333 4.03486 6.33333 3.66667C6.33333 3.29848 6.03486 3 5.66667 3C5.29848 3 5 3.29848 5 3.66667C5 4.03486 5.29848 4.33333 5.66667 4.33333Z" fill="#ABB0BA" stroke="#ABB0BA" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.33366 4.33333C9.70185 4.33333 10.0003 4.03486 10.0003 3.66667C10.0003 3.29848 9.70185 3 9.33366 3C8.96547 3 8.66699 3.29848 8.66699 3.66667C8.66699 4.03486 8.96547 4.33333 9.33366 4.33333Z" fill="#ABB0BA" stroke="#ABB0BA" strokeLinecap="round" strokeLinejoin="round" />
</svg>