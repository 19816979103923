import { CancelAccount, CommitFreePlan, StartCancellationFlow } from "authory-api-types/dist/endpoints";
import { AuthenticatedUserResponse, CancellationFlowResponse, CommitFreePlanBodyParams, UserBillingInvoiceDownloadResponse, UserBillingInvoicesPaginatedResponse, UserBillingResponse } from "authory-api-types/dist/types";
import { UserBilling, UserBillingInvoiceDownload, UserBillingInvoices } from "authory-api-types/dist/endpoints";
import axios from "axios";
import { API_URL } from ".";
import { CommonArgs } from "./collections";
import { getHeaders } from "./get-headers";

export const getUserBillingData = async (token: string, userSlug: string) => {
    const { data } = await axios.get<UserBillingResponse>(`${API_URL}${UserBilling.replace(":userId?", userSlug)}`, getHeaders(token));
    return data;
}


export const getUserInvoicesData = async (token: string, userSlug: string) => {
    const { data } = await axios.get<UserBillingInvoicesPaginatedResponse>(`${API_URL}${UserBillingInvoices.replace(":userId?", userSlug)}`, getHeaders(token));
    return data;
}


export const getInvoicesLink = async (token: string, userSlug: string, invoiceId: string) => {
    const { data } = await axios.get<UserBillingInvoiceDownloadResponse>(`${API_URL}${UserBillingInvoiceDownload.replace(":userId?", userSlug).replace(":invoiceId", invoiceId)}`, getHeaders(token));
    return data;
}

export const getCancelSubLink = async ({ token, userSlug }: CommonArgs) => {
    const { data } = await axios.post<CancellationFlowResponse>(`${API_URL}${StartCancellationFlow.replace(":userId?", userSlug)}`, {}, getHeaders(token));
    return data;
}

export const getCancelImmediatelySubLink = async ({ token, userSlug }: CommonArgs) => {
    const { data } = await axios.post(`${API_URL}${CancelAccount.replace(":userId?", userSlug)}`, {}, getHeaders(token));
    return data;
}

interface CommitFreePlanArgs extends CommonArgs {
    params: CommitFreePlanBodyParams
}

export const commitFreePlan = async ({ token, userSlug, params }: CommitFreePlanArgs) => {
    const { data } = await axios.post<AuthenticatedUserResponse>(`${API_URL}${CommitFreePlan.replace(":userId?", userSlug)}`, params, getHeaders(token));
    return data;
}