import styled from "styled-components";
import Textarea from "react-autosize-textarea";
import { V3Body, V3BodyCSS } from "../Typography";
import { InputStyleProps, InputMixin } from "../SharedInputComponent";
import { V3RightIconPositioner } from "../SharedInputComponent/SharedInputComponent.styles";

interface V3IptProps extends InputStyleProps {
    $hasCharacterLimit?: boolean,
}

export const V3Ipt = styled(V3Body) <V3IptProps>`
    ${InputMixin};
    padding-right: ${({ $hasCharacterLimit }) => {
        if ($hasCharacterLimit) return "75px";
        return undefined;
    }};
`;

export const V3TA = styled(Textarea) <V3IptProps>`
    ${V3BodyCSS};
    ${InputMixin};
    resize: none;
    display: block;
    min-height: 100px;
    padding-bottom: ${({ $hasCharacterLimit }) => {
        if ($hasCharacterLimit) return "25px";
        return undefined;
    }};

    + ${V3RightIconPositioner} {
        right: ${({ $hasCharacterLimit }) => $hasCharacterLimit ? "initial" : undefined};
        bottom: ${({ $hasCharacterLimit }) => $hasCharacterLimit ? "5px" : undefined};
        top:  ${({ $hasCharacterLimit }) => $hasCharacterLimit ? "initial" : undefined};
        left:  ${({ $hasCharacterLimit }) => $hasCharacterLimit ? "18px" : undefined};
    }
`;