import { ContentType } from "authory-api-types/dist/enums"
import { V3IconArticle, V3IconPodcast, V3IconVideo, V3IconPost, V3IconImage, V3IconPDF } from "../Icons";

export const getThumbnaiPlaceholderIcon = (type: ContentType) => {
    switch (type) {
        case ContentType.Article:
            return <V3IconArticle />;
        case ContentType.PodcastItem:
            return <V3IconPodcast />;
        case ContentType.Video:
        case ContentType.VideoPost:
            return <V3IconVideo />;
        case ContentType.TextPost:
        case ContentType.ReplyPost:
            return <V3IconPost />;
        case ContentType.ImagePost:
            return <V3IconImage />;
        case ContentType.PdfFile:
            return <V3IconPDF />
        default:
            return <></>;
    }
}