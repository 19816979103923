import { APPLICATION_ROUTES } from "../types/routes";

export const MOREMARKETINGPAGES = [
    {
        primaryText: "About",
        href: APPLICATION_ROUTES.ABOUT,
        iconPath: "/authory_static_files/images/menu-icons/authory.svg",
    },
    {
        primaryText: "Tutorials",
        href: APPLICATION_ROUTES.TUTORIALS,
        iconPath: "/authory_static_files/images/menu-icons/info.svg",
    },
    {
        primaryText: "Blog",
        href: APPLICATION_ROUTES.BLOG,
        iconPath: "/authory_static_files/images/menu-icons/blog.svg",
    },
    {
        primaryText: "Examples",
        href: APPLICATION_ROUTES.EXAMPLES,
        iconPath: "/authory_static_files/images/menu-icons/people.svg",
    },
    // {
    //     primaryText: "Customer Reviews",
    //     href: APPLICATION_ROUTES.REVIEWS,
    //     iconPath: "/authory_static_files/images/menu-icons/heart.svg",
    // },
    {
        primaryText: "Ownership",
        href: APPLICATION_ROUTES.OWNERSHIP,
        iconPath: "/authory_static_files/images/menu-icons/curate.svg",
    },
    {
        primaryText: "Partner Program",
        href: APPLICATION_ROUTES.PARTNERS,
        iconPath: "/authory_static_files/images/menu-icons/people.svg",
    },
    {
        primaryText: "Privacy",
        href: APPLICATION_ROUTES.PRIVACY,
        iconPath: "/authory_static_files/images/menu-icons/curate.svg",
    },
    {
        primaryText: "Terms",
        href: APPLICATION_ROUTES.TERMS,
        iconPath: "/authory_static_files/images/menu-icons/info.svg",
    },
    {
        primaryText: "DMCA",
        href: APPLICATION_ROUTES.DMCA,
        iconPath: "/authory_static_files/images/menu-icons/locket.svg",
    },
]