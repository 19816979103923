import styled from "styled-components";
import { media } from "../../theme/theme";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { V3CaptionGrey48 } from "../ReusableStyledComponents/V3CaptionGrey48";
import { V3HeadlineH3, V3Paragraph } from "../Typography";

export const PPToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const PPToggleComp = styled.div`
    display: flex;
    align-items: center;

    > div {
        &:not(:last-child) {
            margin-right: 12px;
        }
    }
`;

export const V3PricePeriodCaption = styled(V3CaptionGrey48) <{ $active?: boolean }>`
    transition: color .4s;
    color: ${({ $active, theme }) => $active ? theme.v3.colors.blue : theme.v3.colors.grey48};
`;

export const PlanTooltipPos = styled.span`
    position: relative;
    top: 3px;
    left: 6px;
`;

export const PPCard = styled.div<{ $active?: boolean, $billingAreaVariant?: boolean }>`
    width: 100%;
    min-width: 220px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 18px 10px;
    background: ${({ theme }) => theme.v3.colors.grey96};
    border: 1px solid ${({ $active, theme }) => $active ? theme.v3.colors.blue : theme.v3.colors.grey88};
    box-shadow: ${({ $active }) => $active ? "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px rgba(46, 119, 255, 0.2);" : undefined};
    border-radius: 12px;

    ${media.custom(500)} {
        justify-content: flex-start;
    }

    &:hover {
        border: 1px solid ${({ theme, $billingAreaVariant }) => !$billingAreaVariant ? theme.v3.colors.blue : "none"};
        cursor: ${({ $billingAreaVariant }) => !$billingAreaVariant ? "pointer" : undefined};
        box-shadow: ${({ $billingAreaVariant }) => !$billingAreaVariant ? "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px rgba(46, 119, 255, 0.2)" : undefined}; 
    }

    ${V3Paragraph} {
        font-size: 22px;
        line-height: 30px;
    }
`;

export const PPCardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${media.custom(500)} {
        flex-direction: row;
    }

    > div {
        margin-bottom: 20px;

        ${media.custom(500)} {
            width: 100%;
            max-width: calc(50% - 20px);
            margin-bottom: 0;
        }
    }
`;

export const PPCardDivider = styled.hr`
    background: ${({ theme }) => theme ? theme.v3.colors.grey88 : null};
    height: 1px;
    border: 0;
    margin: 0;
    width: 100px;
    margin: 0 auto;
`;

export const PPCardPrice = styled(V3HeadlineH3)`
    font-size: 34px;
    line-height: 30px;
    
    span {
        color: ${({ theme }) => theme ? theme.v3.colors.grey70 : null};
        font-size: 18px;
        line-height: 22px;
    }
`;

export const PPCardPricePerk = styled.li`
    margin-bottom: 12px;
    position: relative;

    ${V3CaptionGrey35} {
        line-height: 21px;
    }
`;

export const PPerkWrapper = styled.ul`
    padding-left: 12px;
    width: 100%;
    list-style: none;
`;

export const PPPriceSaveText = styled(V3CaptionGrey48)`
    color: #ABB0BA;
    font-size: 20px;
    line-height: 22px;
    text-decoration: line-through;
`;