import { useContext, useState } from "react"
import { V3ModalPayment } from "../V3ModalPayment";
import { getAuthenticatedUserClient } from "../../utils/getAuthenticatedUser";
import { useRouter } from "next/router";
import { CookieContext } from "../../context/cookieContext";
import { V3ModalChoosePlan } from "../V3ModalChoosePlan";
import { ManualSelectContentTrialContainer, ManualSelectFormik } from "../V3ModalUniversalAddContent/ManualSelectContent";
import { useFormik } from "formik";
import { getContentInfiniteQuerie } from "../../utils/query-utils/contentInfiniteQuerie";
import { ArticleFilterQueryParams, FREE_PLAN_ITEM_LIMIT, SetPaymentBodyParams } from "authory-api-types/dist/types";
import { V3ModalBase } from "../V3ModalBase";
import { SubscriptionGateway, SubscriptionPlan } from "authory-api-types/dist/enums";
import { mapFreePlanToPaidPlan } from "../../pagelayouts/settings/billing/payment.utils";
import { V3PaymentFormik } from "../../hooks/useStripePayment";
import { getSetPaymentMutation } from "../../utils/mutation-utils/payment/setPaymentMutation";
import { useQueryClient } from "@tanstack/react-query";
import { getPaypalConfirmLinkMutation } from "../../utils/mutation-utils/payment/getPaypalConfirmLinkMutation";
import { setCookie } from "nookies";
import { CookieKeys } from "../../types/cookie-keys";
import { getCommitFreePlan } from "../../utils/mutation-utils/subscription/getCommitFreePlan";
import { FreePlanLimitExplanation } from "../FreePlanLimitExplanation/FreePlanLimitExplanation";

type PaymentPlanFormik = {
    subscriptionPlan: SubscriptionPlan,
}

enum EndOfTrialStages {
    Payment = "Payment",
    ChoosePlan = "ChoosePlan",
    ManualSelect = "ManualSelect",
    Info = "Info",
}

interface ModalEndOfTrialProps { }

export const ModalEndOfTrial = ({ }: ModalEndOfTrialProps) => {
    const router = useRouter();
    const queryClient = useQueryClient();
    const token = useContext(CookieContext);
    const { authenticatedUser } = getAuthenticatedUserClient(token, router, true);
    const userData = authenticatedUser.data;

    const isElegibleForEndOfTrialFlow = userData?.isUncomfirmedFreeAccount;

    const manualSelectformik = useFormik<ManualSelectFormik>({
        initialValues: {
            text: undefined,
            source: undefined,
            type: undefined,
        },
        enableReinitialize: true,
        onSubmit: () => { }
    });

    const items = getContentInfiniteQuerie(token, userData?.slug, manualSelectformik.values as ArticleFilterQueryParams, isElegibleForEndOfTrialFlow);

    const [stage, setStage] = useState(EndOfTrialStages.Payment);

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const setPaymentMutation = getSetPaymentMutation(queryClient, token!);

    const getPayPalConfirmLinkMutation = getPaypalConfirmLinkMutation();

    const onCommitFreePlan = getCommitFreePlan(
        queryClient,
        authenticatedUser.data?.slug!,
        token,
        (items.data?.pages[0].articles || []).map(item => item.canonicalSlug!)
    );

    const onPaymentUpdateHandler = async (data: V3PaymentFormik) => {
        if (!token) return null;

        // Stripe, Apple Pay and Google Pay flow
        if ((
            data.subscriptionGateway === SubscriptionGateway.Stripe
            || data.subscriptionGateway === SubscriptionGateway.ApplePay
            || data.subscriptionGateway === SubscriptionGateway.GooglePay
        ) && data.stripeCardToken) {
            const params = new SetPaymentBodyParams();

            params.gatewayToken = data.stripeCardToken;
            params.subscriptionGateway = data.subscriptionGateway;
            params.subscriptionPlan = data.subscriptionPlan;

            return setPaymentMutation.mutateAsync({
                token,
                userSlug: authenticatedUser.data?.slug!,
                data: params,
            });

        } else {
            //Set payment plan cookie
            setCookie(null, CookieKeys.subscription_plan, data.subscriptionPlan, {
                path: "/",
            });

            // Paypal flow
            const { url } = await getPayPalConfirmLinkMutation.mutateAsync({ token, userSlug: authenticatedUser.data?.slug! });

            // This explicitely needs to be a hard redirect,
            // we cannot use Next Router. Paypal is a different domain.
            window.location = url as (string & Location);
        }
    }

    const paymentFormik = useFormik<PaymentPlanFormik>({
        initialValues: {
            subscriptionPlan: mapFreePlanToPaidPlan(userData?.subscriptionPlanKey)!,
        },
        enableReinitialize: true,
        onSubmit: () => { }
    });

    const isAboveFreePlanLimit = userData?.articleCount && userData?.articleCount >= FREE_PLAN_ITEM_LIMIT;

    // not elegible, do nothing
    if (!authenticatedUser.isSuccess || !isElegibleForEndOfTrialFlow) return <></>;

    return <>
        {
            stage === EndOfTrialStages.Payment && <V3ModalPayment
                modalOpen
                onCloseHandler={() => { }}
                user={userData!}
                onPaymentUpdateHandler={async (data) => {
                    const dataWithPlan: V3PaymentFormik = {
                        ...data,
                        subscriptionPlan: paymentFormik.values.subscriptionPlan
                    }

                    try {
                        onPaymentUpdateHandler(dataWithPlan);
                    } catch { }
                }}
                trackEvent={() => { }}
                isEndOfTrialFlow
                onChangePlanHandler={() => {
                    setStage(EndOfTrialStages.ChoosePlan);
                }}
                onFreePlanSelection={() => {
                    setStage(EndOfTrialStages.Info);
                }}
                hideCloseCTA
                planSub={paymentFormik.values.subscriptionPlan}
            />
        }
        {
            stage === EndOfTrialStages.ChoosePlan && <V3ModalChoosePlan
                modalOpen
                onCloseHandler={() => { }}
                onChoosePlan={async (plan) => {
                    paymentFormik.setFieldValue("subscriptionPlan", plan);
                    setStage(EndOfTrialStages.Payment);
                }}
                activePlan={paymentFormik.values.subscriptionPlan}
                onBackHandler={() => {
                    setStage(EndOfTrialStages.Payment);
                }}
                hideCloseCTA
            />
        }
        {
            stage === EndOfTrialStages.ManualSelect && userData && <V3ModalBase
                isOpen
                onClose={() => { }}
                title={`Choose items: ${selectedItems.length}/${FREE_PLAN_ITEM_LIMIT} selected`}
                width="650px"
                hideCloseCTA
            >
                <ManualSelectContentTrialContainer
                    onBackHandler={() => { setStage(EndOfTrialStages.Payment); }}
                    onCommitFreePlan={onCommitFreePlan}
                    formik={manualSelectformik}
                    items={items.data?.pages || []}
                    itemsLoading={items.isFetching}
                    itemsLoadmore={items.fetchNextPage}
                    itemsCanFetchmore={items.hasNextPage || false}
                    user={authenticatedUser.data}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                />
            </V3ModalBase>
        }
        {
            stage === EndOfTrialStages.Info && <V3ModalBase
                isOpen
                onClose={() => { }}
                title="Continue with Free Plan"
                width="650px"
                hideCloseCTA
            >
                <FreePlanLimitExplanation
                    isAboveFreePlanLimit={!!isAboveFreePlanLimit}
                    articleCount={userData?.articleCount.toLocaleString()}
                    onGoBack={() => {
                        setStage(EndOfTrialStages.Payment);
                    }}
                    onAbovePlanConfirm={() => {
                        setStage(EndOfTrialStages.ManualSelect);
                    }}
                    onBellowPlanConfirm={onCommitFreePlan}
                />
            </ V3ModalBase>
        }
    </>
}