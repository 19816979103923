import { Placement } from "@floating-ui/react";
import { V3IconHelpCircle } from "../Icons";
import { V3BodyGrey48 } from "../ReusableStyledComponents/V3BodyGrey48";
import { V3Tooltip } from "../Tooltip";
import { V3Paragraph } from "../Typography"
import { SimpleNavbarTitleTooltip, SimpleNavbarWrapper, SNLeftContent } from "./V3SimpleNavbar.styles"
import { PageTitle } from "../ReusableStyledComponents/PageTitle";

interface V3SimpleNavbarProps {
    title: string,
    subtitle?: JSX.Element | string,
    withTitle?: boolean,
    rightSideContent?: JSX.Element,
    tooltipContent?: JSX.Element | string,
    customTooltipWith?: number,
    placement?: Placement
}

export const V3SimpleNavbar = ({ title, subtitle, withTitle = false, rightSideContent, tooltipContent, customTooltipWith = 315, placement }: V3SimpleNavbarProps) => {
    const ComponentToUse = !withTitle ? PageTitle : V3Paragraph;

    return <SimpleNavbarWrapper $withTitle={withTitle}>
        <div>
            <SNLeftContent>
                <SimpleNavbarTitleTooltip>
                    <div>
                        <ComponentToUse>{title}</ComponentToUse>
                    </div>
                    {
                        !!tooltipContent && <div>
                            <V3Tooltip
                                tooltipMaxWidth={customTooltipWith}
                                referenceContent={<V3IconHelpCircle />}
                                tooltipContent={tooltipContent}
                                placement={placement}
                            />
                        </div>
                    }
                </SimpleNavbarTitleTooltip>
                {!!subtitle && <div>
                    <V3BodyGrey48>{subtitle}</V3BodyGrey48>
                </div>}
            </SNLeftContent>
        </div>
        {
            !!rightSideContent && <div>
                {rightSideContent}
            </div>
        }
    </SimpleNavbarWrapper>
}