import styled from "styled-components";
import { V3Caption, V3HeadlineH5 } from "../Typography";

export const InputOptionH5 = styled(V3HeadlineH5)`
    line-height: 20px;
`;

const RADIO_SIZE = 16;
const RADIO_CHECKED_SIZE = 14;

export const V3RadioBtnLb = styled.label<{ $hasIcon: boolean }>`
    display: inline-block;
    min-height: ${RADIO_SIZE}px;
    position: relative;
    padding: 0 0 0 ${RADIO_SIZE + 8}px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before, &:after {
        position: absolute;            
        content: '';  
        border-radius: 50%;
        transition: all .3s ease;
        transition-property: transform;
    }

    &:hover{
        &:before {
            border: 1px solid ${({ theme }) => theme ? theme.v3.colors.blue : "black"};
            background: ${({ theme }) => theme ? theme.v3.colors.lightBlue : null};
        }
    }

    &:before {
        left: 0;
        top: 2px;
        width: ${RADIO_SIZE}px;
        height: ${RADIO_SIZE}px;
        border: 1px solid ${({ theme }) => theme ? theme.v3.colors.grey88 : "black"};
    }

    &:after {
        top: ${(RADIO_SIZE / 2 - RADIO_CHECKED_SIZE / 2) + 2}px;
        left: ${RADIO_SIZE / 2 - RADIO_CHECKED_SIZE / 2}px;
        width:${RADIO_CHECKED_SIZE}px;
        height:${RADIO_CHECKED_SIZE}px;
        transform: scale(0);
        background: ${({ theme }) => theme ? theme.colors.dodgerBlue : "black"};
    }
`;

export const V3RadioBtn = styled.input`
    position: absolute;
    opacity: 0;
    pointer-events: none;

    + ${V3RadioBtnLb} {
        border: 1px solid rgba(0,0,0,0);
    }

    &:disabled {
        & + ${V3RadioBtnLb} {
            cursor: default;
            span {
                color: ${({ theme }) => theme ? theme.v3.colors.grey48 : "black"};
            }

            &:before {
                cursor: default;
                border: 1px solid ${({ theme }) => theme ? theme.v3.colors.grey88 : "black"};
                background: ${({ theme }) => theme ? theme.v3.colors.grey96 : null};
            }
        }
    }

    &:checked:not(:disabled) + ${V3RadioBtnLb}:before {
        border: 2px solid ${({ theme }) => theme ? theme.v3.colors.darkBlue : "black"};
        background: white;
    }
    
    &:checked + ${V3RadioBtnLb} {
        &::after {
            transform: scale(0.6);
        }
    }

    &:checked:disabled + ${V3RadioBtnLb}:after {
        background: ${({ theme }) => theme ? theme.v3.colors.grey88 : null};
    }
`;


const CHECK_SIZE = 16;
const CHECK_CHECKED_SIZE = 14;

export const V3CbLb = styled.label<{ $hasIcon: boolean }>`
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100%);
    text-overflow: ellipsis;
    display: inline-block;
    min-height: ${CHECK_SIZE + 2}px;
    position: relative;
    padding: 0 0 0 ${({ $hasIcon }) => $hasIcon ? CHECK_SIZE + 29 : CHECK_SIZE + 8}px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    z-index: 0;

    &:before, &:after {
        position: absolute;            
        content: '';  
        transition: all .3s ease;
        transition-property: transform;
    }

    &:hover{
        &:before {
            border: 1px solid ${({ theme }) => theme ? theme.v3.colors.blue : "black"};
            background: ${({ theme }) => theme ? theme.v3.colors.lightBlue : null};
        }
    }

    &:before {
        left: 0;
        top: 2px;
        width: ${CHECK_SIZE}px;
        height: ${CHECK_SIZE}px;
        border-radius: 2px;
        border: 1px solid ${({ theme }) => theme ? theme.v3.colors.grey88 : "black"};
        background: white;
    }

    &:after {
        top: ${(CHECK_SIZE / 2 - CHECK_CHECKED_SIZE / 2) + 2}px;
        left: ${CHECK_SIZE / 2 - CHECK_CHECKED_SIZE / 2}px;
        width:${CHECK_CHECKED_SIZE}px;
        height:${CHECK_CHECKED_SIZE}px;
        transform: scale(0);
        background: url("/authory_static_files/images/white_check.svg") 50% no-repeat;
        background-color: ${({ theme }) => theme ? theme.v3.colors.blue : "black"};
        background-size: 10px;
    }
`;

export const V3CbInp = styled.input`
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:focus + ${V3CbLb}:before {
        border: 1px solid ${({ theme }) => theme ? theme.colors.dodgerBlue : "black"};
    }

    &:disabled + ${V3CbLb} {
        &:before {
            cursor: default;
            border: 1px solid ${({ theme }) => theme ? theme.v3.colors.grey88 : "black"};
            background: ${({ theme }) => theme ? theme.v3.colors.grey96 : null};
        }

        &:after {
            background: url("/authory_static_files/images/grey_check.svg") 50% no-repeat;
        }

        span {
            color: ${({ theme }) => theme ? theme.v3.colors.grey48 : "black"};
        }
    }
    
    &:disabled + ${V3CbLb} {
        cursor: default;
        color: ${({ theme }) => theme ? theme.colors.blackPearl4 : "black"};
    }

    &:checked + ${V3CbLb}:before {
        border-color:  "black";
    }

    &:checked + ${V3CbLb}:after {
        transform: scale(1);
    }

    &:checked:not(:disabled) + ${V3CbLb}:before {
        border: 2px solid ${({ theme }) => theme ? theme.v3.colors.darkBlue : "black"};
        background: white;
    }
`;


export const AditionalInformationOffset = styled.div<{ isDisabled?: boolean }>`
    margin-left: ${RADIO_SIZE + 8}px;
    margin-top: 4px;

    ${V3Caption} {
        color: ${({ theme, isDisabled }) => isDisabled ? theme.v3.colors.grey48 : theme.v3.colors.grey35};
    }
`;

export const OIconPos = styled.div`
    position: absolute;
    left: 23px;
    top: 2px;
    z-index: -1;
`;

export const InptWrapper = styled.div`
    position: relative;
`;