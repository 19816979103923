import styled, { css } from "styled-components";
import { CTASize, CTAVariant } from "./MarketingCTA";

type CTAMixinProps = { size: CTASize, variant: CTAVariant, minWidth?: number };

const CtaMixin = css<CTAMixinProps>`
    min-width: ${({ minWidth }) => minWidth ? `${minWidth}px` : null};
    padding: ${({ size }) => {
        switch (size) {
            case CTASize.L:
                return "20px 50px";
            case CTASize.M:
                return "10px 40px";
            case CTASize.S:
                return "6px 40px";
        }
    }}; 
    font-size: ${({ size }) => {
        switch (size) {
            case CTASize.L:
                return "18px";
            case CTASize.M:
                return "16px";
            case CTASize.S:
                return "14px";
        }
    }};
    font-family: ${({ theme }) => theme ? `${theme.typography.type.assistant}` : null};
    font-weight: ${({ variant }) => variant === CTAVariant.Primary ? `700` : `400`};
    background-color: ${({ theme, variant }) => variant === CTAVariant.Primary
        ? theme.marketing.colors.dodgerBlue100
        : "white"
    };
    border-radius: 100px;
    border: ${({ theme, variant }) => variant === CTAVariant.Primary ? `1px solid transparent` : `1px solid ${theme.marketing.colors.dodgerBlue100}`};
    color: ${({ theme, variant }) => variant === CTAVariant.Primary ? "white" : theme.marketing.colors.dodgerBlue100};

    &:hover {
        cursor: pointer;
        background-color: ${({ theme, variant }) => variant === CTAVariant.Primary ? theme.marketing.colors.chathamsBlue : null};
        border: ${({ theme, variant }) => variant === CTAVariant.Primary ? null : `1px solid ${theme.marketing.colors.chathamsBlue}`};
        color: ${({ theme, variant }) => variant === CTAVariant.Primary ? null : theme.marketing.colors.chathamsBlue};
    }

    &:disabled {
        cursor: initial;
        background-color: ${({ theme }) => theme ? theme.marketing.colors.blackPearl60 : null};
    }
`

export const MCTA = styled.button<CTAMixinProps>`
    ${CtaMixin}  
`;