export enum APPLICATION_ROUTES {
    // PRIVATE app routes
    CONTENT = "/app/content",
    PORTFOLIO_APP = "/app/portfolio",
    COLLECTIONS = "/app/collections",
    COLLECTION = "/app/collection",
    COLLECTION_SORT = "/app/collection/[collectionId]/sort",
    NEWSLETTER = "/app/newsletter",
    NEWSLETTER_SUBSCRIBERS = "/app/newsletter/subscribers",
    NEWSLETTER_REPORT = "/app/newsletter/report",
    NEWSLETTER_SETTINGS = "/app/newsletter/settings",
    ANALYTICS = "/app/analytics",
    ANALYTICS_WEB = "/app/analytics/web",
    SETTINGS_ACCOUNT = "/app/settings/account",
    SETTINGS_BILLING = "/app/settings/billing",
    SETTINGS_REFERRAL = "/app/settings/referral",
    SETTINGS_SOURCES = "/app/settings/sources",
    SETTINGS_CUSTOM_DOMAINS = "/app/settings/custom-domain",
    SETTINGS_WEBHOOKS = "/app/settings/webhooks",
    SETTINGS_NOTIFICATIONS = "/app/settings/notifications",
    SETTINGS_CERTIFICATES = "/app/settings/certificates",
    WRITE = "/app/write",
    ACCOUNTS = "/app/accounts",
    UPDATE_PAYMENT = "/payment/update",

    // PUBLIC PAGES
    AUTHOR_PAGE = "/[author]",
    COLLECTION_PAGE = "/[author]/collection/[collectionId]",
    ABOUT_PAGE = "/[author]/p/[aboutPageSlug]",

    // UNAVAILABLE CONTENT
    UNAVAILABLE_CONTENT = "/content-unavailable",
    UNAVAILABLE_PROFILE = "/profile-unavailable",
    UNAVAILABLE_COLLECTION = "/collection-unavailable",
    UNAVAILABLE_CERTIFICATE = "/certificate-unavailable",

    // Signup / Auth routes
    SIGNUP = "/signup",
    SIGNUP_CHOOSE_PLAN = "/signup/choose-plan", // legacy - used for reactivations only now
    SIGNUP_DEACTIVATED = "/signup/deactivated",
    SIGNUP_ACCOUNT = "/signup/account",
    SIGNUP_COMPLETE_NAME = "/signup/complete-name",
    SIGNUP_ANIMATION = "/signup/animation",

    LOGIN = "/login",
    LOGIN_FORGOTTEN_PASSWORD = "/login/forgotten-password",
    OAUTH = "/login/oauth",

    // Public static pages routes
    HOMEPAGE = "/",
    HOMEPAGE_VARIANT = "/home",

    //Features
    PORTFOLIO = "/portfolio",
    ARCHIVE = "/backup",
    ANALYTICS_MARKETING = "/analytics",
    TRACKING = "/tracking",
    COLLECTIONS_MARKETING = "/collections",
    NEWSLETTER_MARKETING = "/newsletter",

    // Use cases
    USE_CASE_WRITERS = "/writers",
    USE_CASE_THOUGHT_LEADERS = "/thought-leaders",
    USE_CASE_CONTENT_MARKETERS = "/content-marketers",
    USE_CASE_JOURNALISTS = "/journalists",

    // Others
    IMPRINT = "/imprint",
    DEMO = "/demo",
    PARTNERS = "/partners",
    ABOUT = "/about",
    REVIEWS = "/reviews",
    OWNERSHIP = "/ownership",
    PRIVACY = "/privacy",
    TERMS = "/tos",
    DMCA = "https://authory.com/help/en/articles/4614822-copyright-policy",
    PRICING = "/pricing",
    BLOG = "/blog",
    BLOG_AUTHOR = "/blog/author",
    BLOG_TAG = "/blog/tag",
    BLOG_POST = "/blog/[slug]",
    DIRECTORY = "/directory",
    PORTFOLIO_CREATOR = "/portfolio-creator",
    TUTORIALS = "/tutorials",

    // Programatic SEO
    EXAMPLES = "/examples",
    EXAMPLES_PROFESSION = "/examples/profession",
    EXAMPLES_CATEGORIES = "/examples/categories",
    EXAMPLES_PROFESSION_CATEGORIES = "/examples/professional-categories",
    EXAMPLES_SOURCE = "/examples/sources",
    EXAMPLES_JOB = "/examples/[job]",
}