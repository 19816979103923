import { V3Button } from "../Button";
import { V3EmptyState } from "./V3EmptyStateScreen";
import { EmptyStateBtnPlacer } from "./V3EmptyStateScreen.styles";

interface V3NoCollectionsProps {
    onCreateCollectionClick: () => void
}

export const V3NoCollections = ({ onCreateCollectionClick }: V3NoCollectionsProps) => <V3EmptyState
    title="It looks like you haven't created any collection yet"
    description="Collections are a great way to curate and display your content, for you personally or in order to share it with others"
    svgIcon={<svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.6665 27.1668V20.5002C26.6665 18.7321 27.3689 17.0364 28.6191 15.7861C29.8694 14.5359 31.5651 13.8335 33.3332 13.8335H59.9998C61.7679 13.8335 63.4636 14.5359 64.7139 15.7861C65.9641 17.0364 66.6665 18.7321 66.6665 20.5002V47.1668C66.6665 48.9349 65.9641 50.6306 64.7139 51.8809C63.4636 53.1311 61.7679 53.8335 59.9998 53.8335H53.3332" fill="#DDDFE3" />
        <path d="M46.6673 27.1665H20.0007C16.3188 27.1665 13.334 30.1513 13.334 33.8332V60.4998C13.334 64.1817 16.3188 67.1665 20.0007 67.1665H46.6673C50.3492 67.1665 53.334 64.1817 53.334 60.4998V33.8332C53.334 30.1513 50.3492 27.1665 46.6673 27.1665Z" fill="#F4F4F6" stroke="#ABB0BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="8 8" />
    </svg>}
    maxWidth={600}
>
    <EmptyStateBtnPlacer>
        <V3Button
            text="Create a new collection"
            onClick={onCreateCollectionClick}
            autoWidth
            minWidth={228}
        />
    </EmptyStateBtnPlacer>
</V3EmptyState>