import styled from "styled-components";

export const RightAlignPanel = styled.div`
    display: flex;
    justify-content: flex-end;

    > div {
        &:nth-child(2) {
            margin-left: 12px;
        }
    }
`;