import { V3BodyGrey48 } from "../ReusableStyledComponents/V3BodyGrey48"
import { VerticalSpacing } from "../VerticalSpacing"
import { NSRContent, NSRWrapper, NSTTitle } from "./V3EmptyStateScreen.styles"

interface V3EmptyStateProps {
    title: string | JSX.Element,
    svgIcon?: JSX.Element,
    description?: string | JSX.Element,
    maxWidth?: number,
    customPadding?: string,
    customTitleMargin?: number
    skipExtendMobile?: boolean,
    customDescriptionElem?: string,
}

export const V3EmptyState: React.FC<React.PropsWithChildren<V3EmptyStateProps>> = ({ title, svgIcon, description, children, maxWidth, customPadding, customTitleMargin = 30, skipExtendMobile = false, customDescriptionElem }) => {
    return <NSRWrapper $customPadding={customPadding} $skipExtendMobile={skipExtendMobile}>
        <div>
            <NSRContent $maxWidth={maxWidth}>
                {svgIcon}
                <VerticalSpacing top={customTitleMargin}>
                    <NSTTitle as="p">{title}</NSTTitle>
                </VerticalSpacing>
                <VerticalSpacing bottom={30} top={10}>
                    <V3BodyGrey48 as={customDescriptionElem}>{description}</V3BodyGrey48>
                </VerticalSpacing>
                {children}
            </NSRContent>
        </div>
    </NSRWrapper>
}