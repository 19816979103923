import styled from "styled-components";
import { media, theme } from "../../theme/theme";

export const V3TTTriggerWrapper = styled.span<{ $noCursor: boolean }>`
    line-height: 0;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        transform: none;
    }

    > * {
        ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
            transform: none;
        }
    }

    &:hover {
        cursor:  ${({ $noCursor }) => !$noCursor ? "pointer" : undefined};
    }
`;

export const V3TTContentWrapper = styled.div <{ background?: string, $hideOnMobile: boolean, $tooltipMaxWidth?: number }>`
    padding: 6px 12px;
    background: ${({ background }) => background ? background : "#17191C"}; 
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: 10;
    display: ${({ $hideOnMobile }) => $hideOnMobile ? "none" : "block"}; 
    width: max-content;
    max-width: ${({ $tooltipMaxWidth }) => $tooltipMaxWidth ? `${$tooltipMaxWidth}px` : undefined};
    font-size: 12px;
    line-height: 16px;
    font-family: ${({ theme }) => theme ? `${theme.typography.type.inter}` : null};

    strong {
        font-weight: ${({ theme }) => theme ? `${theme.typography.weight.semibold}` : null};
    }  

    color: white;

    a {
        color: white;
    }

    ${media.phone} {
        display: block;
    }
`;