import { ChangeEventHandler } from "react";
import { V3BodyGrey35 } from "../ReusableStyledComponents/V3BodyGrey35"
import { V3CaptionGrey48 } from "../ReusableStyledComponents/V3CaptionGrey48";
import { V3ToggleSwitch } from "../ToggleSwitch"
import { V3ToggleLabelWrapper } from "./V3ToggleWithLabel.styles";

interface V3ToggleWithLabelProps {
    name: string,
    checked: boolean,
    onChange: ChangeEventHandler,
    label: string,
    useCaption?: boolean
    disabled?: boolean,
    toggleOnRight?: boolean,
    skipStrong?: boolean,
}

export const V3ToggleWithLabel = ({ name, checked, onChange, label, useCaption = false, disabled = false, toggleOnRight = false, skipStrong = false }: V3ToggleWithLabelProps) => {
    const LabelToUse = useCaption ? V3CaptionGrey48 : V3BodyGrey35;

    return <V3ToggleLabelWrapper $toggleOnRight={toggleOnRight}>
        <div>
            <V3ToggleSwitch
                name={name}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
        {
            !!label && !!label.length && <div>
                <label htmlFor={name}>
                    <LabelToUse>{skipStrong ? <>{label}</> : <strong>{label}</strong>}</LabelToUse>
                </label>
            </div>
        }
    </V3ToggleLabelWrapper>
}