import styled from "styled-components";
import { V3CaptionGrey48 } from "../ReusableStyledComponents/V3CaptionGrey48";
import { V3BodyGrey35 } from "../ReusableStyledComponents/V3BodyGrey35";

export const V3ToggleLabelWrapper = styled.div<{ $toggleOnRight: boolean }>`
    display: flex;
    align-items: center;

    &:hover {
        ${V3CaptionGrey48},
        ${V3BodyGrey35} {
            color: ${({ theme }) => theme ? theme.v3.colors.blue : null};
        }
    }

    > div {
        label {
            cursor: pointer;
        }
        
        &:nth-child(2) {
            margin-left: ${({ $toggleOnRight }) => !$toggleOnRight ? "12px" : undefined};
            margin-right: ${({ $toggleOnRight }) => $toggleOnRight ? "12px" : undefined};
            order: ${({ $toggleOnRight }) => $toggleOnRight ? -1 : undefined};
        }
    }
`;