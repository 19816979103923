import { throttle } from "lodash";
import { useState, useEffect } from "react";

type WindowSize = {
    width: undefined | number,
    height: undefined | number,
}

const defaultState = {
    width: undefined,
    height: undefined,
}

export const useWindowSize = (initialState = defaultState as WindowSize) => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<WindowSize>(initialState);

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        const throttleResize = throttle(handleResize, 100);

        // Add event listener
        window.addEventListener("resize", throttleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", throttleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}