import styled, { css } from "styled-components";
import { V3Body, V3Caption } from "../Typography";

export const V3InputActionCaption = styled(V3Caption)`
    color: ${({ theme }) => theme.v3.colors.blue};
    cursor: pointer;
    position: relative;
    top: 2px;
`;

export const V3InputCaption = styled(V3Caption)`
    color: ${({ theme }) => theme.v3.colors.grey48};
    margin-top: ${({ theme }) => `${theme.v3.spacings.spacing2}px`};
`

export const V3InputErrorCaption = styled(V3InputCaption)`
    color: ${({ theme }) => theme.v3.colors.red};
`

export const V3InputLabel = styled(V3Body)`
    color: ${({ theme }) => theme.v3.colors.grey35};
`

export const V3InputTopLabelsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => `${theme.v3.spacings.spacing2}px`};
`;

export const V3InputWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const V3TextInputIconPositioner = styled.div<{ $disableIconHover: boolean }>`
    position: absolute;
    top: 13px;
    left: 18px;
    cursor: ${({ $disableIconHover }) => !$disableIconHover ? "pointer" : undefined};
`;

export const V3RightIconPositioner = styled(V3TextInputIconPositioner) <{ $customErrorRightOffset?: number }>`
    left: initial;
    right: ${({ $customErrorRightOffset }) => $customErrorRightOffset ? `${$customErrorRightOffset}px` : "18px"};

    svg {
        display: inline-flex;
    }
`;

export interface InputStyleProps { $hasIcon?: boolean, $isRounded?: boolean, $hasError?: boolean, $hasRightIcon?: boolean, $customRightPadding?: number };

export const InputMixin = css<InputStyleProps>`
    height: 42px;
    border: 0;
    width: 100%;
    padding: 8px 18px;
    padding-left: ${({ $hasIcon }) => $hasIcon ? "45px" : null};
    padding-right: ${({ $hasRightIcon, $customRightPadding }) => $customRightPadding ? `${$customRightPadding}px` : $hasRightIcon ? "38px" : null};
    border-radius: ${({ $isRounded }) => $isRounded ? "100px" : "6px"};
    background: ${({ theme }) => theme.v3.colors.grey96};
    border: 1px solid ${({ theme, $hasError }) => $hasError ? theme.v3.colors.red : theme.v3.colors.grey96};

    &::placeholder {
        color: ${({ theme }) => theme.v3.colors.grey48};
    }

    &:focus {
        outline: none;
        border: 1px solid ${({ theme }) => theme.v3.colors.blue};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px rgba(46, 119, 255, 0.2);
    }

    &:disabled {
        color: ${({ theme }) => theme.v3.colors.grey70};
    }
`;

export const V3InptClearIconSvg = styled.svg`
    &:hover {
        cursor: pointer;
    }
`;