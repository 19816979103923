import { InlineLink } from "../../components/ReusableStyledComponents/InlineLink";
import { UNAVAILABLE_RESOURCE_TYPE } from "../../types/unavailable_resource_type";

export const getUnavailableContentBodyCopy = (target?: UNAVAILABLE_RESOURCE_TYPE) => {
    switch (target) {
        case UNAVAILABLE_RESOURCE_TYPE.ARTICLE:
            return <>Oops! Looks like this content has not been made public. Please ask the owner to <InlineLink href={"https://authory.com/help/en/articles/6401424-how-to-share-a-content-item"} target="_blank">create a share link</InlineLink>.</>;
        case UNAVAILABLE_RESOURCE_TYPE.COLLECTION:
            return <>Oops! Looks like this content has not been made public. Please ask the owner to <InlineLink href={"https://authory.com/help/en/articles/6332390-how-to-share-a-collection"} target="_blank">create a share link</InlineLink>.</>;
        case UNAVAILABLE_RESOURCE_TYPE.PROFILE:
            return <>Oops! Looks like this portfolio has not been made public.</>
        default:
            return <></>
    }
}