import { ContentType } from "authory-api-types/dist/enums";
import { V3IconArticle, V3IconImage, V3IconPDF, V3IconPodcast, V3IconPost, V3IconVideo } from "../components/Icons";

export const V3COMBINED_POST_TYPE = `${ContentType.TextPost}$${ContentType.ReplyPost}$${ContentType.Thread}$${ContentType.VideoPost}$${ContentType.ImagePost}`;
export const V3COMBINED_WEBSITE_EMAIL_TYPE = `${ContentType.Website}$${ContentType.Email}`;

export const FORMATED_SOURCE_TYPES = [
    { value: ContentType.Article, text: "Articles", icon: V3IconArticle },
    { value: ContentType.PodcastItem, text: "Podcasts", icon: V3IconPodcast },
    { value: ContentType.Video, text: "Videos", icon: V3IconVideo },
    { value: V3COMBINED_POST_TYPE, text: "Social Media", icon: V3IconPost },
    { value: V3COMBINED_WEBSITE_EMAIL_TYPE, text: "Websites/Emails", icon: V3IconImage },
    { value: ContentType.PdfFile, text: "PDFs", icon: V3IconPDF },
]