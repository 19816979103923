import { APPLICATION_ROUTES } from "../types/routes";

export const UCMARKETINGPAGES = [
    {
        iconPath: "writers",
        primaryText: "Writers",
        secondaryText: "Supercharge your career, no matter what and where you write.",
        route: APPLICATION_ROUTES.USE_CASE_WRITERS,
    },
    {
        iconPath: "journalists",
        primaryText: "Journalists",
        secondaryText: "All your content in a single place, ready to truly get you ahead.",
        route: APPLICATION_ROUTES.USE_CASE_JOURNALISTS,
    },
    {
        iconPath: "content_marketers",
        primaryText: "Content Marketers",
        secondaryText: "Get a true home for all your bylined and non-bylined content.",
        route: APPLICATION_ROUTES.USE_CASE_CONTENT_MARKETERS,
    },
    {
        iconPath: "thought_leaders",
        primaryText: "Thought Leaders",
        secondaryText: "Use the content you create to turbocharge your personal brand.",
        route: APPLICATION_ROUTES.USE_CASE_THOUGHT_LEADERS,
    },
]