import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SubscriptionPlan } from "authory-api-types/dist/enums";
import { V3ModalBase } from "../V3ModalBase";
import { VerticalSpacing } from "../VerticalSpacing";
import { PaymentModalPlanDetailWrapper, PaymentModalPlanDetailsWithCTA } from "./V3ModalPayment.styles";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { V3Body, V3SubtitleMedium } from "../Typography";
import { InlineLink } from "../ReusableStyledComponents/InlineLink";
import { get2023PlanPrice } from "../../utils/getPlanPrice";
import { PaymentOptionsContainer, PaymentOptionsProps } from "./PaymentOptions";
import { getPlanMetrics } from "../../pagelayouts/settings/billing/payment.utils";
import { APPLICATION_ROUTES } from "../../types/routes";

export const getPlanSubLabel = (planSub?: SubscriptionPlan) => {
    switch (planSub) {
        case SubscriptionPlan.monthly:
            return "Standard monthly";
        case SubscriptionPlan.proMonthly:
            return "Professional Monthly";
        case SubscriptionPlan.yearly:
            return "Standard Yearly";
        case SubscriptionPlan.proYearly:
            return "Professional Yearly";
        default:
            return "";
    }
}

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID!);

interface V3ModalPaymentProps extends PaymentOptionsProps {
    onChangePlanHandler?: () => void,
    onFreePlanSelection?: () => void,
    hideCloseCTA?: boolean,
    modalOpen: boolean,
    planSub?: SubscriptionPlan,
    customModalTitle?: string
}

const V3ModalPaymentContent = ({ modalOpen, onCloseHandler, user, onPaymentUpdateHandler, trackEvent, isEndOfTrialFlow = false, onChangePlanHandler, onFreePlanSelection, hideCloseCTA, planSub, customModalTitle }: V3ModalPaymentProps) => {
    const { tier, isYearly } = getPlanMetrics(planSub);
    const monthlyPrice = get2023PlanPrice(tier.price, isYearly, tier.yearlyDiscountFactor, 0);

    return <V3ModalBase
        isOpen={modalOpen}
        onClose={onCloseHandler}
        title={customModalTitle ? customModalTitle : !isEndOfTrialFlow ? "Update payment method" : "Your free trial has ended"}
        hideCloseCTA={hideCloseCTA}
    >
        {
            isEndOfTrialFlow && <>
                <VerticalSpacing bottom={24}>
                    <V3CaptionGrey35>
                        To continue seamlessly with your portfolio, including access to all your imported and backed-up content, please select a plan and enter your payment details below.
                    </V3CaptionGrey35>
                </VerticalSpacing>
                <VerticalSpacing bottom={24}>
                    <PaymentModalPlanDetailWrapper>
                        <VerticalSpacing bottom={5}>
                            <V3SubtitleMedium style={{ textTransform: "capitalize" }}>{getPlanSubLabel(planSub)} Subscription </V3SubtitleMedium>
                        </VerticalSpacing>
                        <PaymentModalPlanDetailsWithCTA>
                            <V3Body>
                                ${monthlyPrice} per month  {isYearly ? `(billed at $${monthlyPrice * 12} yearly)` : ""}&nbsp;
                            </V3Body>
                            <V3Body>
                                <InlineLink onClick={() => {
                                    onChangePlanHandler && onChangePlanHandler();
                                }}>Change plan</InlineLink>
                            </V3Body>
                        </PaymentModalPlanDetailsWithCTA>
                    </PaymentModalPlanDetailWrapper>
                </VerticalSpacing>
            </>
        }
        <PaymentOptionsContainer
            onCloseHandler={onCloseHandler}
            user={user}
            onPaymentUpdateHandler={onPaymentUpdateHandler}
            trackEvent={trackEvent}
            isEndOfTrialFlow={isEndOfTrialFlow}
        />
        {
            isEndOfTrialFlow && <VerticalSpacing bottom={18} top={24}>
                <V3CaptionGrey35>
                    If you only need 10 or less content items in your account, you can also <InlineLink onClick={() => {
                        onFreePlanSelection && onFreePlanSelection();
                    }}>select the Free plan</InlineLink>. And if you want to cancel your account, you'll find the option <InlineLink href={APPLICATION_ROUTES.SETTINGS_BILLING}>here</InlineLink>.
                </V3CaptionGrey35>
            </VerticalSpacing>
        }
    </V3ModalBase >
}

export const V3ModalPayment = (props: V3ModalPaymentProps) => <Elements stripe={stripePromise}>
    <V3ModalPaymentContent
        {...props}
    />
</Elements>