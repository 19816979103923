import styled from "styled-components";
import { InlineLink } from "../ReusableStyledComponents/InlineLink";
import { Body } from "../Typography";

export const MarketingInviteBarWrapper = styled.div`
    padding: 15px;
    background-color: ${({ theme }) => theme ? theme.colors.dodgerBlue1 : null};
    text-align: center;

    ${Body},
    ${InlineLink} {
        color: white;
    }

    ${InlineLink} {
        text-decoration: underline;
    }
`;

export const MarketingBody = styled(Body)`
     font-family: ${({ theme }) => theme ? `${theme.typography.type.assistant}` : null};
`;