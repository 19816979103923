import { ProcessingContentType } from "authory-api-types/dist/enums";
import { DEFAULT_NEWSLETTER_REPORT_SEARCH_PARAMS } from "../pagelayouts/newsletter/report";
import { DEFAULT_SUBSCRIBERS__SEARCH_PARAMS } from "../pagelayouts/newsletter/subscribers";
import { ArticleFilterQueryParams, ShareCountsByArticleQueryParams } from "authory-api-types/dist/types";

// Querie names
export enum Queries {
  User = "USER",
  Content = "CONTENT",
  ContentAnalytics = "CONTENT_ANALYTICS",
  ContentPublishedAmount = "CONTENT_PUBLISHED_AMOUNT",
  Collections = "COLLECTIONS",
  Collection_Rules = "COLLECTION_RULES",
  DashboardNewsletter = "USER_DASHBOARD_NEWSLETTER",
  Newsletter = "USER_NEWSLETTER",
  NewsletterAnalytics = "USER_NEWSLETTER_ANALTYICS",
  NewsletterSubscriberHistory = "USER_NEWSLETTER_SUBSCRIBER_HISTORY",
  NextNewsletter = "NEXT_NEWSLETTER",
  Timezones = "TIMEZONES",
  Subscribers = "SUBSCRIBERS",
  Sources = "SOURCES",
  Export = "EXPORT",
  EmailPreferences = "EMAIL_PREFERENCES",
  NextIssuePreview = "NEXT_ISSUE_PREVIEW",
  UnsplashImages = "UNSPLASH_IMAGES",
  GDPRSettings = "GDPR_SETTINGS",
  Author = "AUTHOR",
  AuthorItem = "AUTHOR_ITEM",
  Collection = "COLLECTION",
  Accounts = "ACCOUNTS",
  AnalyticsSiteHistory = "ANALYTICS_SITE_HISTORY",
  AnalyticsSocialNetwork = "ANALYTICS_SOCIAL_NETWORK",
  AnalyticsSourceTimespan = "ANALYTICS_SOURCE_TIMESPAN",
  BillingData = "BILLING_DATA",
  BillingInvoices = "BILLING_INVOICES",
  UserExamples = "USER_EXAMPLES",
  CustomDomains = "CUSTOM_DOMAINS",
  Webhooks = "WEBHOOKS",
  CustomDomainsConfig = "CUSTOM_DOMAINS_CONFIG",
  CertificatesList = "CERTIFICATES_LIST",
  CertificatesPublicList = "CERTIFICATES_PUBLIC_LIST",
  AuthorCertificate = "AUTHOR_CERTIFICATE",
}

export type Token = string | null;

export const getAuthenticatedUserQuerie = (
  token: Token
) => [Queries.User, token];

export const getAuthorContentQuerie = (token: string | null, authorSlug: string | undefined, params: ArticleFilterQueryParams) => {
  return [
    Queries.Content,
    token,
    authorSlug,
    params
  ] as [string, string | null, string | undefined, ArticleFilterQueryParams];
}

export const getAuthorContentAnalyticsQuerie = (token: string | null, authorSlug: string | undefined, params: ShareCountsByArticleQueryParams) => {
  return [
    Queries.ContentAnalytics,
    token,
    authorSlug,
    params
  ] as [string, string | null, string | undefined, ShareCountsByArticleQueryParams];
}

export const getCollectionsQuerie = (data: any, token: string | null, authorSlug?: string) => {
  return [
    Queries.Collections,
    token,
    authorSlug,
    data.shared ? data.shared === "true" : undefined,
    data.type || undefined,
    data.text || undefined,
    data.favourite ? data.favourite === "true" : undefined,
    data.contentTypes || "",
    "createdAt"
  ];
}

export const getV3CollectionRulesKey = (token: string, authorSlug: string, collectionSlug: string) => {
  return [
    Queries.Collection_Rules,
    token,
    authorSlug,
    collectionSlug
  ];
}

export const getSharedCollectionQuerie = (token: Token, authorSlug: string, collectionId: string) => {
  return [
    Queries.Collection,
    token,
    authorSlug,
    collectionId
  ]
}

export const getNewsletterAnalyticsQuerie = (data: any, token: string | null, authorSlug: string | undefined) => {
  return [
    Queries.NewsletterAnalytics,
    token,
    authorSlug,
    data.orderBy || DEFAULT_NEWSLETTER_REPORT_SEARCH_PARAMS.ORDERBY
  ]
}

export const getNextNewsletterQuerie = (token: string, userSlug: string) => ([
  Queries.NextNewsletter,
  token,
  userSlug
]);

export const getTimezoneQuerie = () => ([Queries.Timezones]);

export const getNewsletterSubscribersQuerie = (data: any, token: string | null, authorSlug: string | undefined) => {
  return [
    Queries.Subscribers,
    token,
    authorSlug,
    data.orderBy || DEFAULT_SUBSCRIBERS__SEARCH_PARAMS.ORDERBY
  ]
}

export const getSubscribersHistoryQuerie = (token: string, authorSlug: string) => ([Queries.NewsletterSubscriberHistory, token, authorSlug]);

export const getSourcesKey = (token: string, authorSlug: string) => ([Queries.Sources, token, authorSlug]);

export const getExportKey = (token: string, authorSlug: string) => ([Queries.Export, token, authorSlug]);

export const getEmailPreferencesKey = (token: string, authorSlug: string) => ([Queries.EmailPreferences, token, authorSlug]);

export const getNextIssuePreviewQuerie = (authorSlug: string) => ([Queries.NextIssuePreview, authorSlug]);

export const getSplashInfiniteQuerie = (keyword: string) => ([Queries.UnsplashImages, keyword]);

export const getGDPRSettingsQuerie = (token: string | null, authorSlug: string) => ([Queries.GDPRSettings, token, authorSlug]);

export const getAuthorQuerie = (authorSlug: string) => ([Queries.Author, authorSlug]);

export const getItemQuerie = (token: string | null, itemSlug: string) => ([Queries.AuthorItem, token, itemSlug]);

export const getAnalyticsSiteHistoryQuerie = (token: string | null, authorSlug: string | undefined, sourceType: ProcessingContentType[] | null, from: string, to: string) => ([Queries.AnalyticsSiteHistory, token, authorSlug, sourceType, from, to])

export const getAnalyticsSourceTimespanQuerie = (token: string | null, authorSlug: string | undefined, from: string, to: string, sourceType: ProcessingContentType[]) => ([Queries.AnalyticsSourceTimespan, token, authorSlug, from, to, sourceType])

export const getAnalyticsSocialNetworkTimespanQuerie = (token: string | null, authorSlug: string | undefined, from: string, to: string, sourceType: ProcessingContentType[]) => ([Queries.AnalyticsSocialNetwork, token, authorSlug, from, to, sourceType])

export const getBillingDataQuerie = (token: string | null, authorSlug: string | undefined) => ([Queries.BillingData, token, authorSlug])

export const getBillingInvoicesQuerie = (token: string | null, authorSlug: string | undefined) => ([Queries.BillingInvoices, token, authorSlug])

export const getCustomDomainQuerie = (token: string | null, authorSlug: string | undefined) => ([Queries.CustomDomains, token, authorSlug])

export const getCertificatesListQuerie = (token: string | null, authorSlug: string | undefined) => ([Queries.CertificatesList, token, authorSlug])

export const getPublicCertificatesListQuerie = (token: string | null, authorSlug: string | undefined) => ([Queries.CertificatesPublicList, token, authorSlug])

export const getAuthorCertificateQuerie = (authorSlug: string | undefined, uuid: string) => ([Queries.AuthorCertificate, authorSlug, uuid])

export const getWebhooksQuerie = (token: string | null) => ([Queries.Webhooks, token])

export const getCustomDomainConfigQuerie = (token: string | null, authorSlug: string | undefined) => ([Queries.CustomDomainsConfig, token, authorSlug])

export const getAccountsQuerie = (token: string | null, authorSlug: string | undefined) => ([
  Queries.Accounts,
  token || "",
  authorSlug || ""
])