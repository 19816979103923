import styled from "styled-components"
import { media } from "../../theme/theme";

type VerticalSpacingBreakpoint = {
    screen: number,
    marginTop?: number,
    marginBottom?: number
}

export type VerticalSpacing = {
    top?: number,
    bottom?: number,
    breakpoints?: VerticalSpacingBreakpoint[]
}

export const VerticalSpacing = styled.div<VerticalSpacing>`
    margin-top:  ${({ top }) => top ? `${top}px` : null};
    margin-bottom:  ${({ bottom }) => bottom ? `${bottom}px` : null};

    ${({ breakpoints }) => breakpoints ? breakpoints.map(({ screen, marginTop, marginBottom }) => `${media.custom(screen)} {
        ${marginBottom !== undefined ? `margin-bottom: ${marginBottom}px;` : ""}
        ${marginTop !== undefined ? `margin-top: ${marginTop}px;` : ""}
    }`) : null}
`;

export const HiddenPrintVerticalSpacing = styled(VerticalSpacing)`
    @media print {
        margin: 0;
    }
`;