import React, { useEffect, useRef } from "react"
import { HR, MobileLinkWrapper, MobileMenuPanel, MobilePanelH4, MobileLink, MobileLinkSimple, MobilePanelButtonsWrapper, MobileButtonCTA } from "./MobileMenuPanel.styles"
import { APPLICATION_ROUTES } from "../../types/routes"
import { MediumText } from "../Typography"
import { CTASize, CTAVariant, MarketingCTA } from "../MarketingCTA"
import { throttle } from "lodash"
import { FTMARKETINGPAGES } from "../../utils/featuresMarketingPage"
import { UCMARKETINGPAGES } from "../../utils/useCasesMarketingPages"
import { MOREMARKETINGPAGES } from "../../utils/moreMarketingPages"
import { OptionalNextLink } from "../ReusableStyledComponents/OptionalNextLink"

type MobileLinkProps = {
    icon: JSX.Element
    text: string,
    href: string,
    useNextRouter?: boolean
}

const MobileLinkItem = ({ icon, text, href, useNextRouter = true }: MobileLinkProps) => <div>
    <OptionalNextLink href={href} useNextRouter={useNextRouter}>
        <MobileLink>
            <div>{icon}</div>
            <MediumText>{text}</MediumText>
        </MobileLink>
    </OptionalNextLink>
</div>

type MobileMenuProps = {
    open: boolean,
    setShowShadow: (val: boolean) => void,
    useNextRouter?: boolean
}

export const MobileMenu = ({ open, setShowShadow, useNextRouter = true }: MobileMenuProps) => {

    const divEl = useRef<HTMLDivElement>(null);

    const scrollCheck = () => {
        if (!divEl || !divEl.current) return;

        if (divEl.current.scrollTop > 0) {
            setShowShadow(true);
        } else {
            setShowShadow(false);
        }
    }

    useEffect(() => {
        if (open) setTimeout(() => scrollCheck(), 40);
    }, [open]);

    useEffect(() => {
        if (!divEl || !divEl.current) return;

        const throttleScroll = throttle(scrollCheck, 100);

        divEl.current.addEventListener('scroll', throttleScroll);

        return () => {
            if (!divEl || !divEl.current) return;

            divEl.current.removeEventListener('scroll', throttleScroll);
        };
    }, [divEl.current]);

    return <MobileMenuPanel open={open} ref={divEl} >
        <MobilePanelH4>Features</MobilePanelH4>
        <MobileLinkWrapper>
            {
                FTMARKETINGPAGES.map(feature => {
                    return <React.Fragment key={feature.route}>
                        <MobileLinkItem
                            icon={<img src={`/authory_static_files/images/icons/${feature.iconPath}_xs_active.svg`} alt={`${feature.iconPath} menu icon`} />}
                            text={feature.primaryText}
                            href={feature.route}
                            useNextRouter={useNextRouter}
                        />
                    </React.Fragment>
                })
            }
        </MobileLinkWrapper>
        <HR />
        <MobilePanelH4>Use cases</MobilePanelH4>
        <MobileLinkWrapper>
            {
                UCMARKETINGPAGES.map(uc => {
                    return <React.Fragment key={uc.route}>
                        <MobileLinkItem
                            icon={<img src={`/authory_static_files/images/icons/${uc.iconPath}_xs_active.svg`} alt={`${uc.iconPath} menu icon`} />}
                            text={uc.primaryText}
                            href={uc.route}
                            useNextRouter={useNextRouter}
                        />
                    </React.Fragment>
                })
            }
        </MobileLinkWrapper>
        <HR />
        <MobilePanelH4>
            <OptionalNextLink href={APPLICATION_ROUTES.PRICING} useNextRouter={useNextRouter}>
                Pricing
            </OptionalNextLink>
        </MobilePanelH4>
        <HR />
        <MobilePanelH4>More</MobilePanelH4>
        <MobileLinkWrapper>
            {
                MOREMARKETINGPAGES.map(item => <div key={item.href}>
                    {
                        (item.href === APPLICATION_ROUTES.BLOG || item.href === APPLICATION_ROUTES.EXAMPLES) ? <a href={item.href}>
                            <MobileLinkSimple>{item.primaryText}</MobileLinkSimple>
                        </a> : <OptionalNextLink href={item.href} useNextRouter={useNextRouter}>
                            <MobileLinkSimple>{item.primaryText}</MobileLinkSimple>
                        </OptionalNextLink>
                    }
                </div>)
            }
        </MobileLinkWrapper>
        <MobilePanelButtonsWrapper>
            <div>
                <OptionalNextLink href={APPLICATION_ROUTES.SIGNUP} useNextRouter={useNextRouter}>
                    <MobileButtonCTA
                        size={CTASize.M}
                        variant={CTAVariant.Primary}
                        text="Get started for free"
                    />
                </OptionalNextLink>
            </div>
            <div>
                <OptionalNextLink href={APPLICATION_ROUTES.LOGIN} useNextRouter={useNextRouter}>
                    <MarketingCTA
                        size={CTASize.M}
                        variant={CTAVariant.Secundary}
                        text="Login"
                    />
                </OptionalNextLink>
            </div>
        </MobilePanelButtonsWrapper>
    </MobileMenuPanel>
}