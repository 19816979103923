import { V3ToggleLabel, V3ToggleInput, V3ToggleSpan } from "./V3ToggleSwitch.styles";

export enum V3ToggleColorVariant {
    blue = "blue",
    green = "green",
}
interface V3RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
    variant?: V3ToggleColorVariant
    labelDataId?: string
}

export const V3ToggleSwitch = ({ name, variant = V3ToggleColorVariant.blue, labelDataId, ...rest }: V3RadioButtonProps) => {
    return (
        <V3ToggleLabel htmlFor={name} data-testid={labelDataId}>
            <V3ToggleInput variant={variant} name={name} type="checkbox" id={name} {...rest} />
            <V3ToggleSpan ></V3ToggleSpan>
        </V3ToggleLabel>
    );
}