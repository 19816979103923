import React, { useRef, useState } from "react";
import {
    useFloating,
    useInteractions,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    safePolygon,
    autoUpdate,
    offset,
    shift,
    arrow,
    FloatingArrow
} from "@floating-ui/react";
import { DropdownContentWrapper, DropdownMenuArrow } from "./DropdownMenuPanel.styles";

type DropdownMenuPanelProps = {
    referenceContent: JSX.Element,
    tooltipContent: JSX.Element,
    maxWidth?: number,
}

export const DropdownMenuPanel = ({ referenceContent, tooltipContent, maxWidth }: DropdownMenuPanelProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        strategy: "fixed",
        onOpenChange: setIsOpen,
        placement: "bottom",
        // Make sure the tooltip stays on the screen
        whileElementsMounted: autoUpdate,
        middleware: [
            // Make sure tooltip is X pixels away from reference element
            offset(40),
            // Make sure the tooltip is in view, even if it can't be fully centered to the element
            shift({ padding: 10 }),
            arrow({
                element: arrowRef,
            }),
        ]
    });

    const hover = useHover(context, {
        handleClose: safePolygon(),
        move: false,
        delay: 150
    });

    const focus = useFocus(context);
    const dismiss = useDismiss(context);

    // Role props for screen readers
    const role = useRole(context, { role: "menu" });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
        dismiss,
        role
    ]);

    return (
        <>
            <div
                ref={refs.setReference}
                {...getReferenceProps()}
            >
                {referenceContent}
            </div>
            {
                isOpen && <DropdownContentWrapper
                    $maxWidth={maxWidth}
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                >
                    <FloatingArrow
                        ref={arrowRef}
                        context={context}
                        className={String(DropdownMenuArrow).replace(".", "")}
                        width={30}
                        height={15}
                        fill="white"
                        style={{
                            border: "1em solid black",
                            borderColor: "transparent transparent white white",
                            boxShadow: "-3px 3px 4px 0 rgba(0, 0, 0, 0.08)",
                            transformOrigin: "14px 25px",
                            transform: "rotate(315deg)"
                        }}
                    />
                    <div>
                        {tooltipContent}
                    </div>
                </DropdownContentWrapper>
            }
        </>
    );
}