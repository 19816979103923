import styled, { css } from "styled-components";
import { EmbedTheme } from "../../utils/embeds";
import { V3ProfileAvatarSizes } from "./V3ProfileAvatar.types";

type Size = keyof typeof V3ProfileAvatarSizes;

const getSize = (size: Size) => {
    switch (size) {
        case V3ProfileAvatarSizes.L:
            return "96px";
        case V3ProfileAvatarSizes.M:
            return "72px";
        case V3ProfileAvatarSizes.S:
            return "42px";
        case V3ProfileAvatarSizes.XS:
            return "24px";
        case V3ProfileAvatarSizes.EMBED_COMPACT:
            return "80px";
        case V3ProfileAvatarSizes.EMBED_NORMAL:
            return "50px";
    }
}

export const AvatarProfileWrapper = styled.div<{ size: Size, $embedTheme: EmbedTheme, disabled?: boolean }>`
    height: ${({ size }) => getSize(size)};
    width: ${({ size }) => getSize(size)};
    border-radius: 50%;
    background: ${({ theme }) => theme ? theme.v3.colors.lightBlue : null};
    position: relative;
    overflow: hidden;
    border: ${({ $embedTheme }) => $embedTheme === EmbedTheme.light ? "1px solid white" : undefined};
    opacity: ${({ disabled }) => disabled ? "0.3" : undefined};
`;

const imgMixin = css`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0; 
    right: 0;
`;

export const AvatarProfileImage = styled.img`
    ${imgMixin};
`;

const getPlaceholderSvgSize = (size: Size) => {
    switch (size) {
        case V3ProfileAvatarSizes.L:
            return "60px";
        case V3ProfileAvatarSizes.M:
            return "40px";
        case V3ProfileAvatarSizes.S:
            return "24px";
        case V3ProfileAvatarSizes.XS:
            return "16px";
    }
}

export const AvatarProfileSvg = styled.svg<{ size: Size }>`
    ${imgMixin}
    height: ${({ size }) => getPlaceholderSvgSize(size)};
    width: ${({ size }) => getPlaceholderSvgSize(size)};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;