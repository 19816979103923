import { EmbedTheme } from "../../utils/embeds";
import { AvatarProfileImage, AvatarProfileSvg, AvatarProfileWrapper } from "./V3ProfileAvatar.styles";
import { V3ProfileAvatarProps } from "./V3ProfileAvatar.types";

export const V3ProfileAvatar = ({ src, size, embedTheme = EmbedTheme.light, disabled, authorName, ...rest }: V3ProfileAvatarProps) => {
    const hasSrc = !!src;

    return <AvatarProfileWrapper size={size} $embedTheme={embedTheme} disabled={disabled} >
        {
            hasSrc ? <AvatarProfileImage src={src} {...rest} alt={`${authorName}'s Portfolio`} />
                : <AvatarProfileSvg size={size} {...rest} aria-label={`${authorName}'s Portfolio`} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 7.333A2.667 2.667 0 1 0 8 2a2.667 2.667 0 0 0 0 5.333ZM4 14v-1.333A2.667 2.667 0 0 1 6.667 10h2.666A2.667 2.667 0 0 1 12 12.667V14" stroke="#2E77FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </AvatarProfileSvg>
        }
    </AvatarProfileWrapper>
}