import styled from "styled-components";
import { V3Body } from "../Typography";
import { media } from "../../theme/theme";
import { LEFT_SIDE_MENU_WIDTH } from "../../layout/SideBarLayout/SidebarLayout.styles";

export const LEFT_NAV_MOBILE_MENU_BR = 880;

export const LeftNavBarWrapper = styled.div<{ $menuOpen: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 18px;
    background: ${({ theme, $menuOpen }) => $menuOpen ? theme.v3.colors.blue : "white"};

    ${media.custom(LEFT_NAV_MOBILE_MENU_BR)} {
        flex-direction: column;
        padding: 0;
        padding: 15px 0 0 0;
        min-height: 100vh;
    }
`;

export const LeftNavBodyLink = styled(V3Body) <{ $active?: boolean, $customDesktopPadding?: string, $inactive: boolean }>`
    color: ${({ theme, $active }) => $active ? theme.v3.colors.blue : theme.v3.colors.grey35};
    text-decoration: none;
    display: block;
    padding: 7px;
    margin-right: 4px;
    background: ${({ theme, $active }) => $active ? theme.v3.colors.grey96 : undefined};
    
    &:hover {
        background: ${({ theme }) => theme.v3.colors.grey96};
    }

    ${media.custom(LEFT_NAV_MOBILE_MENU_BR)} {
        width: ${LEFT_SIDE_MENU_WIDTH}px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18px 0px;
        margin-right: 0;
    }

    svg {
        display: block;

        path, rect {
            stroke: ${({ $active, theme, $inactive }) => $inactive ? theme.v3.colors.grey88 : $active ? theme.v3.colors.blue : undefined};
        }
    }

    &:hover {
        color: ${({ theme }) => theme ? theme.v3.colors.blue : undefined};
       
        svg {
            path, rect {
                stroke: ${({ theme }) => theme ? theme.v3.colors.blue : undefined};
            }
        }
    }
`;

const LeftNavPanel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    ${media.custom(LEFT_NAV_MOBILE_MENU_BR)} {
        flex-direction: column;
    }
`;

export const LeftNavTopPanel = styled(LeftNavPanel)`
    > div {
        display: none;

        ${media.custom(LEFT_NAV_MOBILE_MENU_BR)} {
            display: inline-flex;
        }
    }
`;

export const LeftNavbarLogoPanel = styled(LeftNavPanel)`
    ${media.custom(LEFT_NAV_MOBILE_MENU_BR)} {
        margin-bottom: 16px;
    }
`;

export const LeftNavBottomPanel = styled(LeftNavPanel)`
    ${LeftNavBodyLink} {
        ${media.custom(LEFT_NAV_MOBILE_MENU_BR)} {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0px;
        }
    }
`;

export const LeftBarShowDesktop = styled.div`
    display: none;

    ${media.custom(LEFT_NAV_MOBILE_MENU_BR)} {
        display: block;
    }
`;

export const LeftBarShowMobile = styled.div`
    display: block;

    ${media.custom(LEFT_NAV_MOBILE_MENU_BR)} {
        display: none;
    }
`;

export const LeftBarMobileIconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

    ${media.custom(LEFT_NAV_MOBILE_MENU_BR)} {
        display: none;
    }
`;

export const LeftNavbarLinkWrapper = styled.div<{ $inactive: boolean }>`
    pointer-events: ${({ $inactive }) => $inactive ? "none" : undefined};
    cursor: ${({ $inactive }) => $inactive ? "initial" : undefined};
`;