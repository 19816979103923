import styled from "styled-components";
import { V3Body, V3SubtitleMedium } from "../Typography";

export const MobileNavBarPanel = styled.div<{ $isOpen: boolean, $topValue: number }>`
    width: 100%;
    height: ${({ $topValue }) => `calc(100vh - ${$topValue}px)`};
    padding-top: 10px;
    padding-bottom: 120px;
    background: ${({ theme }) => theme ? theme.v3.colors.blue : "white"};
    position: fixed;
    top: ${({ $topValue }) => `${$topValue}px`};
    transform: translateX(${({ $isOpen }) => $isOpen ? "0%" : "100%"});
    z-index: 2;
    overflow-y: auto;

    > ul {
        > li {
            &:not(:last-child) {
                border-bottom: 1px solid rgba(255,255,255,0.2);
            }
        }
    }
`

export const MobileMenuOption = styled.div<{ $panelOpen: boolean }>`
    display: flex;
    align-items: center;
    color: white;
    padding: 28px 17px;
    user-select: none;

    ${V3SubtitleMedium} {
        color: white;
    }

    &:hover {
        cursor: pointer;
    }

    > div {
        display: inline-flex;

        &:first-child  {
            margin-right: 16px;
        }

        &:nth-child(3) {
            margin-left: auto;

            svg {   
                transition: transform .3s ease;
                transform: rotate(${({ $panelOpen }) => $panelOpen ? "90deg" : "0"});
            }
        }

        svg {   
            width: 30px;
            height: auto;
            
            rect,
            path {
                stroke: white;
            }
        }
    }
`;

export const MobileNavbarLink = styled.a`
    width: 100%;
    display: block;
    text-decoration: none;
`;

export const SubMenuWrapper = styled.ul`
    margin: 0 0 16px 0;
`;

export const SubMenuLink = styled(V3Body) <{ $noStroke?: boolean }>`
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: white;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 13px 0px 13px 42px;
    cursor: pointer;

    > div {
        display: inline-flex;

        &:first-child  {
            margin-right: 18px;
        }

        svg {
            rect,
            path {
                stroke: ${({ $noStroke }) => $noStroke ? undefined : "white"};
            }
        }
    }
`;