
import { css } from "styled-components";

export const MarketingLinkHoverEffect = css`
    a {
        display: block;
        text-decoration: none;

        svg {
            transition: transform .25s ease;

            path {
                transition: stroke .25s ease;
            }
        }

        &:hover {
            text-decoration: none;

            > div {
                > div {
                    &:not(:first-child) {
                        svg {
                            transform:  translateX(7px);

                                path {
                                    stroke: ${({ theme }) => theme ? theme.marketing.colors.chathamsBlue : null};
                                }
                        }
                    }
                }
            }
        }
    }
`;
