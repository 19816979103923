import styled from "styled-components";
import { V3Body, V3Caption } from "../Typography";
import { V3ButtonColor, V3ButtonIconPosition, V3ButtonVariant } from "./V3Button.types";

export const V3StyledButton = styled.button<{
    color: keyof typeof V3ButtonColor,
    $variant: keyof typeof V3ButtonVariant,
    $iconPosition: keyof typeof V3ButtonIconPosition,
    $hasText: boolean,
    $autoWidth?: boolean
    $minWidth?: number,
    $skipIconStyling: boolean,
    $relativePosition?: boolean,
}>`
    position: ${({ $relativePosition }) => $relativePosition ? "relative" : undefined};
    display: flex;
    width: ${({ $autoWidth }) => $autoWidth ? "auto" : "100%"};
    min-width: ${({ $minWidth }) => $minWidth ? `${$minWidth}px` : "106px"};
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: ${({ $variant }) => $variant === V3ButtonVariant.small ? "36px" : "42px"};
    padding: ${({ $variant, $hasText }) => {
        if ($variant === V3ButtonVariant.small) {
            if ($hasText) {
                return "9px 24px";
            } else {
                return "10px 16px";
            }
        }

        if ($variant === V3ButtonVariant.large) {
            if ($hasText) {
                return "12px";
            } else {
                return "13px 24px";
            }
        }
    }};
    border-radius: 100px;
    background: ${({ color, theme }) => {
        switch (color) {
            case V3ButtonColor.primary:
                return theme.v3.colors.blue;
            case V3ButtonColor.secondary:
                return theme.v3.colors.white;
            case V3ButtonColor.error:
                return theme.v3.colors.red;
        }
    }};
    border: 1px solid ${({ color, theme }) => {
        switch (color) {
            case V3ButtonColor.primary:
                return theme.v3.colors.blue;
            case V3ButtonColor.secondary:
                return theme.v3.colors.grey88;
            case V3ButtonColor.error:
                return theme.v3.colors.red;
        }
    }};

    &:hover {
        cursor: pointer;
            background: ${({ color, theme }) => {
        switch (color) {
            case V3ButtonColor.primary:
                return theme.v3.colors.darkBlue;
            case V3ButtonColor.secondary:
                return theme.v3.colors.grey96;
            case V3ButtonColor.error:
                return theme.v3.colors.darkRed;
        }
    }};
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        background: ${({ color, theme }) => {
        switch (color) {
            case V3ButtonColor.primary:
                return theme.v3.colors.midBlue;
            case V3ButtonColor.secondary:
                return theme.v3.colors.grey96;
            case V3ButtonColor.error:
                return theme.v3.colors.lightRed;
        }
    }};
        border: 1px solid ${({ color, theme }) => {
        switch (color) {
            case V3ButtonColor.primary:
                return theme.v3.colors.midBlue;
            case V3ButtonColor.secondary:
                return theme.v3.colors.grey96;
            case V3ButtonColor.error:
                return theme.v3.colors.lightRed;
        }
    }};
        color: ${({ color, theme }) => {
        switch (color) {
            case V3ButtonColor.primary:
                return theme.v3.colors.white;
            case V3ButtonColor.secondary:
                return theme.v3.colors.grey88;
            case V3ButtonColor.error:
                return theme.v3.colors.white;
        }
    }};
    }

    > div {
        display: inline-flex;
        
        &:not(:last-child) {
            margin-right: ${({ $iconPosition }) => {
        switch ($iconPosition) {
            case V3ButtonIconPosition.left:
                return `8px`;
            case V3ButtonIconPosition.right:
                return "0";
        }
    }};
            margin-left: ${({ $iconPosition }) => {
        switch ($iconPosition) {
            case V3ButtonIconPosition.left:
                return "0";
            case V3ButtonIconPosition.right:
                return `8px`;
        }
    }};
        }

        &:first-child {
            order:  ${({ $iconPosition }) => {
        switch ($iconPosition) {
            case V3ButtonIconPosition.left:
                return "0";
            case V3ButtonIconPosition.right:
                return "1";
        }
    }};
        }

        > svg {
            > path {
                stroke: ${({ color, theme, $skipIconStyling }) => {
        if ($skipIconStyling) return;
        switch (color) {
            case V3ButtonColor.primary:
            case V3ButtonColor.error:
                return theme.v3.colors.white;
            case V3ButtonColor.secondary:
                return theme.v3.colors.grey70;
        }
    }};
            }
        }
    }

    ${V3Body},
    ${V3Caption} {
        color: ${({ color, theme }) => {
        switch (color) {
            case V3ButtonColor.primary:
            case V3ButtonColor.error:
                return theme.v3.colors.white;
            case V3ButtonColor.secondary:
                return theme.v3.colors.grey35;
        }
    }};
    };
`;