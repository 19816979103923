import { MouseEventHandler } from "react";
import { theme } from "../../theme/theme";
import { V3IconAlertCircle, V3IconHelpCircle } from "../Icons";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { V3InptClearIconSvg, V3InputActionCaption, V3InputCaption, V3InputErrorCaption, V3InputLabel, V3InputTopLabelsWrapper, V3InputWrapper, V3RightIconPositioner, V3TextInputIconPositioner } from "./SharedInputComponent.styles";
import { WrapperWithTooltip } from "../ReusableStyledComponents/WrapperWithTooltip";
import { V3Tooltip } from "../Tooltip";

export interface SharedInputProps {
    label?: string;
    caption?: string;
    icon?: JSX.Element;
    isRounded?: boolean;
    error?: string | JSX.Element;
    actionText?: string;
    hasError?: boolean;
    customErrorRightOffset?: number;
    hasClearIcon?: boolean;
    characterLimit?: number;
    onClearIconHandler?: MouseEventHandler<SVGSVGElement>,
    inputValue?: any,
    actionClickHandler?: () => void,
    disableIconHover?: boolean,
    tooltipContent?: string | JSX.Element,
    tooltipMaxWidth?: number,
}

export const SharedInputComponent: React.FC<React.PropsWithChildren<SharedInputProps>> = ({
    label,
    caption,
    icon,
    error,
    actionText,
    children,
    hasError,
    customErrorRightOffset,
    hasClearIcon = false,
    characterLimit,
    onClearIconHandler,
    inputValue,
    actionClickHandler,
    disableIconHover = false,
    tooltipContent,
    tooltipMaxWidth,
}) => {
    const hasAction = !!actionText;
    const hasLabel = !!label;
    const hasActionOrLabel = hasAction || hasLabel;
    const hasRightSideIcon = hasClearIcon || hasError || !!characterLimit;

    return <>
        {hasActionOrLabel && <V3InputTopLabelsWrapper>
            <div>
                <WrapperWithTooltip>
                    <div>
                        {label && <V3InputLabel><strong>{label}</strong></V3InputLabel>}
                    </div>
                    <div>
                        {
                            !!tooltipContent && <V3Tooltip
                                tooltipMaxWidth={tooltipMaxWidth}
                                placement="right"
                                referenceContent={<V3IconHelpCircle />}
                                tooltipContent={tooltipContent}
                            />
                        }
                    </div>
                </WrapperWithTooltip>
            </div>
            <div>
                {hasAction && <V3InputActionCaption onClick={() => actionClickHandler && actionClickHandler()}>{actionText}</V3InputActionCaption>}
            </div>
        </V3InputTopLabelsWrapper>}
        <V3InputWrapper>
            {icon && <V3TextInputIconPositioner $disableIconHover={disableIconHover}>{icon}</V3TextInputIconPositioner>}
            {children}
            {
                hasRightSideIcon && <V3RightIconPositioner $disableIconHover={disableIconHover} $customErrorRightOffset={customErrorRightOffset}>
                    {hasClearIcon && !characterLimit && <V3InptClearIconSvg onClick={(e) => onClearIconHandler && onClearIconHandler(e)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="m2.4 2.4 11.2 11.2M13.6 2.4 2.4 13.6" stroke="#ABB0BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </V3InptClearIconSvg>}
                    {
                        characterLimit && <V3CaptionGrey35>{`${(inputValue?.length || 0)}/${characterLimit}`}</V3CaptionGrey35>
                    }
                    {hasError && !characterLimit && !hasClearIcon && <V3IconAlertCircle stroke={theme.v3.colors.red} />}
                </V3RightIconPositioner>
            }
        </V3InputWrapper>
        {error && <V3InputErrorCaption>{error}</V3InputErrorCaption>}
        {caption && !hasError && <V3InputCaption>{caption}</V3InputCaption>}
    </>
}