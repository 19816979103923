import { QueryClient, useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { addOrRemoveArticleFromCollection } from "../../../api/articles";
import { V3GenericErrorHandler } from "../../../api/error-handler";
import { Queries, getAuthenticatedUserQuerie, getItemQuerie } from "../../../types/queries";
import { assign } from "lodash";
import { AuthenticatedUserResponse, ContentWithSharesResponse } from "authory-api-types/dist/types";

// Add or remove item from  collection mutation
export const getAddRemoveItemFromCollectionMutation = (
    queryClient: QueryClient,
) => useMutation({
    mutationFn: addOrRemoveArticleFromCollection,
    onMutate: (data) => {
        const user = queryClient.getQueryData<AuthenticatedUserResponse>(getAuthenticatedUserQuerie(data.token));

        const oldItem = queryClient.getQueryData<ContentWithSharesResponse>(getItemQuerie(data.token, data.articleIds[0]));

        if (data.useContentDetaisOptimisticUpdate) {
            // Generate new item details
            const newItemDetails = assign({}, oldItem, {
                article: {
                    ...oldItem?.article,
                    collections: data.add
                        ? oldItem?.article.collections.concat(user?.collections.find(col => col.canonicalSlug === data.collectionSlug)!).sort((a, b) => a.order - b.order)
                        : oldItem?.article.collections.filter(col => col.canonicalSlug !== data.collectionSlug).sort((a, b) => a.order - b.order)
                }
            });

            // Update new item details
            queryClient.setQueryData(getItemQuerie(data.token, data.articleIds[0]), newItemDetails);
        }
    },
    onError: V3GenericErrorHandler(toast.error),
    onSuccess: async (_data, { skipContentInvalidation }) => {
        queryClient.invalidateQueries({ queryKey: [Queries.Collections] });

        if (!skipContentInvalidation) await queryClient.invalidateQueries({ queryKey: [Queries.Content] });
    }
});
