import ReactGA from 'react-ga4';
import { BaseTracker } from "../BaseTracker";
import { AuthenticatedUser } from '../../types/user';


/**
 * GoogleAnalyticsTracker class responsible for google analytic events
 */
export class GoogleAnalyticsTracker extends BaseTracker {

    constructor(user: AuthenticatedUser) {
        super(user, process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);
    }

    protected initializeInternal() {
        ReactGA.initialize([{
            trackingId: this.providerToken!,
            gaOptions: {
                cookieFlags: "SameSite=None; Secure"
            }
        }]);

        if (this.user) {
            this.trackLogin();
        }
    }

    protected trackPageViewInternal() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    protected trackEventInternal(_key: string, data: any) {
        ReactGA.event(data);
    }

    protected shutdownInternal() {
    }

    trackLogin() {
        ReactGA.event({
            category: 'login',
            action: 'is_logged_in',
        });
    }
}