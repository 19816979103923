import { FREE_PLAN_ITEM_LIMIT } from "authory-api-types/dist/types";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { VerticalSpacing } from "../VerticalSpacing";
import { MarketingUL } from "../ReusableStyledComponents/MarketingUl";
import { RightAlignPanel } from "../ReusableStyledComponents/RightAlignPanel";
import { V3Button } from "../Button";
import { V3Loader } from "../Loader";
import { V3ButtonColor } from "../Button/V3Button.types";
import { useState } from "react";
import { SignupParagraph } from "../../layout/SignupLayout/SignupLayout.styles";

interface FreePlanLimitExplanationProps {
    isAboveFreePlanLimit: boolean,
    articleCount: string,
    onGoBack: () => void,
    onAbovePlanConfirm: () => void,
    onBellowPlanConfirm: () => void,
    isSignup?: boolean,
}

export const FreePlanLimitExplanation = ({ isAboveFreePlanLimit, articleCount, onGoBack, onAbovePlanConfirm, onBellowPlanConfirm, isSignup = false }: FreePlanLimitExplanationProps) => {
    const [submitting, setSubmitting] = useState(false);

    const CopyToUse = isSignup ? SignupParagraph : V3CaptionGrey35;

    return <>
        <VerticalSpacing bottom={18}>
            <CopyToUse>
                The Free Plan has a limit of {FREE_PLAN_ITEM_LIMIT} content items. If you continue, please be aware of the following:
            </CopyToUse>
        </VerticalSpacing>
        <VerticalSpacing bottom={48}>
            <MarketingUL>
                {
                    !isAboveFreePlanLimit ? <>
                        <li>
                            <VerticalSpacing bottom={5}>
                                <CopyToUse>
                                    Once you've reached {FREE_PLAN_ITEM_LIMIT} items, you won't be able to add more items unless you upgrade to our Standard or Professional plan.
                                </CopyToUse>
                            </VerticalSpacing>
                        </li>
                        <li>
                            <CopyToUse>
                                The automated finding and importing of your new content won't work anymore.
                            </CopyToUse>
                        </li>
                    </>
                        : <>
                            <li>
                                <VerticalSpacing bottom={5}>
                                    <CopyToUse>
                                        You currently have {articleCount} content items in your Authory account.
                                    </CopyToUse>
                                </VerticalSpacing>
                            </li>
                            <li>
                                <CopyToUse>
                                    If you choose the Free plan, you can select up {FREE_PLAN_ITEM_LIMIT} out of those {articleCount} content items to remain in your account. The remaining ones will be deleted.
                                </CopyToUse>
                            </li>
                        </>
                }
            </MarketingUL>
        </VerticalSpacing>
        <RightAlignPanel>
            <div>
                <V3Button
                    text={isAboveFreePlanLimit ? "Go back and keep all content items" : "Go back"}
                    onClick={onGoBack}
                    disabled={submitting}
                />
            </div>
            <div>
                {
                    submitting ? <V3Loader height={42} /> : <V3Button
                        text={isAboveFreePlanLimit ? `Continue with Free and select ${FREE_PLAN_ITEM_LIMIT} items to keep` : "Continue with the Free plan"}
                        color={V3ButtonColor.secondary}
                        onClick={() => {
                            if (isAboveFreePlanLimit) {
                                onAbovePlanConfirm();
                            } else {
                                setSubmitting(true);

                                try {
                                    onBellowPlanConfirm();
                                } catch {
                                    setSubmitting(false);
                                }
                            }
                        }}
                    />
                }
            </div>
        </RightAlignPanel>
    </>
}