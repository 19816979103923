import React from "react";
import * as Sentry from '@sentry/nextjs'
import Router from 'next/router'
import { Generic500 } from "../../pagelayouts/generic-error-page";

export class ErrorBoundary extends React.Component<React.PropsWithChildren> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
        this.onRouteChange = this.onRouteChange.bind(this);
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        Sentry.captureException({ error, errorInfo });
    }

    componentDidMount() {
        Router.events.on('routeChangeComplete', this.onRouteChange);
    }

    componentWillUnmount() {
        Router.events.off('routeChangeComplete', this.onRouteChange);
    }

    onRouteChange() {
        this.setState({ hasError: false });
    }

    render() {
        //@ts-ignore
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Generic500 />
        }

        return this.props.children;
    }
}