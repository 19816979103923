import styled from "styled-components";
import { V3Body, V3DashMixin } from "../Typography";

export const V3LinksPanelWrapper = styled.div`
    padding: 0 18px;
`;

export const LinksPanelLinkWrapper = styled.div<{ $active?: boolean, $dash?: boolean, $baseColor?: string, $disableIconHover?: boolean, $disabled?: boolean }>`
    display: flex;
    align-items: center;
    ${V3DashMixin};

    &:hover {
        cursor: ${({ $disabled }) => {
        if (!$disabled) return "pointer";
    }};

        > div { 
            ${V3Body} {
                color: ${({ theme, $baseColor, $disabled }) => {
                    if ($disabled) return theme.v3.colors.grey70;
                    return $baseColor ? $baseColor : theme.v3.colors.blue;
                }};
            }

            svg {
                path, rect {
                    stroke: ${({ theme, $baseColor, $disableIconHover, $disabled }) => {
                        if ($disabled) return theme.v3.colors.grey70;
                        if ($disableIconHover) return;
                        return $baseColor ? $baseColor : theme.v3.colors.blue;
                    }};
                }
            }
        }
    }

    > div { 
        display: inline-flex;

        &:first-child {
            margin-right: 14px;
        }

        ${V3Body} {
            color: ${({ $active, theme, $baseColor, $disabled }) => {
                if ($disabled) return theme.v3.colors.grey70;
                return $active ? theme.v3.colors.blue : $baseColor ? $baseColor : null;
            }};
        }

        svg {
            path, rect {
                stroke: ${({ $active, theme, $baseColor, $disableIconHover, $disabled }) => {
                    if ($disabled) return theme.v3.colors.grey70;
                    if ($disableIconHover) return;
                    return $active ? theme.v3.colors.blue : $baseColor ? $baseColor : null;
                }};
            }
        }
    }
`;


export const V3LinksTooltipPos = styled.span`
    position: relative;
    top: 3px;
    right: -6px;
    display: inline-block;
`;