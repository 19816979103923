import { V3Body, V3Caption } from "../Typography";
import { AditionalInformationOffset, InptWrapper, InputOptionH5, OIconPos, V3CbInp, V3CbLb, V3RadioBtn, V3RadioBtnLb } from "./V3OptionInput.styles";

interface V3OptionInput extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string,
    aditionalInformation?: string,
    type?: string,
    icon?: JSX.Element
}

const SharedInputOption = ({ id, label, aditionalInformation, type, disabled, icon, ...rest }: V3OptionInput) => {

    const hasAdditionalInformation = !!aditionalInformation?.length;
    const isRadio = type === "radio";
    const InpElem = isRadio ? V3RadioBtn : V3CbInp;
    const LbElem = isRadio ? V3RadioBtnLb : V3CbLb;
    const hasIcon = !!icon;

    return <>
        <InptWrapper>
            <InpElem
                type={type}
                id={id}
                disabled={disabled}
                {...rest}
            />
            <LbElem htmlFor={id} $hasIcon={hasIcon}>
                {
                    !hasAdditionalInformation || hasIcon ? <V3Body as="span" style={{ position: "relative", top: "1px" }}>{label}</V3Body>
                        : <InputOptionH5 as="span"><strong>{label}</strong></InputOptionH5>
                }
            </LbElem>
            <OIconPos>
                {hasIcon && icon}
            </OIconPos>
        </InptWrapper>
        {
            !!aditionalInformation && <AditionalInformationOffset isDisabled={disabled}>
                <V3Caption as="label" htmlFor={id} >{aditionalInformation}</V3Caption>
            </AditionalInformationOffset>
        }
    </>
}

export const V3RadioButton = ({ ...rest }: V3OptionInput) => <SharedInputOption {...rest} type="radio" />

export const V3CheckBox = ({ ...rest }: V3OptionInput) => <SharedInputOption {...rest} type="checkbox" />