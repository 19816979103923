// Root properties belong to the main theme and "site" properties are exclusively used in the public static pages
export const theme = {
    colors: {
        dodgerBlue: "#2E77FF",
        dodgerBlue1: "#5892FF",
        dodgerBlue2: "rgba(46, 119, 255, 0.6)",
        dodgerBlue3: "#ABC9FF",
        blackPearl: "#08162B",
        blackPearl1: "#394555",
        blackPearl2: "#6B7380",
        blackPearl3: "#9CA2AA",
        blackPearl4: "#CED0D5",
        blackPearl5: "#F5F6F7",
        persimmon: "#FF6247",
        bahia: "#93CC10",
        brightSun: "#FFDD47",
        chathamsBlue: "#183D82"
    },
    typography: {
        type: {
            assistant: "'Assistant', sans-serif",
            lora: "'Lora', serif",
            inter: "'Inter', sans-serif;",
        },
        weight: {
            light: '300',
            regular: '400',
            medium: '500',
            semibold: '600',
            bold: '700',
        },
        size: {
            s1: 38,
            s2: 32,
            s3: 28,
            s4: 24,
            s5: 18,
            s6: 16,
            s7: 14,
        },
        lineHeight: {
            l1: 48,
            l2: 42,
            l3: 36,
            l4: 34,
            l5: 30,
            l6: 28,
            l7: 24,
            l8: 22,
            l9: 20,
            l10: 16,
            l11: 14,
        },
        letterSpacing: {
            ls1: 1,
            ls2: 0.5,
        }
    },
    breakpoints: {
        desktop: 922,
        tablet: 768,
        phone: 576,
    },
    marketing: {
        colors: {
            dodgerBlue100: "#2E77FF",
            dodgerBlue90: "#5892FF",
            dodgerBlue80: "#82AEFF",
            dodgerBlue70: "#ACC9FF",
            dodgerBlue60: "#D2E2FF",
            dodgerBlue50: "#E9F1FF",
            dodgerBlue40: "#F1F6FF ",
            blackPearl100: "#08162B",
            blackPearl90: "#394555",
            blackPearl80: "#6B7380",
            blackPearl70: "#9CA1AA",
            blackPearl60: "#CED1D5",
            blackPearl50: "#E3E7EB",
            blackPearl40: "#EFF4FA",
            blackPearl30: "#F7FAFD",
            chathamsBlue: "#183D82",
            coral100: "#D4682B",
            coral90: "#FF9051",
            coral80: "#FFA858",
            coral70: "#FFBF73",
            coral60: "#FFD08A",
            coral50: "#FFE2B0",
            UISuccess: "#17D4BE",
            UIError: "#EF4F39",
            UIAlert: "#F4C347"
        },
        typography: {
            size: {
                s1: 70,
                s2: 48,
                s3: 44,
                s4: 32,
                s5: 26,
                s6: 24,
                s7: 21,
                s8: 18,
                s9: 17,
                s10: 16,
                s11: 14,
            },
            lineHeight: {
                l1: 70,
                l2: 57,
                l3: 48,
                l4: 42,
                l5: 38,
                l6: 34,
                l7: 32,
                l8: 30,
                l9: 27,
                l10: 24,
                l11: 20,
            }
        }
    },
    v3: {
        colors: {
            ink: "#17191C",
            grey35: "#505662",
            grey48: "#6E7687",
            grey70: "#ABB0BA",
            grey88: "#DDDFE3",
            grey96: "#F4F4F6",
            white: "#ffffff",
            blue: "#2E77FF",
            darkBlue: "#0050EE",
            midBlue: "#7FA7F6",
            lightBlue: "#D5E4FF",
            red: "#D60024",
            darkRed: "#B42318",
            midRed: "#FECDCA",
            lightRed: "#FEE4E2",
            yellow: "#FFBE00",
            midYellow: "#FFF0C5",
            lightYellow: "#FFF9E9",
            green: "#8BD100",
            darkgreen: "#71A112",
            midGreen: "#DCFD9B",
            lightGreen: "#EDFDCE"
        },
        spacings: {
            spacing1: 4,
            spacing2: 6,
            spacing3: 12,
            spacing4: 16,
            spacing5: 18,
            spacing6: 24,
            spacing7: 30,
            spacing8: 36,
            spacing9: 42,
            spacing10: 48
        },
        layout: {
            sidebarLayout: {
                mobile: 880
            },
        }
    }
}

// Making use of Typescript’s type inference for our theme object to do it for us
declare module 'styled-components' {
    type Theme = typeof theme;
    export interface DefaultTheme extends Theme { }
}

const customMediaQuery = (maxWidth: number) =>
    `@media (min-width: ${maxWidth}px)`;

export const media = {
    custom: customMediaQuery,
    desktop: customMediaQuery(theme.breakpoints.desktop),
    tablet: customMediaQuery(theme.breakpoints.tablet),
    phone: customMediaQuery(theme.breakpoints.phone),
};