import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { ArticleFilterQueryParams } from "authory-api-types/dist/types";
import { getUserContent } from "../../api/content";
import { getAuthorContentQuerie } from "../../types/queries";
import { getSignupUserContent } from "../../api/signup";

export const getContentInfiniteQuerie = (token: string | null, userSlug: string | undefined, filters: ArticleFilterQueryParams, customEnabledCondition = true, isReactivation = false) => {
    const contentAnalyticsQuerie = getAuthorContentQuerie(
        token,
        userSlug,
        filters
    );

    return useInfiniteQuery({
        queryKey: contentAnalyticsQuerie,
        queryFn: ({ pageParam, queryKey }) => {
            const newParams = Object.assign({}, queryKey[3]);

            // If page is not a valid number we dont query anything
            if (!pageParam) {
                newParams.skip = 0;
                newParams.take = 0;
            }

            if (newParams.take && pageParam) newParams.skip = newParams.take * (pageParam - 1);
            return !isReactivation ? getUserContent(token, userSlug, newParams) : getSignupUserContent(token, userSlug, newParams);
        },
        initialPageParam: 1,
        enabled: userSlug !== undefined && customEnabledCondition,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastGroup, allGroups) => {
            const fetched = allGroups.reduce((acc, curr) => curr.articles.length + acc, 0);
            const hasPinned = allGroups.reduce((acc, curr) => acc || !!curr.pinned, false);
            let total = hasPinned ? lastGroup.filteredCount - 1 : lastGroup.filteredCount;

            return total > fetched ? allGroups.length + 1 : null;
        },
    });
}