import { ArticlePreviewResponse } from "authory-api-types/dist/types"
import { getThumbnaiPlaceholderIcon } from "../ContentList/ContentList.utils"
import { ContentListImageContainer, ContentListImage, CLPlaceholderWrapper } from "./ItemImage.styles"

interface ItemImageProps {
    item: ArticlePreviewResponse,
}

export const ItemImage = ({ item }: ItemImageProps) => {
    return <ContentListImageContainer $useBorder={!item.thumbnailImage}>
        <ContentListImage src={item.thumbnailImage || undefined} />
        {!item.thumbnailImage && <CLPlaceholderWrapper>
            {getThumbnaiPlaceholderIcon(item.type)}
        </CLPlaceholderWrapper>}
    </ContentListImageContainer>
}