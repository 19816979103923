import styled from "styled-components";
import { media } from "../../theme/theme";

export const H1 = styled.div<{ semibold?: boolean }>`
    font-family: ${({ theme }) => theme ? `${theme.typography.type.assistant}` : null};
    font-weight: ${({ theme, semibold }) => semibold ? theme.typography.weight.semibold : theme.typography.weight.light};
    font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s3}px` : null};
    line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l3}px` : null};
    color:  ${({ theme }) => theme ? `${theme.marketing.colors.blackPearl100}` : null};
    -webkit-font-smoothing: antialiased;

    ${media.tablet} {
        font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s1}px` : null};
        line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l1}px` : null};
    }

    strong {
        font-weight: ${({ theme }) => theme ? theme.typography.weight.semibold : null};
    }
`;

export const H2 = styled.div<{ semibold?: boolean }>`
    font-family: ${({ theme }) => theme ? `${theme.typography.type.assistant}` : null};
    font-weight: ${({ theme, semibold }) => semibold ? theme.typography.weight.semibold : theme.typography.weight.light};
    font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s4}px` : null};
    line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l5}px` : null};
    color:  ${({ theme }) => theme ? `${theme.marketing.colors.blackPearl100}` : null};
    -webkit-font-smoothing: antialiased;

    ${media.tablet} {
        font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s2}px` : null};
        line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l2}px` : null};
    }

    strong {
        font-weight: ${({ theme }) => theme ? theme.typography.weight.semibold : null};
    }
`;

export const H3 = styled.div<{ semibold?: boolean }>`
    font-family: ${({ theme }) => theme ? `${theme.typography.type.assistant}` : null};
    font-weight: ${({ theme, semibold }) => semibold ? theme.typography.weight.semibold : theme.typography.weight.light};
    font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s5}px` : null};
    line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l7}px` : null};
    color:  ${({ theme }) => theme ? `${theme.marketing.colors.blackPearl100}` : null};
    -webkit-font-smoothing: antialiased;

    ${media.tablet} {
        font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s4}px` : null};
        line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l4}px` : null};
    }

    strong {
        font-weight: ${({ theme }) => theme ? theme.typography.weight.semibold : null};
    }
`;

export const H4 = styled.div<{ semibold?: boolean }>`
    font-family: ${({ theme }) => theme ? `${theme.typography.type.assistant}` : null};
    font-weight: ${({ theme, semibold }) => semibold ? theme.typography.weight.semibold : theme.typography.weight.light};
    font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s7}px` : null};
    line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l9}px` : null};
    color:  ${({ theme }) => theme ? `${theme.marketing.colors.blackPearl100}` : null};
    -webkit-font-smoothing: antialiased;

    ${media.tablet} {
        font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s6}px` : null};
        line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l6}px` : null};
    }

    strong {
        font-weight: ${({ theme }) => theme ? theme.typography.weight.semibold : null};
    }
`;

export const Body = styled.div<{ bold?: boolean }>`
    font-family: ${({ theme }) => theme ? `${theme.typography.type.lora}` : null};
    font-weight: ${({ theme, bold }) => bold ? theme.typography.weight.bold : theme.typography.weight.regular};
    font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s9}px` : null};
    line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l8}px` : null};
    color:  ${({ theme }) => theme ? `${theme.marketing.colors.blackPearl100}` : null};
    -webkit-font-smoothing: antialiased;

    ${media.tablet} {
        font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s8}px` : null};
        line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l8}px` : null};
    }

    strong {
        font-weight: ${({ theme }) => theme ? theme.typography.weight.semibold : null};
    }

    i {
        font-style: italic;
    }
`;

export const MediumText = styled.div<{ bold?: boolean }>`
    font-family: ${({ theme }) => theme ? `${theme.typography.type.assistant}` : null};
    font-weight: ${({ theme, bold }) => bold ? theme.typography.weight.bold : theme.typography.weight.semibold};
    font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s10}px` : null};
    line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l10}px` : null};
    color:  ${({ theme }) => theme ? `${theme.marketing.colors.blackPearl100}` : null};
    -webkit-font-smoothing: antialiased;

    ${media.tablet} {
        font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s8}px` : null};
        line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l11}px` : null};
    }

    strong {
        font-weight: ${({ theme }) => theme ? theme.typography.weight.bold : null};
    }
`;

export const SmallText = styled.div<{ bold?: boolean }>`
    font-family: ${({ theme }) => theme ? `${theme.typography.type.assistant}` : null};
    font-weight: ${({ theme, bold }) => bold ? theme.typography.weight.bold : theme.typography.weight.light};
    font-size: ${({ theme }) => theme ? `${theme.marketing.typography.size.s11}px` : null};
    line-height: ${({ theme }) => theme ? `${theme.marketing.typography.lineHeight.l11}px` : null};
    color:  ${({ theme }) => theme ? `${theme.marketing.colors.blackPearl100}` : null};
    -webkit-font-smoothing: antialiased;

    strong {
        font-weight: ${({ theme }) => theme ? theme.typography.weight.semibold : null};
    }
`;