import React from "react"
import { PanelItemContent, PanelItemPrimaryText, PanelItemSecondaryText, NavigateArrowSVG } from "./MarketingUseCasePanel.styles";
import { OptionalNextLink } from "../ReusableStyledComponents/OptionalNextLink";

type MarketingUseCasePanelProps = {
    icon: JSX.Element,
    primaryText: string,
    secondaryText: string,
    href: string,
    disabled?: boolean,
    secondaryTextMaxWidth?: number,
    useNextRouter?: boolean
}

export const MarketingUseCasePanel = ({ icon, href, primaryText, secondaryText, disabled = false, secondaryTextMaxWidth, useNextRouter = true }: MarketingUseCasePanelProps) => {
    return <OptionalNextLink href={href} useNextRouter={useNextRouter}>
        <PanelItemContent disabled={disabled}>
            <div>
                {icon}
            </div>
            <div>
                <PanelItemPrimaryText disabled={disabled}>
                    <div>
                        {primaryText}
                    </div>
                    <div>
                        <NavigateArrowSVG width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 2l5.701 5.326a.91.91 0 010 1.348L5 14" stroke="#2E77FF" />
                        </NavigateArrowSVG>
                    </div>
                </PanelItemPrimaryText>
                <PanelItemSecondaryText secondaryTextMaxWidth={secondaryTextMaxWidth}>
                    {secondaryText}
                </PanelItemSecondaryText>
            </div>
        </PanelItemContent>
    </OptionalNextLink>
}