import styled from "styled-components";

export const CardsCVCDateWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    > div {
        width: calc(50% - 8px);
    }
`;

export const ModalPaymentOptions = styled.div`
    display: flex;

    > div {
        display: inline-flex;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }
`;

export const PaymentModalPlanDetailsWithCTA = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const PaymentModalPlanDetailWrapper = styled.div`
    display: inline-block;
`;