import styled from "styled-components";
import { V3DropdownCTAColor } from ".";
import { media, theme } from "../../theme/theme";
import { V3Body } from "../Typography";

export const ColOptWrapper = styled.div<{ $variant: V3DropdownCTAColor, $active: boolean, $skipSVGActiveEffect?: boolean, $justifiedCentered?: boolean, $disabled: boolean }>`
    display: flex;
    align-items: center;
    padding: 12px 0;
    text-decoration: none;
    justify-content: ${({ $justifiedCentered }) => $justifiedCentered ? "center" : undefined};

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        padding: 8px 12px;
    }

    ${V3Body} {
        color: ${({ $variant, theme, $active, $disabled }) => {
        if ($disabled) return theme.v3.colors.grey70;
        if ($variant === V3DropdownCTAColor.red) return theme.v3.colors.red;
        if ($active && $variant === V3DropdownCTAColor.color) return theme.v3.colors.blue;
    }};
    }

    
    
    > div {
        &:first-child {
            display: inline-flex;

            svg path,
            svg rect {
                stroke: ${({ $variant, theme, $active, $skipSVGActiveEffect, $disabled }) => {
        if ($skipSVGActiveEffect) return;
        if ($disabled) return theme.v3.colors.grey88;
        if ($variant === V3DropdownCTAColor.red) return theme.v3.colors.red;
        if ($active && $variant === V3DropdownCTAColor.color) return theme.v3.colors.blue;
    }};
            }
        }

        &:nth-child(2) {
            max-width: calc(100% - 24px);
            margin-left: 10px;

            ${V3Body} {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &:hover {
        text-decoration: none;
        cursor: ${({ theme, $disabled }) => theme && !$disabled ? "pointer" : "default"};
       
        ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
            background: ${({ theme, $disabled }) => theme && !$disabled ? theme.v3.colors.grey96 : null};
            border-radius: 6px;
        }
    }
`;

export const DropdownCtaWithTooltip = styled.div`
    display: flex;
    align-items: center;

    > div {
        &:nth-child(2) {
            margin-left: 10px;
            display: inline-flex;
        }
    }
`;