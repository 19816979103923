import { useFormik } from "formik"
import dynamic from "next/dynamic"
import router from "next/router"
import { DropdownOptionCTA } from "../../../components/DropdownOptionCTA"
import { V3IconCursor, V3IconPreview } from "../../../components/Icons"
import { InfinitePaginationFooter } from "../../../components/InfinitePaginationFooter"
import { V3Loader } from "../../../components/Loader"
import { V3ContentLargeWrapper } from "../../../components/ReusableStyledComponents/V3ContentLargeWrapper"
import { SidebarCustomizedSelect } from "../../../components/SidebarCustomizedSelect"
import { V3DashboardCard } from "../../../components/V3DashboardCard"
import { V3NoSearchResults } from "../../../components/V3EmptyStateScreen"
import { V3NewsletterLinksPanel } from "../../../components/V3LinksPanel"
import { V3SimpleNavbar } from "../../../components/V3SimpleNavbar"
import { VerticalSpacing } from "../../../components/VerticalSpacing"
import { SidebarLayout } from "../../../layout/SideBarLayout"
import { NewsletterAnalyticsType } from "../../../mocks/newsletter-analytics"
import { theme } from "../../../theme/theme"
import { APPLICATION_ROUTES } from "../../../types/routes"
import { V3NOverviewWrapper } from "../V3NewsletterPageLayout.styles"
import { formattedNewsletterSortOptions, formattedNewsletterTimeFilterOptions } from "./V3NewsletterReportPageLayout.utils"

const V3TableNewsletterReport = dynamic(
    () => import('../../../components/V3Table/V3TableNewsletterReport'),
    { ssr: false }
);

export interface SearchReportsFormik {
    from: string,
    orderBy: string,
}

export const DEFAULT_NEWSLETTER_REPORT_SEARCH_PARAMS = {
    FROM: formattedNewsletterTimeFilterOptions[0].value,
    ORDERBY: formattedNewsletterSortOptions[0].value,
}

interface V3NewsletterReportPageLayoutProps {
    analyticsData?: NewsletterAnalyticsType[],
    analyticsDataLoading: boolean,
    analyticsLoadMore: () => void,
    analyticsCanFetchMore: boolean,
    searchArgs: any,
    onSearchFormSubmit: (e: SearchReportsFormik) => void,
}

export const V3NewsletterReportPageLayout = ({ analyticsData, analyticsDataLoading, analyticsLoadMore, analyticsCanFetchMore, searchArgs, onSearchFormSubmit }: V3NewsletterReportPageLayoutProps) => {
    const formik = useFormik<SearchReportsFormik>({
        initialValues: {
            from: searchArgs?.from || DEFAULT_NEWSLETTER_REPORT_SEARCH_PARAMS.FROM,
            orderBy: searchArgs?.orderBy || DEFAULT_NEWSLETTER_REPORT_SEARCH_PARAMS.ORDERBY
        },
        enableReinitialize: true,
        onSubmit: onSearchFormSubmit
    });

    return <SidebarLayout
        footerVisible={false}
        disableFadeIn
        title="Reporting"
        leftPanel={
            <V3NewsletterLinksPanel />
        }
        topNavBarContent={<V3SimpleNavbar
            title="Newsletter"
            rightSideContent={<SidebarCustomizedSelect
                optionTitle={<><strong> {formattedNewsletterTimeFilterOptions.filter(opt => opt.value === formik.values.from)[0].text}</strong></>}
                mobileTitle="Pick a range"
                renderPopperElement={(_active, setShowPopper) => <div style={{ minWidth: 130 }}>
                    {
                        formattedNewsletterTimeFilterOptions.map(opt => <DropdownOptionCTA
                            key={opt.value}
                            label={opt.text}
                            onClick={() => {
                                formik.setFieldValue("from", opt.value);
                                formik.submitForm();
                                setShowPopper(false)
                            }}
                            active={formik.values.orderBy === opt.value}
                        />)
                    }
                </div>}
            />}
        />}
        rightPanel={<V3ContentLargeWrapper>
            <V3SimpleNavbar
                title="Reporting"
                withTitle
                subtitle={!!analyticsData?.length ? `${analyticsData[0].count} issues` : ""}
            />
            {
                !!analyticsData?.length && <>
                    <V3NOverviewWrapper>
                        <div>
                            <V3DashboardCard
                                noShadow
                                icon={<V3IconPreview />}
                                background={theme.v3.colors.grey96}
                                caption={"Opening rate"}
                                headline={`${Math.round((analyticsData[0].overall.openedCount / analyticsData[0].overall.deliveredCount) * 100) || 0}%`}
                            />
                        </div>
                        <div>
                            <V3DashboardCard
                                noShadow
                                icon={<V3IconCursor />}
                                background={theme.v3.colors.grey96}
                                caption={"Click rate"}
                                headline={`${Math.round((analyticsData[0].overall.clickedCount / analyticsData[0].overall.deliveredCount) * 100) || 0}%`}
                            />
                        </div>
                    </V3NOverviewWrapper>
                    <VerticalSpacing top={54}>
                        <V3SimpleNavbar
                            title="Previous issues"
                            withTitle
                            rightSideContent={<>
                                <SidebarCustomizedSelect
                                    optionTitle={<><strong>Sort by</strong> {formattedNewsletterSortOptions.filter(opt => opt.value === formik.values.orderBy)[0].text}</>}
                                    mobileTitle="Sort by"
                                    renderPopperElement={(_active, setShowPopper) => <div style={{ minWidth: 130 }}>
                                        {
                                            formattedNewsletterSortOptions.map(opt => <DropdownOptionCTA
                                                key={opt.value}
                                                label={opt.text}
                                                onClick={() => {
                                                    formik.setFieldValue("orderBy", opt.value);
                                                    formik.submitForm();
                                                    setShowPopper(false)
                                                }}
                                                active={formik.values.orderBy === opt.value}
                                            />)
                                        }
                                    </div>}
                                />
                            </>}
                        />
                    </VerticalSpacing>
                </>
            }
            {!!analyticsData?.length && <>
                <V3TableNewsletterReport
                    analyticsData={analyticsData}
                />
                <InfinitePaginationFooter
                    contentLoading={analyticsDataLoading}
                    numPagesLoaded={analyticsData.length}
                    contentCanFetchmore={analyticsCanFetchMore}
                    loadMore={analyticsLoadMore}
                />
            </>}
            {
                analyticsData?.length && !analyticsData[0].count && <VerticalSpacing top={20}>
                    <V3NoSearchResults onClearHandler={() => {
                        router.push(APPLICATION_ROUTES.NEWSLETTER_REPORT)
                    }} />
                </VerticalSpacing>
            }
            {analyticsDataLoading && <VerticalSpacing top={20} bottom={20}>
                <V3Loader />
            </VerticalSpacing>}
        </V3ContentLargeWrapper>}
    />
}