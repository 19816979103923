import styled from "styled-components";

export const UnstyledLink = styled.a<{ textDecoration?: string }>`
    text-decoration: ${({ textDecoration }) => textDecoration ? textDecoration : "none"};
    color: inherit;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    
    &:visited {
        color: inherit;
    }
`;

export const UnstyledInlineLink = styled(UnstyledLink)`
    color: ${({ theme }) => theme.v3.colors.blue};
    cursor: pointer;
    -webkit-font-smoothing: antialiased;

    &:visited {
        color: ${({ theme }) => theme.v3.colors.blue};
    }
`;