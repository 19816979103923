import { ContentType } from "authory-api-types/dist/enums";
import { V3COMBINED_POST_TYPE, V3COMBINED_WEBSITE_EMAIL_TYPE } from "./getFormattedSourceTypes";
import { TypeCounts } from "authory-api-types/dist/types";
import { cloneDeep } from "lodash";

const formattedSourceTypes = [
    { value: "", text: "All Content Types" },
    { value: ContentType.Article, text: "Articles" },
    { value: ContentType.PodcastItem, text: "Podcasts" },
    { value: ContentType.Video, text: "Videos" },
    { value: V3COMBINED_POST_TYPE, text: "Posts" },
    { value: V3COMBINED_WEBSITE_EMAIL_TYPE, text: "Websites/Emails" },
    { value: ContentType.PdfFile, text: "PDFs" },
];

export const getSearchFormattedTypesOptions = (typeCounts: TypeCounts) => {
    return cloneDeep(formattedSourceTypes).filter(sourceType => {
        const types = sourceType.value.split("$");

        if (sourceType.value === "") return true;

        for (const type of types) {
            const userTypeValue = typeCounts[type as ContentType];
            if (userTypeValue && userTypeValue > 0) return true;
        }

        return false;
    });
}