import { UserArticles } from "authory-api-types/dist/endpoints";
import { ArticleSearchResultResponse } from "authory-api-types/dist/types";
import { ArticleFilterQueryParams } from "authory-api-types/dist/types";
import axios from "axios";
import { API_URL } from ".";
import { getHeaders } from "./get-headers";

export const getUserContent = async (token: string | null, userSlug: string | undefined, params: ArticleFilterQueryParams) => {
    const { data } = await axios.get<ArticleSearchResultResponse>(
        `${API_URL}${UserArticles.replace(":userId?", userSlug || "")}`,
        {
            params,
            ...getHeaders(token || "")
        }
    );

    return data;
}