const translateValuetoTest = (val: string) => {
    if (val === "1") return "Control";
    return `Variation-${parseInt(val) - 1}`;
}

export const getAbTestCookies = (cookies: { [key: string]: string }, isBackendEvent = false) => {
    const payload: { [key: string]: string } = {}

    for (const [key, value] of Object.entries(cookies)) {
        if (key.startsWith("_vis_opt_exp_") && key.endsWith("_combi")) {
            // Here the Control has a value of 1, Variation #1 has a value of 2, Variation #2 has a value of 3, and so on
            payload[`${!isBackendEvent ? "experiment-" : ""}Test-ID-${key.replace("_vis_opt_exp_", "").replace("_combi", "")}`] = translateValuetoTest(value);
        }
    }

    return payload;
}

const CURRENT_AB_TEST_ID = 12;

export const getIsCurrentRunningAbTest = (cookies: Record<string, string> | undefined) => {
    if (cookies) {
        for (const key of Object.keys(cookies)) {
            const splitTestName = key.split("-");
            const testId = parseInt(splitTestName[splitTestName.length - 1]);
            if (testId === CURRENT_AB_TEST_ID && cookies[key] === "Variation-1") return true;
        }
    }

    return false;
}