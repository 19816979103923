import styled from "styled-components";

export const SSCPLabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SSCPSelectDropdownTrigger = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 18px;
    height: 42px;
    left: 0px;
    background: ${({ theme }) => theme ? theme.v3.colors.grey96 : null};
    border-radius: 6px;

    > div {
        display: inline-flex;
       
        &:first-child {
            margin-right: 15px;
            max-width: calc(100%);
            overflow: hidden;

            > div {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
`;


export const SSCPOptionTitleWrapper = styled.div`
    display: flex;
    align-items: center;

    > * {
        &:nth-child(2) {
            margin-left: 7px;
        }
    }
`;