import axios, { AxiosError } from "axios";
import { AuthenticatedUserResponse, EmailPreferences, UpdateUserBodyParams, UserResponse } from "authory-api-types/dist/types";
import { DeleteUserOgImage } from "authory-api-types/dist/endpoints";
import { Token } from "../types/queries";
import { Errors } from "../types/api";
import { logAxiosError } from "../logging/logSentryError";
import {
    User as UserEndpoint,
    SignupSetByline,
    UpdateUserAvatar,
    DeleteUserAvatar,
    UpdateUser,
    UserContact,
    UserEmailpreferences,
    UpdateUserEmailpreferences,
    UserNextIssuePreviewStatic,
    UserPrivacyPolicy,
    RequestResetPassword,
    UserResetPassword,
    AttemptBusinessSignup
} from "authory-api-types/dist/endpoints";
import { API_URL } from "./index";
import { UserSignInResponse, BusinessSeatInviteInfo } from "authory-api-types/dist/types";
import { getHeaders } from "./get-headers";
import { CommonArgs } from "./collections";
import { APPLICATION_ROUTES } from "../types/routes";
import { NotificationInlineLink } from "../components/ReusableStyledComponents/NotificationInlineLink";
import { NotificationHandler } from "./error-handler";
import { ContactUserFormik } from "../components/V3ContactUser";

const formatUserEndpoint = (user?: string) => {
    if (user) {
        return UserEndpoint.replace(":userId", user);
    } else {
        return UserEndpoint.split("/:userId")[0];
    }
};

export const getAuthenticatedUser = async (token: Token) => {
    if (token) {
        const { data } = await axios.get<AuthenticatedUserResponse>(
            `${API_URL}${formatUserEndpoint()}`,
            getHeaders(token)
        );

        return data;
    } else {
        return null;
    }
};

export const getUser = async (slug: string) => {
    const { data } = await axios.get<UserResponse>(
        `${API_URL}${formatUserEndpoint(slug)}`,
    );

    return data;
}

type SetByLineArgs = {
    token: string,
    userSlug: string
    data: UpdateUserBodyParams
}

export const setByline = async ({ token, userSlug, data }: SetByLineArgs) => {
    const res = await axios.post<AuthenticatedUserResponse>(
        `${API_URL}${SignupSetByline.replace(":userId?", userSlug)}`,
        {
            ...data,
        },
        getHeaders(token)
    );

    return res.data;
};

type UploadUserAvatarType = {
    token: string,
    file: File,
    userSlug: string
}

export const uploadUserAvatar = async ({ token, file, userSlug }: UploadUserAvatarType) => {

    let formData = new FormData();
    formData.append('file', file);

    const res = await axios.post<AuthenticatedUserResponse>(
        `${API_URL}${UpdateUserAvatar.replace(":userId?", userSlug)}`,
        formData,
        {
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'multipart/form-data'
            },
        }
    );

    return res.data;
};

type DeleteUserAvatarArgs = {
    token: string,
    userSlug: string
}

export const deleteUserAvatar = async ({ token, userSlug }: DeleteUserAvatarArgs) => {
    const res = await axios.post<AuthenticatedUserResponse>(
        `${API_URL}${DeleteUserAvatar.replace(":userId?", userSlug)}`,
        {},
        {
            headers: {
                Authorization: "Bearer " + token,
            },
        }
    );

    return res.data;
};

type updateUserArgs = {
    token: string,
    userSlug: string,
    data: UpdateUserBodyParams,
    skipOptimisticUpdate?: boolean,
    target?: string,
    skipAllUpdates?: boolean,
}

export const updateUser = async ({ token, userSlug, data }: updateUserArgs) => {
    const res = await axios.post<AuthenticatedUserResponse>(
        `${API_URL}${UpdateUser.replace(":userId?", userSlug)}`,
        {
            ...data
        },
        {
            headers: {
                Authorization: "Bearer " + token,
            },
        }
    );

    return res.data;
};

type ContactUserArgs = {
    userSlug: string,
    data: ContactUserFormik
}

export const ContactUser = async ({ userSlug, data }: ContactUserArgs) => {
    const res = await axios.post<AuthenticatedUserResponse>(
        `${API_URL}${UserContact.replace(":userId", userSlug)}`,
        {
            ...data
        },
    );

    return res.data;
};

export const getEmailPreferences = async (token: string, userSlug: string) => {
    const { data } = await axios.get<EmailPreferences>(
        `${API_URL}${UserEmailpreferences.replace(":userId?", userSlug)}`,
        getHeaders(token)
    );

    return data;
};

interface saveEmailPreferencesArgs extends CommonArgs {
    data: EmailPreferences
}

export const saveEmailPreferences = async ({ token, userSlug, data }: saveEmailPreferencesArgs) => {
    const res = await axios.post<EmailPreferences>(
        `${API_URL}${UpdateUserEmailpreferences.replace(":userId?", userSlug)}`,
        {
            ...data
        },
        getHeaders(token)
    );

    return res.data;
};

export const getNextIssuePreview = async (userSlug: string) => {
    const { data } = await axios.get(
        `${API_URL}${UserNextIssuePreviewStatic.replace(":userId?", userSlug)}`
    );

    return data;
};

type GDPRSettings = {
    privacyPolicy: string,
    imprint: string
}

export const getGDPRSettings = async (token: string | null, userSlug: string) => {
    const { data } = await axios.get<GDPRSettings>(
        `${API_URL}${UserPrivacyPolicy.replace(":userId?", userSlug)}`,
        token ? getHeaders(token) : undefined
    );

    return data;
};

export const requestResetPassword = async ({ email }: { email: string }) => {
    const { data } = await axios.post(
        `${API_URL}${RequestResetPassword}`,
        { email }
    );

    return data;
}

export const V3resetPasswordErrorHandler = (toastHandler: NotificationHandler) => async (error: AxiosError) => {

    if (error?.response?.status === Errors.State) {
        toastHandler(error?.response?.data?.message);
    } if (error?.response?.status === Errors.AccessDenied) {
        toastHandler(<><span>This password reset link seems to be no longer valid. You can request a new one <NotificationInlineLink href={APPLICATION_ROUTES.LOGIN_FORGOTTEN_PASSWORD}>here</NotificationInlineLink>.</span></>);
    } else {
        toastHandler("An unexpected error occured");
        logAxiosError(error);
    }
};

type ResetPasswordPayload = {
    userSlug: string,
    password: string,
    token: string,
}

export const resetPassword = async ({ userSlug, password, token }: ResetPasswordPayload) => {
    const { data } = await axios.post<UserSignInResponse>(
        `${API_URL}${UserResetPassword.replace(":userId", userSlug)}`,
        {
            password,
            token
        }
    );

    return data;
}

type AttemptBusinessPlanPayload = {
    referral: BusinessSeatInviteInfo,
    token: string,
}

export const attemptBusinessPlan = async ({ token, referral }: AttemptBusinessPlanPayload) => {
    const { data } = await axios.post<{ success: boolean }>(
        `${API_URL}${AttemptBusinessSignup}`,
        {
            ...referral
        },
        getHeaders(token)
    );

    return data;
}

export const clearLegacyProfileOgImage = async ({ token, userSlug }: CommonArgs) => {
    const { data } = await axios.post(`${API_URL}${DeleteUserOgImage.replace(":userId?", userSlug)}`, undefined, getHeaders(token));

    return data;
}