
import { APPLICATION_ROUTES } from "../../types/routes";
import { V3IconSend, V3IconSubscribers, V3IconAnalytics, V3IconSettings } from "../Icons";
import { V3LinksPanel } from "./V3LinksPanel";

export const V3NewsletterLinksPanel = () => <V3LinksPanel
    links={[{
        icon: <V3IconSend />,
        label: "Next newsletter",
        href: APPLICATION_ROUTES.NEWSLETTER
    },
    {
        icon: <V3IconSubscribers />,
        label: "Subscribers",
        href: APPLICATION_ROUTES.NEWSLETTER_SUBSCRIBERS
    },
    {
        icon: <V3IconAnalytics width="16" height="16" />,
        label: "Reporting",
        href: APPLICATION_ROUTES.NEWSLETTER_REPORT
    },
    {
        icon: <V3IconSettings />,
        label: "Settings",
        href: APPLICATION_ROUTES.NEWSLETTER_SETTINGS
    },
    ]}
/>

