import Link from "next/link";
import { useRouter } from "next/router";
import { APPLICATION_ROUTES } from "../../types/routes";
import { V3IconHelpCircle } from "../Icons";
import { UnstyledLink } from "../ReusableStyledComponents/UnstyledLink";
import { V3Tooltip } from "../Tooltip";
import { V3Body } from "../Typography";
import { VerticalSpacing } from "../VerticalSpacing";
import { LinksPanelLinkWrapper, V3LinksPanelWrapper, V3LinksTooltipPos } from "./V3LinksPanel.styles";

export type SettingsLinkPanel = {
    icon: JSX.Element,
    label: string,
    href: string,
    disableIconHover?: boolean
    inactive?: boolean,
    proTooltip?: boolean
    proTooltipContent?: JSX.Element
};

interface V3LinksPanelProps {
    links: SettingsLinkPanel[],
}

export const V3LinksPanel: React.FC<React.PropsWithChildren<V3LinksPanelProps>> = ({ links, children }) => {
    const router = useRouter();

    return <V3LinksPanelWrapper>
        <VerticalSpacing top={30}>
            {
                links.map(({ icon, label, href, disableIconHover, inactive, proTooltip, proTooltipContent }, index) => {
                    const active = router.route === href;

                    const Elem = () => <LinksPanelLinkWrapper
                        $disabled={inactive}
                        $disableIconHover={disableIconHover}
                        $active={active}
                    >
                        <div>{icon}</div>
                        <div>
                            <V3Body><strong>{label}</strong>
                                {
                                    proTooltip && <V3LinksTooltipPos>
                                        <V3Tooltip
                                            referenceContent={<V3IconHelpCircle />}
                                            tooltipMaxWidth={270}
                                            tooltipContent={<>{proTooltipContent}&nbsp;
                                                <Link
                                                    href={`${APPLICATION_ROUTES.SETTINGS_BILLING}?update=plan`}
                                                    passHref
                                                    legacyBehavior
                                                ><UnstyledLink textDecoration="underline" >You can upgrade anytime!</UnstyledLink></Link>&nbsp;
                                            </>}
                                        />
                                    </V3LinksTooltipPos>
                                }
                            </V3Body></div>
                    </LinksPanelLinkWrapper>

                    return <VerticalSpacing bottom={26} key={index}>
                        {
                            inactive ? <Elem /> :
                                <Link href={href} passHref legacyBehavior>
                                    <UnstyledLink>
                                        <Elem />
                                    </UnstyledLink>
                                </Link>
                        }
                    </VerticalSpacing>
                })
            }
            {children}
        </VerticalSpacing>
    </V3LinksPanelWrapper>
}