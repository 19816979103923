interface V3IconHelpCircleProps extends React.SVGProps<SVGSVGElement> {
    stroke?: string;
}

export const HELP_CIRCLE_DISPLAY_NAME = 'V3IconHelpCircle';

export const V3IconHelpCircle = ({ stroke = "#ABB0BA", strokeWidth = "1.5", ...rest }: V3IconHelpCircleProps) => <svg width="16" height="16"  {...rest} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.25 8A7.25 7.25 0 1 1 .75 8a7.25 7.25 0 0 1 14.5 0Z" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.75 6S6 3.75 8 3.75 10.25 5 10.25 6c0 .751-.423 1.503-1.27 1.83C8.465 8.029 8 8.448 8 9v.25" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.5 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
</svg>

// This displayName is important! The tooltip component uses it to determine if it will disable tooltip behaviour
// This is because the tooltip is sometimes used with dropdown etc inside.
V3IconHelpCircle.displayName = HELP_CIRCLE_DISPLAY_NAME;