import { UserAccountType, UserStatus } from "authory-api-types/dist/enums";
import { NextPageContext } from "next"
import { NextRouter } from "next/router";
import { destroyCookie } from "nookies";
import { CookieKeys } from "../types/cookie-keys";
import { APPLICATION_ROUTES } from "../types/routes";
import { AuthenticatedUser } from "../types/user";

function isBrowser() {
    return typeof window !== 'undefined';
}

export const DEFAULT_LOGGED_IN_PAGE = APPLICATION_ROUTES.PORTFOLIO_APP;
export const DEFAULT_BUSINESS_LOGGED_IN_PAGE = APPLICATION_ROUTES.CONTENT;

const handlePLGSignupRedirect = (user: AuthenticatedUser) => {
    const isActive = user?.status === UserStatus.Active;
    const isDeactivated = user?.status === UserStatus.Inactive;
    const isStandardAccount = user?.type === UserAccountType.Standard;
    const isBusinessParent = user?.type === UserAccountType.BusinessParent;

    // User is active
    if (user && isActive) return isBusinessParent ? DEFAULT_BUSINESS_LOGGED_IN_PAGE : DEFAULT_LOGGED_IN_PAGE;

    // Account reactivation
    const isDeactivatedAndStandard = isDeactivated && isStandardAccount;
    if (user && isDeactivatedAndStandard) return APPLICATION_ROUTES.SIGNUP_CHOOSE_PLAN;

    // User doesn't have last name setup
    const hasLastName = !!user?.lastName.length;
    if (user && !hasLastName) return APPLICATION_ROUTES.SIGNUP_COMPLETE_NAME;

    // Has last name setup but not active - send to animation
    if (user) return APPLICATION_ROUTES.SIGNUP_ANIMATION;

    // user doesnt exists, go to create account
    if (!user) return APPLICATION_ROUTES.SIGNUP_ACCOUNT;

    return false;
}

const handleDefaultRedirect = (user: AuthenticatedUser, ctx?: NextPageContext) => {
    // If user doesnt exists, destroy cookie token and redirect to login
    // Possible scenarios:
    // -> user endpoint failed to respond (400s/500s)
    // -> token cookies was expired or invalid
    // -> token cookie was not set
    if (!user) {
        destroyCookie(ctx, CookieKeys.auth_token);
        return APPLICATION_ROUTES.LOGIN;
    }

    // If not active and not god mode, go to signup
    const isActive = user?.status === UserStatus.Active;
    if (user && !isActive && !user.isGodMode) return APPLICATION_ROUTES.SIGNUP;

    return false;
}

const handleBusinessParentDissallowedRedirect = (user: AuthenticatedUser, ctx?: NextPageContext) => {
    // Default redirect checks
    const defaultRedirect = handleDefaultRedirect(user, ctx);
    if (defaultRedirect) return defaultRedirect;

    // Dont allow BusinessParent accounts
    const isBusinessParent = user?.type === UserAccountType.BusinessParent;
    if (user && isBusinessParent) return DEFAULT_BUSINESS_LOGGED_IN_PAGE;

    return false;
}

const handleBusinessParentOnlyRedirect = (user: AuthenticatedUser, ctx?: NextPageContext) => {
    // Default redirect checks
    const defaultRedirect = handleDefaultRedirect(user, ctx);
    if (defaultRedirect) return defaultRedirect;

    // Only business allow BusinessParent
    const isBusinessParent = user?.type === UserAccountType.BusinessParent;
    if (user && !isBusinessParent) return DEFAULT_LOGGED_IN_PAGE;

    return false;
}

const handleStandardUserOnlyRedirect = (user: AuthenticatedUser, ctx?: NextPageContext) => {
    // Default redirect checks
    const defaultRedirect = handleDefaultRedirect(user, ctx);
    if (defaultRedirect) return defaultRedirect;

    // Only allow Standard users
    const isStandard = user?.type === UserAccountType.Standard;
    if (user && !isStandard) return DEFAULT_LOGGED_IN_PAGE;

    return false;
}

const handleLoginRedirect = (user: AuthenticatedUser) => {
    const isActive = user?.status === UserStatus.Active;
    const isBusinessParent = user?.type === UserAccountType.BusinessParent;

    // User is active or is god mode, send to profile
    if (user && (isActive || user.isGodMode)) return isBusinessParent ? DEFAULT_BUSINESS_LOGGED_IN_PAGE : DEFAULT_LOGGED_IN_PAGE;

    // User not active, go finish signup
    if (user && !isActive) return APPLICATION_ROUTES.SIGNUP;

    return false;
}

const handleDeactivatedRoute = (user: AuthenticatedUser, ctx?: NextPageContext) => {
    // Default redirect checks
    const defaultRedirect = handleDefaultRedirect(user, ctx);
    if (defaultRedirect) return defaultRedirect;

    // If user is not deactivated, we dont allow it to go to deactivated page
    const isDeactivated = user?.status === UserStatus.Inactive;
    if (user && !isDeactivated) return DEFAULT_LOGGED_IN_PAGE;

    return false;
}

// This function is responsible for handling all the logic that determines if a user has access to a certain route 
// or if it should be redirected.
// The permissions system is quite complex as it depends on a mix of user status and account type
// The switch on the main function checks for what route a user is trying to navigate to and call a function that determines
// is the navigation is allowed (return false) or a string for what the user should be redirectd to
export const userRedirects = (user: AuthenticatedUser, ctx?: NextPageContext, router?: NextRouter) => {
    let redirect: string | boolean = false;
    const route = ctx?.pathname || router?.pathname;

    // You can add redirect logic per route, or just leave the default fallback at the end
    // important thing is to assign the route to be redirected to to the redirect var
    switch (route) {
        case APPLICATION_ROUTES.SIGNUP:
        case APPLICATION_ROUTES.SIGNUP_ACCOUNT:
        case APPLICATION_ROUTES.SIGNUP_COMPLETE_NAME:
        case APPLICATION_ROUTES.SIGNUP_CHOOSE_PLAN:
        case APPLICATION_ROUTES.SIGNUP_ANIMATION:
            redirect = handlePLGSignupRedirect(user);
            break;
        case APPLICATION_ROUTES.LOGIN:
        case APPLICATION_ROUTES.LOGIN_FORGOTTEN_PASSWORD:
            redirect = handleLoginRedirect(user);
            break;
        case APPLICATION_ROUTES.SETTINGS_SOURCES:
        case APPLICATION_ROUTES.NEWSLETTER:
        case APPLICATION_ROUTES.NEWSLETTER_SUBSCRIBERS:
        case APPLICATION_ROUTES.NEWSLETTER_SETTINGS:
        case APPLICATION_ROUTES.NEWSLETTER_REPORT:
        case APPLICATION_ROUTES.COLLECTIONS:
        case APPLICATION_ROUTES.SETTINGS_REFERRAL:
        case APPLICATION_ROUTES.PORTFOLIO_APP:
        case APPLICATION_ROUTES.ANALYTICS:
        case APPLICATION_ROUTES.ANALYTICS_WEB:
        case APPLICATION_ROUTES.SETTINGS_CERTIFICATES:
        case APPLICATION_ROUTES.SETTINGS_CUSTOM_DOMAINS:
        case APPLICATION_ROUTES.SETTINGS_WEBHOOKS:
            redirect = handleBusinessParentDissallowedRedirect(user, ctx);
            break;
        case APPLICATION_ROUTES.SETTINGS_BILLING:
            redirect = handleStandardUserOnlyRedirect(user, ctx);
            break;
        case APPLICATION_ROUTES.ACCOUNTS:
            redirect = handleBusinessParentOnlyRedirect(user, ctx);
            break;
        case APPLICATION_ROUTES.SIGNUP_DEACTIVATED:
            redirect = handleDeactivatedRoute(user, ctx);
            break;
        default:
            redirect = handleDefaultRedirect(user, ctx);
            break;
    }

    // If user is deactivated we always redirect to deactivated unless reactivating
    if (user?.status === UserStatus.Inactive && redirect !== APPLICATION_ROUTES.SIGNUP_CHOOSE_PLAN && !user.isGodMode) {
        redirect = APPLICATION_ROUTES.SIGNUP_DEACTIVATED;
    }

    // IMPORTANT: If we are already on the supposed path, dont do anything
    if (redirect && redirect === route) return;

    // If a user is redirected to login for authentication
    // we append a query param so the client after login sends him to where he wanted to go
    if (redirect === APPLICATION_ROUTES.LOGIN as string && typeof route === "string") {
        const redirectDestination = ctx?.asPath || router?.asPath;
        if (redirectDestination) redirect += `?redirect=${encodeURI(redirectDestination)}`;
    }

    // Isomorphic redirect logic, will either redirect via http 302 (server) or browser next router push
    if (redirect) {
        if (!isBrowser() && ctx?.res) {
            ctx.res.writeHead(302, { Location: redirect });
            ctx.res.end();
        }

        if (isBrowser() && router) {
            router.push(redirect);
            return true;
        }
    }
}