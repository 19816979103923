import Link from "next/link";
import React from "react";
import { getAbsoluteUrl } from "../../utils/domainRouting";
import { UnstyledLink } from "./UnstyledLink";

interface OptionalNextLinkProps {
    useNextRouter?: boolean,
    href?: string,
    target?: string,
    ariaLabel?: string,
    isMainDomain?: boolean
    useProvidedURLInFul?: boolean,
}

export const OptionalNextLink: React.FC<React.PropsWithChildren<OptionalNextLinkProps>> = ({ useNextRouter = true, href, children, target, ariaLabel, isMainDomain = false, useProvidedURLInFul = false }) => {

    if (!href) return <>{children}</>;

    // If Not on main Domain. always use an external link to the main Domain.
    if (!isMainDomain && !useProvidedURLInFul) {
        return <UnstyledLink href={getAbsoluteUrl(href)} target={target} aria-label={ariaLabel}>{children}</UnstyledLink>
    }

    if (useNextRouter) {
        return <Link href={href}>
            {children}
        </Link>
    } else {
        return <UnstyledLink href={href} target={target} aria-label={ariaLabel}>{children}</UnstyledLink>
    }
}