import styled from "styled-components";
import { theme } from "../../theme/theme";

export const NotificationInlineLink = styled.a`
    color: ${theme.v3.colors.darkRed};
    text-decoration: underline;
    -webkit-font-smoothing: antialiased;
    
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;
