interface V3IconRedirectProps extends React.SVGProps<SVGSVGElement> {
    stroke?: string;
}

export const V3IconRedirect = ({ stroke = "#ABB0BA", ...rest }: V3IconRedirectProps) => <svg width="16" height="16" {...rest} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 2H14V7" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 8.00024L14.0104 1.98984" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 3H3.5C2.67157 3 2 3.67157 2 4.5V12.5C2 13.3284 2.67157 14 3.5 14H11.5C12.3284 14 13 13.3284 13 12.5V10.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>

