import styled from "styled-components";

export const NSPSearchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > div {
        &:first-child {
            margin-right: 12px;
        }
    }
`;