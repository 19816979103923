import { useRouter } from "next/router";
import { useContext } from "react";
import { isProSubscription } from "authory-api-types/dist/utils/isProSubscription";
import { CookieContext } from "../../context/cookieContext";
import { V3HasAccessToBillingPage } from "../../pagelayouts/settings/billing/payment.utils";
import { theme } from "../../theme/theme";
import { APPLICATION_ROUTES } from "../../types/routes";
import { getAuthenticatedUserClient } from "../../utils/getAuthenticatedUser";
import { V3IconMyAccount, V3IconSources, V3IconCard, V3IconBell, V3IconOut, V3IconGift, V3IconSettings, V3IconPortfolio, V3IconCertificates } from "../Icons";
import { V3Body } from "../Typography";
import { SettingsLinkPanel, V3LinksPanel } from "./V3LinksPanel";
import { LinksPanelLinkWrapper } from "./V3LinksPanel.styles";
import { useSignOut } from "../../hooks/useSignOut";
import { SubscriptionStatus, UserAccountType } from "authory-api-types/dist/enums";

export const V3SettingsLinksPanel = () => {
    const router = useRouter();
    const signOut = useSignOut();
    const token = useContext(CookieContext);
    const { authenticatedUser: { data } } = getAuthenticatedUserClient(token, router, true);
    const showBillingPage = V3HasAccessToBillingPage(data);

    const isBusinessParent = data?.type === UserAccountType.BusinessParent;
    const isOnTrial = data?.subscriptionStatus === SubscriptionStatus.InTrial;
    const isPro = isProSubscription(data?.subscriptionPlanKey, isOnTrial);

    const links: SettingsLinkPanel[] = [{
        icon: <V3IconMyAccount />,
        label: "Account",
        href: APPLICATION_ROUTES.SETTINGS_ACCOUNT
    }];

    if (!isBusinessParent) {
        links.push({
            icon: <V3IconSources />,
            label: "Sources",
            href: APPLICATION_ROUTES.SETTINGS_SOURCES
        });
    }

    if (showBillingPage) {
        links.push({
            icon: <V3IconCard />,
            label: "Billing",
            href: APPLICATION_ROUTES.SETTINGS_BILLING,
        });
    }

    if (!isBusinessParent) {
        links.push({
            icon: <V3IconCertificates stroke="#ABB0BA" />,
            label: "Certificates",
            href: APPLICATION_ROUTES.SETTINGS_CERTIFICATES
        });
    }

    if (!isBusinessParent) {
        links.push({
            icon: <V3IconGift stroke="#ABB0BA" />,
            label: "Refer a Friend",
            href: APPLICATION_ROUTES.SETTINGS_REFERRAL
        });
    }

    links.push({
        icon: <V3IconBell />,
        label: "Notifications",
        href: APPLICATION_ROUTES.SETTINGS_NOTIFICATIONS
    });

    if (!isBusinessParent) {
        links.push({
            icon: <V3IconSettings />,
            label: "Integrations",
            href: APPLICATION_ROUTES.SETTINGS_WEBHOOKS,
            inactive: !isPro,
            proTooltip: !isPro,
            proTooltipContent: <>Zapier integration and API access are part of the Pro plan.</>
        });

        links.push({
            icon: <V3IconPortfolio width={16} height={16} />,
            label: "Custom domain",
            href: APPLICATION_ROUTES.SETTINGS_CUSTOM_DOMAINS,
            inactive: !isPro,
            proTooltip: !isPro,
            proTooltipContent: <>Custom domain support is part of the Pro plan.</>
        });
    }

    return <>
        <V3LinksPanel
            links={links}
        >
            <LinksPanelLinkWrapper $baseColor={theme.v3.colors.red}>
                <div><V3IconOut /></div>
                <div><V3Body onClick={signOut}><strong>Sign out</strong></V3Body></div>
            </LinksPanelLinkWrapper>
        </V3LinksPanel>
    </>
}
