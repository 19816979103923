import styled, { css } from "styled-components";
import { media, theme } from "../../theme/theme";

export const V3DashMixin = css<{ $dash?: boolean }>`
  position: relative;

  &:before {
    background: ${({ theme }) => theme ? `${theme.v3.colors.blue}` : null};
    content: "";
    display: ${({ $dash }) => $dash ? "block" : "none"};
    left: -32px;
    position: absolute;
    pointer-events: none;
    width: 18px;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        left: -42px;
    }

    @media print {
        display: none;
    }
  }
`;

const FontSharedStyling = css`
    color:  ${({ theme }) => theme ? `${theme.v3.colors.ink}` : null};
    -webkit-font-smoothing: antialiased;
`

const InterFontSharedStyling = css`
    ${FontSharedStyling};
    font-family: ${({ theme }) => theme ? `${theme.typography.type.inter}` : null};
`

export const LoraFontSharedStyling = css`
    ${FontSharedStyling};
    font-family: ${({ theme }) => theme ? `${theme.typography.type.lora}` : null};
`

export const V3HeadlineH1 = styled.h1`
    ${InterFontSharedStyling}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.semibold}` : null};
    font-size: 30px;
    line-height: 36px;

    ${media.tablet} {
        font-size: 40px; 
        line-height: 48px;
    }
`;

export const V3HeadlineH2 = styled.h2`
    ${InterFontSharedStyling}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.semibold}` : null};
    font-size: 24px;
    line-height: 30px;

    ${media.tablet} {
        font-size: 30px;
        line-height: 36px;  
    }
`;

export const V3HeadlineH3 = styled.h3`
    ${InterFontSharedStyling}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.semibold}` : null};
    font-size: 18px;
    line-height: 22px;
  

    ${media.tablet} {
        font-size: 24px;
        line-height: 30px;
    }
`;

export const V3HeadlineH4 = styled.h4`
    ${InterFontSharedStyling}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.semibold}` : null};
    font-size: 14px;
    line-height: 17px;

    ${media.tablet} {
        font-size: 16px;
        line-height: 20px;
    }
`;

export const V3HeadlineH5 = styled.h5`
    ${InterFontSharedStyling}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.semibold}` : null};
    font-size: 14px;
    line-height: 18px;
`;

export const V3BodyCSS = css`
    ${InterFontSharedStyling};
    font-size: 13px;
    line-height: 18px;
`;

export const V3Body = styled.p<{ $mobileSmallVariant?: boolean }>`
    ${V3BodyCSS}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.regular}` : null};
    font-size: ${({ $mobileSmallVariant }) => $mobileSmallVariant ? "11px" : "13px"};
    line-height: ${({ $mobileSmallVariant }) => $mobileSmallVariant ? "14px" : "18px"};

    ${media.tablet} {
        font-size: 13px;
        line-height: 18px;
    }
  
    strong {
        font-weight: ${({ theme }) => theme ? `${theme.typography.weight.semibold}` : null};
    }
`;

export const V3Caption = styled.p<{ $mobileSmallVariant?: boolean }>`
    ${InterFontSharedStyling}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.regular}` : null};
    font-size: 12px;
    line-height: 16px;

    strong {
        font-weight: ${({ theme }) => theme ? `${theme.typography.weight.semibold}` : null};
    }  
`;

export const V3Title = styled.p`
    ${LoraFontSharedStyling}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.medium}` : null};
    font-size: 22px;
    line-height: 30px;
`;

export const V3SubtitleMedium = styled.p<{ $dash?: boolean }>`
    ${LoraFontSharedStyling}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.medium}` : null};
    font-size: 18px;
    line-height: 24px;

    ${V3DashMixin}
`;

export const V3SubtitleRegular = styled.p`
    ${LoraFontSharedStyling}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.regular}` : null};
    font-size: 18px;
    line-height: 27px;
`;

export const V3Paragraph = styled.p`
    ${LoraFontSharedStyling}
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.regular}` : null};
    font-size: 17px;
    line-height: 27px;

    ${media.tablet} {
        font-size: 18px;
        line-height: 30px;
    }

    strong {
        font-weight: ${({ theme }) => theme ? `${theme.typography.weight.semibold}` : null};
    }
`;