interface V3IconPortfolioProps extends React.SVGProps<SVGSVGElement> {
    stroke?: string;
}

export const V3IconPortfolio = ({ stroke = "#ABB0BA", ...rest }: V3IconPortfolioProps) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M19 4H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM4 8h16M8 4v4" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="m8.5 17 3.5-6 3.5 6" stroke={stroke} strokeWidth="1.5" />
</svg>

export const V3IconPortfolio16 = ({ ...rest }: V3IconPortfolioProps) => <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M13.25 2H2.75a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h10.5a.75.75 0 0 0 .75-.75V2.75a.75.75 0 0 0-.75-.75ZM2 5h12M5 2v3" stroke="#ABB0BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="m5 12 3-4 3 4" stroke="#ABB0BA" strokeWidth="1.5" />
</svg>
