import styled from "styled-components";
import { H4, SmallText } from "../Typography";

export const PanelItemPrimaryText = styled(H4) <{ disabled: boolean, selected?: boolean }>`
    color: ${({ theme, disabled }) => disabled ? theme.marketing.colors.dodgerBlue80 : theme.marketing.colors.dodgerBlue100};
    line-height: 26px;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    word-wrap: nowrap;
    font-weight: ${({ selected }) => selected ? "bold" : undefined};

    > div {
        &:first-child {
            margin-right: 7px;
            position: relative;
            top: -1px;
        }
    }
`;

export const PanelItemSecondaryText = styled(SmallText) <{ secondaryTextMaxWidth?: number }>`
    color:  ${({ theme }) => theme ? theme.marketing.colors.blackPearl80 : null};
    max-width: ${({ secondaryTextMaxWidth }) => secondaryTextMaxWidth ? `${secondaryTextMaxWidth}px` : null};
`;

export const NavigateArrowSVG = styled.svg`
    transition: transform .25s ease;

    path {
        transition: stroke .25s ease;
    }
`;

export const PanelItemContent = styled.div<{ disabled: boolean }>`
    display: flex;
    align-items: center;

    > div {
        &:first-child {
            display: inline-flex;
        }

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &:hover {
        cursor: ${({ disabled }) => !disabled ? "pointer" : "initial"};

        ${PanelItemPrimaryText} {
            color: ${({ theme, disabled }) => !disabled ? theme.marketing.colors.chathamsBlue : null};
        }

        ${NavigateArrowSVG} {
            transform:  ${({ disabled }) => !disabled ? "translateX(7px)" : null};

            path {
                stroke: ${({ theme, disabled }) => !disabled ? theme.marketing.colors.chathamsBlue : null};
            }
        }
    }
`