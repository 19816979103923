import { AuthenticatedUserResponse, BillingSessionQueryParams, CreatePaymentIntentBodyParams, PaymentIntentResponse, SetPaymentBodyParams, UserBillingBodyParams } from "authory-api-types/dist/types";
import { PaypalConfirmLink, PaypalConfirmLinkNoSession, SetPayment, SetPaymentNoSession, SubscriptionPaymentIntent, SubscriptionPaymentIntentNoSession, UserBillingAddress } from "authory-api-types/dist/endpoints";
import axios from "axios";
import { API_URL } from ".";
import { CommonArgs } from "./collections";
import { getHeaders } from "./get-headers";
import { NotificationTargets } from "../types/notification";

interface setPaymentPayload {
    data: SetPaymentBodyParams,
    userSlug: string,
    token: string,
    target?: NotificationTargets
}

export const setPayment = async ({ token, userSlug, data }: setPaymentPayload) => {
    const res = await axios.post<AuthenticatedUserResponse>(
        `${API_URL}${SetPayment.replace(":userId?", userSlug)}`,
        data,
        getHeaders(token)
    );

    return res.data;
};

interface getPayPalConfirmLinkPayload {
    userSlug: string,
    token: string
}

export const getPayPalConfirmLink = async ({ token, userSlug }: getPayPalConfirmLinkPayload) => {
    const res = await axios.post<{ url: string }>(
        `${API_URL}${PaypalConfirmLink.replace(":userId?", userSlug)}`, undefined, getHeaders(token)
    );

    return res.data;
};

interface getSubscriptionPaymentIntentPayload {
    userSlug: string,
    token: string,
    params: CreatePaymentIntentBodyParams
}

export const getSubscriptionPaymentIntent = async ({ token, userSlug, params }: getSubscriptionPaymentIntentPayload) => {
    const res = await axios.post<PaymentIntentResponse>(
        `${API_URL}${SubscriptionPaymentIntent.replace(":userId?", userSlug)}`, params, getHeaders(token)
    );

    return res.data;
};

interface setPaymentAddressArgs extends CommonArgs {
    params: UserBillingBodyParams
}

export const setPaymentAddress = async ({ token, userSlug, params }: setPaymentAddressArgs) => {
    const res = await axios.post<AuthenticatedUserResponse>(
        `${API_URL}${UserBillingAddress.replace(":userId?", userSlug)}`,
        {
            firstName: params.firstName,
            lastName: params.lastName,
            lineOne: params.lineOne,
            lineTwo: params.lineTwo,
            city: params.city,
            state: params.state,
            zip: params.zip,
            country: params.country,
        },
        getHeaders(token)
    );

    return res.data;
};

// SESSION LESS API endpoints
interface getPayPalConfirmLinkNoSessionParams {
    params: BillingSessionQueryParams
}

export const getPayPalConfirmLinkNoSession = async ({ params }: getPayPalConfirmLinkNoSessionParams) => {
    const res = await axios.post<{ url: string }>(`${API_URL}${PaypalConfirmLinkNoSession}`, undefined, { params });

    return res.data;
};

interface getSubscriptionPaymentIntentNoSessionParams {
    params: BillingSessionQueryParams
}

export const getSubscriptionPaymentIntentNoSession = async ({ params }: getSubscriptionPaymentIntentNoSessionParams) => {
    const res = await axios.post<PaymentIntentResponse>(`${API_URL}${SubscriptionPaymentIntentNoSession}`, undefined, { params });

    return res.data;
};

interface getSubscriptionPaymentIntentPayloadNoSession {
    queryParams: BillingSessionQueryParams,
    bodyParams: SetPaymentBodyParams
}

export const setPaymentNoSession = async ({ queryParams, bodyParams }: getSubscriptionPaymentIntentPayloadNoSession) => {
    const res = await axios.post<AuthenticatedUserResponse>(
        `${API_URL}${SetPaymentNoSession}`,
        bodyParams,
        { params: queryParams }
    );

    return res.data;
};