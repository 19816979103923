import Link from "next/link"
import React from "react"
import { V3Body } from "../Typography"
import { TCTACommonWrapper, V3TertiaryCTABtn, V3TertiaryCTALink } from "./V3TertiaryCTA.styles"
import { SharedTertiaryProps, V3TertiaryButtonProps, V3TertiaryCTAIconPosition, V3TertiaryCTAColor, V3TertiaryLinkProps } from "./V3TertiaryCTA.types"

const TCTACommon = ({
    icon,
    text,
    iconPosition = V3TertiaryCTAIconPosition.left,
}: SharedTertiaryProps) => {
    return <TCTACommonWrapper
        $iconPosition={iconPosition}
    >
        {icon && <div>{icon}</div>}
        {text && <V3Body as="div"><strong>{text}</strong></V3Body>}
    </TCTACommonWrapper>
}

export const V3TertiaryButton = ({ iconPosition, icon, text, ctaColor = V3TertiaryCTAColor.color, ...rest }: V3TertiaryButtonProps) => <V3TertiaryCTABtn $ctaColor={ctaColor} {...rest} >
    <TCTACommon
        iconPosition={iconPosition}
        icon={icon}
        text={text}
    />
</V3TertiaryCTABtn>

export const V3TertiaryLink = ({ iconPosition, icon, text, ctaColor = V3TertiaryCTAColor.color, useNextRouter = true, href, ...rest }: V3TertiaryLinkProps) => {
    const ElemToUse = useNextRouter ? Link : React.Fragment;

    const props: any = {};

    if (useNextRouter) {
        props.useNextRouter = true;
        props.href = href!;
        props.legacyBehavior = true;
    }

    return <ElemToUse {...props}>
        <V3TertiaryCTALink $ctaColor={ctaColor} href={href} {...rest} >
            <TCTACommon
                iconPosition={iconPosition}
                icon={icon}
                text={text}
            />
        </V3TertiaryCTALink>
    </ElemToUse>
}