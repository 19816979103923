const SvgCheckIcon = () => <img src="/authory_static_files/images/icons/check_mark.svg" />

export const AUTHORY_2023_PLAN_TIERS = {
    STANDARD: {
        price: 15,
        yearlyDiscountFactor: 0.8,
        label: "Standard",
        perks: [
            {
                label: "Unlimited content items",
                icon: <SvgCheckIcon />,
                tooltip: "Add an unlimited number of articles, videos, podcasts, PDFs, screenshots or social media posts to your account.",
            },
            {
                label: "Self-updating portfolio page",
                icon: <SvgCheckIcon />,
                tooltip: "Authory will update your portfolio with your new articles, podcasts and videos, no matter where they are published.",
            },
            {
                label: "Automated import of all your past and future content",
                icon: <SvgCheckIcon />,
                tooltip: "Authory will find and import all your existing articles, podcasts and videos automatically, even if they've been published years ago.",
            },
            {
                label: "Searchable content archive with backups of all your content",
                icon: <SvgCheckIcon />,
                tooltip: "Your personal content archive makes your entire body of work 100% safe against deletion, plus it's easy to search and filter.",
            },
            {
                label: "Detailed social media analytics",
                icon: <SvgCheckIcon />,
                tooltip: "Authory will tell you exactly how often each piece of content you create is shared on social media."
            },
            {
                label: "Integrated blog and newsletter",
                icon: <SvgCheckIcon />,
                tooltip: "Forget about a separate blog and newsletter setup, Authory includes both.",
            },
        ]
    },
    PRO: {
        price: 24,
        yearlyDiscountFactor: 0.75,
        label: "Professional",
        perks: [
            {
                label: "Custom domain support",
                icon: <SvgCheckIcon />,
                tooltip: "Host your self-updating portfolio page at a domain that you own.",
            },
            {
                label: "Zapier app to integrate Authory in your existing workflows",
                icon: <SvgCheckIcon />,
                tooltip: "Our Zapier integration enables you to connect Authory easily to other apps",
            },
            {
                label: "Full-fledged API access to use your imported content with full flexibility",
                icon: <SvgCheckIcon />,
                tooltip: "Connect directly to our platform with our custom built API",
            },
            {
                label: "Highest update frequency",
                icon: <SvgCheckIcon />,
                tooltip: "Automated importing of your new content at least every 4h."
            },
        ]
    }
}