import styled from "styled-components";
import { media } from "../../theme/theme";

const FTBR = 800;

export const V3FooterWrapper = styled.div<{ $maxWidth?: string }>`
    display: flex;
    max-width: ${({ $maxWidth }) => $maxWidth ? $maxWidth : "1056px"};
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    
    ${media.custom(414)} {
        align-items: center;
    }

    ${media.custom(FTBR)} {
        flex-direction: row;
    }

    @media print {
        display: none;
    }
`;

export const V3FooterContainer = styled.div`
    padding: 30px 0 18px;
`;

export const V3LeftS = styled.div`
    display: flex;
    align-items: center;

    > div {
        &:first-child {
            margin-right: 25px;
        }
    }
`;

export const V3RightS = styled.div<{ $showMarketingLinks?: boolean }>`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-top: ${({ $showMarketingLinks }) => $showMarketingLinks ? "0px" : "15px"};

    ${media.custom(FTBR)} {
        margin-top: 0;
    }

    > div {
        &:not(:last-child) {
            margin-right: 30px;
        }
    }
`;

export const DynamicLogoSectionPublicWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
`;

export const V3FooterBLogLinks = styled.ul`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 18px;

    ${media.custom(FTBR)} {
        justify-content: left;
        margin-bottom: 0;
        margin-right: 45px;
    }

    > li {
        &:not(:last-child) {
            margin-right: 5px;
        }
    }
`;

export const V3FooterBlogLink = styled.a`
    color: inherit;
    text-decoration: underline;
`;

export const BuiltFooterBr = styled.br`
    display: block;

    ${media.custom(490)} {
        display: none;
    }
`;