export const V3IconInstagram = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.2178 15.5749L3.79678 15.5829C1.94415 15.5846 0.427099 14.0705 0.424994 12.2178L0.416994 3.79678C0.415309 1.94415 1.92941 0.427099 3.78205 0.424994L12.2031 0.416994C14.0557 0.415309 15.5728 1.92941 15.5749 3.78205L15.5829 12.2031C15.585 14.0562 14.0705 15.5732 12.2178 15.5749Z" fill="url(#paint0_radial_4095_12263)" />
    <path d="M12.2178 15.5749L3.79678 15.5829C1.94415 15.5846 0.427099 14.0705 0.424994 12.2178L0.416994 3.79678C0.415309 1.94415 1.92941 0.427099 3.78205 0.424994L12.2031 0.416994C14.0557 0.415309 15.5728 1.92941 15.5749 3.78205L15.5829 12.2031C15.585 14.0562 14.0705 15.5732 12.2178 15.5749Z" fill="url(#paint1_radial_4095_12263)" />
    <path d="M8.0001 10.9475C6.37526 10.9475 5.05273 9.62537 5.05273 8.0001C5.05273 6.37484 6.37526 5.05273 8.0001 5.05273C9.62495 5.05273 10.9475 6.37484 10.9475 8.0001C10.9475 9.62537 9.62495 10.9475 8.0001 10.9475ZM8.0001 5.89484C6.83926 5.89484 5.89484 6.83926 5.89484 8.0001C5.89484 9.16095 6.83926 10.1054 8.0001 10.1054C9.16095 10.1054 10.1054 9.16095 10.1054 8.0001C10.1054 6.83926 9.16095 5.89484 8.0001 5.89484Z" fill="white" />
    <path d="M11.1579 5.4741C11.5068 5.4741 11.7895 5.19133 11.7895 4.84252C11.7895 4.49371 11.5068 4.21094 11.1579 4.21094C10.8091 4.21094 10.5264 4.49371 10.5264 4.84252C10.5264 5.19133 10.8091 5.4741 11.1579 5.4741Z" fill="white" />
    <path d="M10.5264 13.4737H5.47374C3.84889 13.4737 2.52637 12.1516 2.52637 10.5264V5.47374C2.52637 3.84847 3.84889 2.52637 5.47374 2.52637H10.5264C12.1512 2.52637 13.4737 3.84847 13.4737 5.47374V10.5264C13.4737 12.1516 12.1512 13.4737 10.5264 13.4737ZM5.47374 3.36847C4.31289 3.36847 3.36847 4.31289 3.36847 5.47374V10.5264C3.36847 11.6872 4.31289 12.6316 5.47374 12.6316H10.5264C11.6872 12.6316 12.6316 11.6872 12.6316 10.5264V5.47374C12.6316 4.31289 11.6872 3.36847 10.5264 3.36847H5.47374Z" fill="white" />
    <defs>
        <radialGradient id="paint0_radial_4095_12263" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.05489 15.5938) scale(18.9048)">
            <stop stopColor="#FFDD55" />
            <stop offset="0.328" stopColor="#FF543F" />
            <stop offset="0.348" stopColor="#FC5245" />
            <stop offset="0.504" stopColor="#E64771" />
            <stop offset="0.643" stopColor="#D53E91" />
            <stop offset="0.761" stopColor="#CC39A4" />
            <stop offset="0.841" stopColor="#C837AB" />
        </radialGradient>
        <radialGradient id="paint1_radial_4095_12263" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.85741 0.227647) scale(12.5528 8.36396)">
            <stop stopColor="#4168C9" />
            <stop offset="0.999" stopColor="#4168C9" stopOpacity="0" />
        </radialGradient>
    </defs>
</svg>