import styled from "styled-components";
import { media, theme } from "../../theme/theme";
import { V3CaptionGrey48 } from "../ReusableStyledComponents/V3CaptionGrey48";
import { V3Body, V3HeadlineH3 } from "../Typography";
import { V3DashboardCardVariants } from "./V3DashboardCard.types";

const LARGE_BR = 1160;

export const DashboardCardFakeCTA = styled(V3Body)`
    display: none;
    transition: opacity .3s ease;
    color: ${({ theme }) => theme.v3.colors.blue};

    ${media.custom(470)} {
        display: block;
        opacity: 1;
    }

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        display: block;
        opacity: 0;
    }
`;

export const CaptionCardWrapper = styled.div`
    display: flex;
    align-items: center;
   
    ${V3CaptionGrey48} {
        max-width: calc(100%);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    > svg {
        flex-shrink: 0;
        display: block;
        margin-right: auto;
        margin-left: 5px;
    }
`;

export const DashboardCardWrapper = styled.div<{ $useSmallSquare?: boolean, variant: V3DashboardCardVariants, $noShadow?: boolean }>`
    background: #FFFFFF;
    box-shadow: ${({ variant, $noShadow }) => variant === V3DashboardCardVariants.primary && !$noShadow ? "0px 4px 8px rgba(27, 34, 50, 0.1)" : undefined};
    border: 1px solid #DDDFE3;
    border-radius: 18px;
    padding: 15px;
    display: flex;
    align-items: center;

    ${media.custom(LARGE_BR)} {
        padding: 24px;
    }

    &:hover{
        box-shadow: ${({ $noShadow }) => !$noShadow ? "0px 4px 8px rgba(27, 34, 50, 0.1)" : undefined};
        cursor: ${({ variant }) => variant === V3DashboardCardVariants.primary ? undefined : "pointer"};

        ${DashboardCardFakeCTA} {
            opacity: 1;
        }
    }

    > div {
        &:first-child {
            margin-right: ${({ $useSmallSquare }) => $useSmallSquare ? "12px" : "24px"};
            flex-shrink: 0;

            ${media.custom(LARGE_BR)} {
                margin-right: 24px;
            }
        }

        &:nth-child(2) {
            width: 100%;
            max-width: 100%;
            overflow: hidden;
        }
    }
`;

export const DashboardCardIcon = styled.div<{ background?: string, $useSmallSquare?: boolean }>`
    width: ${({ $useSmallSquare }) => $useSmallSquare ? "36px" : "48px"};
    height: ${({ $useSmallSquare }) => $useSmallSquare ? "36px" : "48px"};
    background: ${({ background }) => background ? background : "white"};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.custom(LARGE_BR)} {
        width: 48px;
        height: 48px;
    }
`;

export const DashboardCardHeadlineWrapper = styled.div<{ variant: V3DashboardCardVariants }>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        &:nth-child(2) {
            ${media.custom(LARGE_BR)} {
                margin-top: 5px;
            }
        }
    }
`;

export const DashboardCardHeadline = styled(V3HeadlineH3) <{ disabled?: boolean }>`
    color: ${({ theme, disabled }) => disabled ? theme.v3.colors.grey70 : undefined};
`;

