import styled from "styled-components";
import { V3HeadlineH3, V3Paragraph } from "../../components/Typography";
import { media } from "../../theme/theme";

export const SignupLayoutContainerWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
`;

export const SignupLayoutContainer = styled.div<{ $rightSideMaxWidth?: number, $hideRightSideMobile?: boolean, $minHeight?: string, $rightSideBiggerPadding?: boolean }>`
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
    }

    > div {
        width: 100%;

        &:first-child {
            padding: 0 18px;
        }

        &:nth-child(2) {
            padding: ${({ $rightSideBiggerPadding }) => $rightSideBiggerPadding ? "0px 52px" : "12px"};
            display: ${({ $hideRightSideMobile }) => $hideRightSideMobile ? "none" : undefined};

            ${media.tablet} {
                max-width: ${({ $rightSideMaxWidth }) => $rightSideMaxWidth ? `${$rightSideMaxWidth}px` : undefined};
                display: block;
                min-height: ${({ $minHeight }) => $minHeight};
            }

            > div {
                min-height: ${({ $minHeight }) => $minHeight};
                border-radius: 12px;
                background: ${({ theme }) => theme.v3.colors.grey96};
            }
        }
    }
`;

export const SignupHeader = styled.div<{ $isPreview?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
    max-width: ${({ $isPreview }) => $isPreview ? "600px" : undefined};
    margin-left: auto;
    margin-right: auto;

    ${media.tablet} {
        margin-top: 72px;
    }
`;


export const SignupLayoutTitleDescriptionWrapper = styled.div<{ $isPreview?: boolean }>`
    max-width: ${({ $isPreview }) => $isPreview ? "600px" : undefined};
    margin-left: auto;
    margin-right: auto;
`;

export const SignupLeftsideContainer = styled.div<{ $leftSideMaxWidth?: number }>`
    width: 100%;
    max-width: ${({ $leftSideMaxWidth }) => $leftSideMaxWidth ? `${$leftSideMaxWidth}px` : undefined};
    margin: 0 auto;
   
`;

export const SignupParagraph = styled(V3Paragraph)`
    color: ${({ theme }) => theme.v3.colors.grey35};
`;

export const V3SignupHeadline3 = styled(V3HeadlineH3) <{ $hideRightSideMobile?: boolean }>`
    &:before {
        background: ${({ theme }) => theme ? `${theme.v3.colors.blue}` : null};
        content: "";
        display: none;
        left: -32px;
        position: relative;
        pointer-events: none;
        width: 16px;
        height: 3px;
        top: 17px;
        transform: translateY(-50%);

        ${media.tablet} {
            display: ${({ $hideRightSideMobile }) => $hideRightSideMobile ? "none" : "block"};
        }

        ${media.custom(1100)} {
            display: block;
        }
    }
`;