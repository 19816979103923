import styled from "styled-components";
import { V3LoaderSpinner } from "../Loader";
import { V3BodyGrey48 } from "./V3BodyGrey48";

const MediaSubmitWrapper = styled.div`
    display: flex;
    align-items: center;
    min-height: 42px;

    > div {
        &:nth-child(2) {
            margin-left: 18px;
        }
    }
`;

export const ModalLoadingSpinner = ({ skipLabel = false }: { skipLabel?: boolean }) => <MediaSubmitWrapper>
    {
        !skipLabel && <div>
            <V3BodyGrey48>
                <strong>Submitting</strong>
            </V3BodyGrey48>
        </div>
    }
    <div>
        <V3LoaderSpinner />
    </div>
</MediaSubmitWrapper>