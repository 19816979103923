import React from "react";
import { MCTA } from "./MarketingCTA.styles";

export enum CTASize {
    S = "S",
    M = "M",
    L = "L",
}

export enum CTAVariant {
    Primary = "Primary",
    Secundary = "Secundary"
}

interface MarketingCTAProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean
    variant?: CTAVariant
    size: CTASize
    text: string
    className?: string
    minWidth?: number
}

export const MarketingCTA = ({ disabled, size, text, variant = CTAVariant.Primary, className, minWidth, ...rest }: MarketingCTAProps) => <MCTA
    className={className}
    disabled={disabled}
    size={size}
    variant={variant}
    minWidth={minWidth}
    {...rest}
>
    <div>
        {text}
    </div>
</MCTA>