export enum V3TertiaryCTAColor {
    color = "color",
    gray = "gray",
    red = "red",
    white = "white",
}

export enum V3TertiaryCTAIconPosition {
    left = "left",
    right = "right",
}

export interface SharedTertiaryProps {
    ctaColor?: V3TertiaryCTAColor;
    icon?: JSX.Element;
    text?: string;
    iconPosition?: V3TertiaryCTAIconPosition;
    useNextRouter?: boolean,
}

export interface V3TertiaryButtonProps extends SharedTertiaryProps, React.ButtonHTMLAttributes<HTMLButtonElement> { }
export interface V3TertiaryLinkProps extends SharedTertiaryProps, React.AnchorHTMLAttributes<HTMLAnchorElement> { }