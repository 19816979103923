import { SignupCreateAccount, SignupSetupCommunication, SignupSetupExperimentTags, SignupUserArticles } from "authory-api-types/dist/endpoints";
import { CreateAccountBodyParams, SignupCommunicationDetails, AuthenticatedUserResponse, UserSignInResponse, SetupExperimentTags, ArticleSearchResultResponse, ArticleFilterQueryParams } from "authory-api-types/dist/types";
import axios, { AxiosError } from "axios";
import { API_URL } from ".";
import { logAxiosError } from "../logging/logSentryError";
import { Errors } from "../types/api";
import { APPLICATION_ROUTES } from "../types/routes";
import { NotificationHandler } from "./error-handler";
import { getHeaders } from "./get-headers";

export const createAccount = async (credentials: CreateAccountBodyParams) => {
    const { data } = await axios.post<UserSignInResponse>(`${API_URL}${SignupCreateAccount}`, credentials);
    return data;
};

export const onCreateAccountError = (toastHandler: NotificationHandler) => async (error: AxiosError) => {
    const Message = () => <span>It looks like you've already signed up. You can log in <a style={{ color: "inherit" }} href={APPLICATION_ROUTES.LOGIN}>here</a>.</span>;

    if (error?.response?.status === Errors.State) {
        toastHandler(<Message />);
    } else if (error?.response?.status === Errors.AccessDenied) {
        toastHandler("Sorry, signing up with your preferred method didn't work. Please sign up via email.");
    } else {
        toastHandler("An unexpected error occured");
        logAxiosError(error);
    }
};

interface SetupCommunicationParams {
    params: SignupCommunicationDetails,
    token: string
}

export const setupCommunication = async ({ params, token }: SetupCommunicationParams) => {
    const { data } = await axios.post<AuthenticatedUserResponse>(`${API_URL}${SignupSetupCommunication}`, params, getHeaders(token));
    return data;
};

interface SetupExperimentsParams {
    params: SetupExperimentTags,
    token: string
}

export const setupExperiments = async ({ params, token }: SetupExperimentsParams) => {
    const { data } = await axios.post<AuthenticatedUserResponse>(`${API_URL}${SignupSetupExperimentTags}`, params, getHeaders(token));
    return data;
};

export const getSignupUserContent = async (token: string | null, userSlug: string | undefined, params: ArticleFilterQueryParams) => {
    const { data } = await axios.get<ArticleSearchResultResponse>(
        `${API_URL}${SignupUserArticles.replace(":userId?", userSlug || "")}`,
        {
            params,
            ...getHeaders(token || "")
        }
    );

    return data;
}