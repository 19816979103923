import styled from "styled-components";
import { V3ToggleColorVariant } from "./V3ToggleSwitch";

export const V3ToggleSpan = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme ? theme.v3.colors.grey70 : null};
    transition: transform .4s;
    border-radius: 34px;

    &:hover {
        background-color: ${({ theme }) => theme ? theme.v3.colors.grey48 : null};
    }

    &:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 3px;
        bottom: 2px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
    }
`;

export const V3ToggleInput = styled.input<{ variant: V3ToggleColorVariant }>`
    &:checked + ${V3ToggleSpan} {
        background-color: ${({ theme, variant }) => variant === V3ToggleColorVariant.green ? theme.v3.colors.green : theme.v3.colors.blue};
    }

    &:checked:not(:disabled) + ${V3ToggleSpan}:hover {
        background-color: ${({ variant, theme }) => variant === V3ToggleColorVariant.green ? theme.v3.colors.darkgreen : theme.v3.colors.darkBlue};
    }

    &:checked + ${V3ToggleSpan}:before {
        transform: translateX(9px);
    }

    &:disabled + ${V3ToggleSpan} {
        cursor: initial;
        background-color: ${({ theme }) => theme ? theme.v3.colors.grey88 : null};
    }
`;

export const V3ToggleLabel = styled.label<{ marginLeft?: number }>`
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
    margin-left: ${({ marginLeft }) => marginLeft ? `${marginLeft}px` : null};

    ${V3ToggleInput} {
        opacity: 0;
        width: 0;
        height: 0;
    }
`
