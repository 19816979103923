import styled from "styled-components";
import { media } from "../../theme/theme";

const MOBILE_BR = 700;

export const V3NOverviewWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
        width: 100%;
        margin-bottom: 12px;

        ${media.custom(MOBILE_BR)} {
            width: calc(50% - 6px);
        }
    }
`;

export const NewsletterIframe = styled.iframe`
    width: 100%;
    background: white;
    display: block;
`;

export const NewsletterPreviewHeader = styled.div`
    display: flex;
    background: ${({ theme }) => theme.v3.colors.grey96};
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    &:before {
        position: absolute;
        width: 1px;
        background: ${({ theme }) => theme.v3.colors.grey88};
        top: 0;
        left: 50%;
        content: "";
        display: none;
        height: 100%;

        ${media.custom(MOBILE_BR)} {
            display: block;
        }
    }

    > div {
        width: 100%;
        padding: 20px 24px;

        ${media.custom(MOBILE_BR)} {
            width: 50%;
            padding: 33px 24px;
        }

        &:first-child {
            padding-bottom: 10px;

            ${media.custom(MOBILE_BR)} {
                padding-bottom: 33px;
            }
        }
    }
`;

export const NewsletterPreviewWrapper = styled.div`
    border-radius: 18px;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.v3.colors.grey88};
`;

export const NewsletterPreviewPanel = styled.div`
    display: flex;
    align-items: center;

    > div {
        &:first-child {
            min-width: 42px;
            flex-shrink: 0;
            margin-right: 20px;
        }
    }
`;

export const NewsletterPreviewPanelSquare = styled.div`
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    height: 48px;
    width: 48px;
`;