import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { throttle } from 'lodash';

type scrollShadowHook = () => [boolean, Dispatch<SetStateAction<boolean>>, () => void];

export const scrollShadowHook: scrollShadowHook = () => {
    const [showShadow, setShowShadow] = useState(false);

    const scrollCheck = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
        const scrolled = winScroll / height;

        if (scrolled > 0) {
            setShowShadow(true);
        } else {
            setShowShadow(false);
        }
    }

    useEffect(() => {
        const throttleScroll = throttle(scrollCheck, 100);

        window.addEventListener('scroll', throttleScroll);

        return () => {
            window.removeEventListener('scroll', throttleScroll);
        };
    }, []);

    return [showShadow, setShowShadow, scrollCheck];
};