import { AuthenticatedUser } from "../../types/user";
import { BaseTracker } from "../BaseTracker";

// This class is a utility helper that should be used to call MixPanel tracking methods
export class GoogleAnalyticsTrackerMock extends BaseTracker {

    constructor(user: AuthenticatedUser) {
        super(user, process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);
    }

    protected initializeInternal() {
        console.log("Mock - Google Analytics initializeInternal");
    }

    protected trackPageViewInternal() {
        console.log("Mock - Google Analytics trackPageViewInternal");
    }

    protected trackEventInternal() {
        console.log("Mock - Google Analytics trackEventInternal");
    }

    protected shutdownInternal() {
        console.log("Mock - Google Analytics shutdownInternal");
    }

    trackLogin() {
        console.log("Mock - Google Analytics trackLogin");
    }
}