import { throttle } from "lodash";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useWindowSize } from "./useWindowSize";

export const useCustomScrollShadows = () => {
    const [bottomShadow, setBottomShadow] = useState(false);
    const [topShadow, setTopShadow] = useState(false);
    const [leftShadow, setLeftShadow] = useState(false);
    const [rightShadow, setRightShadow] = useState(false);

    const [scrollElem, setScrollElem] = useState(null);
    const size = useWindowSize();

    const scrollCheck = ({ target }: any) => {
        if (!target) return;

        const el = target as HTMLDivElement;
        const isMaxVerticalScroll = Math.ceil(el.offsetHeight + el.scrollTop) >= el.scrollHeight;

        if (isMaxVerticalScroll) {
            setBottomShadow(false);
        } else {
            setBottomShadow(true);
        }

        if (el.scrollTop > 0) {
            setTopShadow(true);
        } else {
            setTopShadow(false);
        }

        const isMaxHorizontalScroll = Math.ceil(el.offsetWidth + el.scrollLeft) >= (el.scrollWidth - 2);

        if (isMaxHorizontalScroll) {
            setRightShadow(false);
        } else {
            setRightShadow(true);
        }

        if (el.scrollLeft > 0) {
            setLeftShadow(true);
        } else {
            setLeftShadow(false);
        }
    }

    useEffect(() => {
        if (scrollElem) scrollCheck({ target: scrollElem });
    }, [size.width, size.height]);

    const shadows = [];

    if (topShadow) shadows.push("inset 0 7px 9px -8px rgba(0,0,0,0.4)");
    if (bottomShadow) shadows.push("inset 0 -7px 9px -8px rgba(0,0,0,0.4)");
    if (leftShadow) shadows.push("inset 7px 0 10px -10px rgba(0,0,0,0.4)");
    if (rightShadow) shadows.push("inset -7px 0 10px -10px rgba(0,0,0,0.4)");

    return {
        ref: useCallback((node: any) => {
            setScrollElem(node);
            if (!node) return;

            setTimeout(() => {
                scrollCheck({ target: node })
            }, 100)

            let timeOut: any;

            if ("MutationObserver" in window && node) {
                var mutationObserver = new MutationObserver(function (mutations) {
                    mutations.forEach(function () {
                        if (timeOut) clearTimeout(timeOut);

                        timeOut = setTimeout(() => {
                            scrollCheck({ target: node })
                        }, 100);
                    });
                });

                // Starts listening for changes in the root HTML element of the page.
                mutationObserver.observe(node, {
                    attributes: true,
                    characterData: true,
                    childList: true,
                    subtree: true,
                    attributeOldValue: true,
                    characterDataOldValue: true
                });
            }
        }, []),
        onScroll: throttle(scrollCheck, 180),
        style: {
            boxShadow: shadows.join(",")
        },
        shadowStates: {
            topShadow,
            bottomShadow,
            rightShadow,
            leftShadow
        }
    };
}