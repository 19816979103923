import React, { useEffect, useState } from "react";
import { V3ModalBase } from "../V3ModalBase";
import { DDContentWrapper, DDContentWrapperPadding, DDTriggerElem } from "./V3Dropdown.styles";
import {
    useFloating,
    useInteractions,
    useDismiss,
    useRole,
    Placement,
    autoUpdate,
    offset,
    flip,
    shift,
    useClick,
    Strategy,
    OffsetOptions,
    useClientPoint,
    FloatingPortal
} from "@floating-ui/react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { theme } from "../../theme/theme";

type V3DropdownProps = {
    renderReferenceElement: (active: boolean, setShowPopper: React.Dispatch<React.SetStateAction<boolean>>) => JSX.Element,
    renderPopperElement: (active: boolean, setShowPopper: React.Dispatch<React.SetStateAction<boolean>>) => JSX.Element,
    maxWidth?: number,
    title?: string,
    onShowChange?: (show: boolean) => void,
    placement?: Placement,
    strategy?: Strategy,
    customOffset?: OffsetOptions,
    clientPointEnabled?: boolean,
    shiftPadding?: number,
    disabled?: boolean,
}

export const V3Dropdown = ({ renderReferenceElement, renderPopperElement, maxWidth, title, onShowChange, placement = "bottom-start", strategy = "absolute", customOffset = 10, clientPointEnabled = false, shiftPadding = 10, disabled = false }: V3DropdownProps) => {
    const size = useWindowSize();
    const isMobile = size.width && size.width < theme.v3.layout.sidebarLayout.mobile || false;

    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        strategy,
        onOpenChange: disabled ? undefined : setIsOpen,
        placement,
        // Make sure the tooltip stays on the screen
        whileElementsMounted: autoUpdate,
        middleware: [
            // Make sure tooltip is X pixels away from reference element
            offset(customOffset),
            // Make sure the tooltip is in view, even if it can't be fully centered to the element
            shift({ padding: shiftPadding }),
            // Make sure the placement is respected as long as there is space
            flip({ fallbackAxisSideDirection: "start" }),
        ]
    });

    const clientPoint = useClientPoint(context, {
        enabled: clientPointEnabled && !isOpen
    });

    const click = useClick(context, { event: "mousedown" });
    const dismiss = useDismiss(context, {
        enabled: !isMobile
    });
    const role = useRole(context, { role: "menu" }); // Role props for screen readers

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
        role,
        clientPoint
    ]);

    useEffect(() => {
        if (typeof window === "undefined") return;
        onShowChange && onShowChange(isOpen);
    }, [isOpen]);

    return isMobile ? <>
        <DDTriggerElem onClick={() => setIsOpen(!isOpen)}>
            {renderReferenceElement(isOpen, setIsOpen)}
        </DDTriggerElem>
        {
            isOpen && !disabled && <V3ModalBase
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={title}
            >
                {renderPopperElement(isOpen, setIsOpen)}
            </V3ModalBase>
        }
    </> : <span>
        <DDTriggerElem
            ref={refs.setReference}
            {...getReferenceProps()}
        >
            {renderReferenceElement(isOpen, setIsOpen)}
        </DDTriggerElem>
        <>
            {
                isOpen && <FloatingPortal>
                    <DDContentWrapper
                        $maxWidth={maxWidth}
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                    >
                        <DDContentWrapperPadding>
                            {renderPopperElement(isOpen, setIsOpen)}
                        </DDContentWrapperPadding>
                    </DDContentWrapper>
                </FloatingPortal>
            }
        </>
    </span>
}