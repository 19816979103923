import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useWindowSize } from "./useWindowSize";

const handlePageOverflow = (open: boolean) => {
    const body = document.querySelector('body');

    if (body) {
        body.style.overflow = open ? "hidden" : "initial";
    }

    // In case this component is unmounted with menu open, we cleanup the overflow
    return () => {
        const body = document.querySelector('body');
        if (body) body.style.overflow = "initial";
    }
}

type useMenuOpenOverflowHook = (toggleWidth: number) => [boolean, Dispatch<SetStateAction<boolean>>]

export const useMenuOpenOverflow: useMenuOpenOverflowHook = (toggleWidth) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const size = useWindowSize();

    useEffect(() => {
        if (!document) return;

        return handlePageOverflow(menuOpen);
    }, [menuOpen]);

    if (size.width && size.width > toggleWidth && menuOpen) {
        setMenuOpen(false);
    }

    return [menuOpen, setMenuOpen];
}