import { theme } from "../theme/theme";

export const toastConfig = {
    success: {
        icon: <div style={{ display: "inline-flex" }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 11L12 14L20 6" stroke="#71A112" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20 12V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H15" stroke="#71A112" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>,
        style: {
            background: "#e2eccf",
            boxShadow: "0px 8px 16px rgba(139, 209, 0, 0.05)",
            color: "#5a800e",
        },
    },
    error: {
        icon: <div style={{ display: "inline-flex" }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 15V15.01M12 9V11V9Z" stroke="#D60024" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 18.9995H19C19.3263 18.9972 19.6471 18.9151 19.9344 18.7604C20.2217 18.6057 20.4667 18.383 20.6482 18.1118C20.8297 17.8406 20.942 17.5291 20.9754 17.2045C21.0089 16.8799 20.9624 16.552 20.84 16.2495L13.74 3.99953C13.567 3.68693 13.3135 3.42636 13.0058 3.24491C12.698 3.06347 12.3473 2.96777 11.99 2.96777C11.6327 2.96777 11.282 3.06347 10.9742 3.24491C10.6665 3.42636 10.413 3.68693 10.24 3.99953L3.14 16.2495C3.01994 16.5451 2.97233 16.8651 3.00115 17.1828C3.02997 17.5006 3.13437 17.8068 3.30565 18.0759C3.47693 18.3451 3.71011 18.5694 3.98573 18.73C4.26134 18.8907 4.57139 18.9831 4.89 18.9995" stroke="#D60024" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>,
        style: {
            background: theme.v3.colors.lightRed,
            color: theme.v3.colors.darkRed,
        },
    },
    icon: <div style={{ display: "inline-flex" }}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#0050EE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.0098 16L12.0098 12" stroke="#0050EE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.0098 8L11.9998 8" stroke="#0050EE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </div>,
    style: {
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "13px",
        borderRadius: '10px',
        boxShadow: "0px 8px 16px rgba(214, 0, 36, 0.05)",
        background: theme.v3.colors.lightBlue,
        color: theme.v3.colors.darkBlue,
        maxWidth: 550,
    }
}