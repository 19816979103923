import React from "react";
import styled from "styled-components";

const FavSVG = styled.svg`
    &:hover {
        cursor: pointer;
        
        path {
            stroke: ${({ theme }) => theme.v3.colors.blue};
        }
    }
`;

const RegularSVG: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({ children, ...rest }) => <svg {...rest}>{children}</svg>

interface V3IconFavoriteProps extends React.SVGProps<SVGSVGElement> {
    stroke?: string;
    fill?: string;
    noHover?: boolean;
}

export const V3IconFavorite = ({ stroke = "#ABB0BA", fill = "none", ref, noHover = false, ...rest }: V3IconFavoriteProps) => {
    const WrapperToUse = noHover ? RegularSVG : FavSVG

    return <WrapperToUse width="16" height="16" {...rest} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="m8 11.833-4.115 2.163.786-4.582L1.338 6.17l4.6-.667 2.057-4.169 2.058 4.17 4.6.666-3.334 3.244.786 4.582L8 11.833Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </WrapperToUse>
}