import styled from "styled-components";
import { media } from "../../theme/theme";
import { V3Body, V3HeadlineH5 } from "../Typography";

export const S_BREAKPOINT = 910;
const SM_BREAKPOINT = 1263;

export const MarketingMenuWrapper = styled.div<{ $showShadow: boolean, $customScrollContentEnabled: boolean }>`
    position: sticky;
    top: 0; 
    background-color: ${({ $customScrollContentEnabled }) => $customScrollContentEnabled ? "#F5F8FF" : "white"};
    transition: background-color .5s ease;
    z-index: 3;
    box-shadow: ${({ $showShadow }) => $showShadow ? "0 0 1.8rem rgb(0 0 0 / 15%)" : null};

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    > div {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 63px;

        ${media.custom(SM_BREAKPOINT)} {
            min-height: 81px;
        }
    }
`;

export const DesktopMenu = styled.div<{ showShadow: boolean }>`
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const SimplifiedMenu = styled.div`
    max-width: 1336px;
    width: 100%;
    padding: 12px 18px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        &:first-child {
            display: none;

            ${media.custom(1270)} {
                display: block;
            }
        }
    }
`;

export const MenuNavWrapper = styled.div`
    display: none;

    ${media.custom(S_BREAKPOINT)} {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    > div {
        margin-bottom: 2px;

        &:not(:last-child) {
            margin-right: 25px;

            ${media.custom(1080)} {
                margin-right: 40px;
            }
        }

        &:nth-child(4) {
            margin-right: 0;

            ${media.custom(1156)} {
                margin-right: 40px;
            }
        }

        &:nth-child(5) {
            display: none;

            ${media.custom(1156)} {
                display: block;
            }
        }
    }
`;

export const MenuItemText = styled.div<{ underline?: boolean }>`
    font-size: 19px;
    line-height: 34px;
    font-weight: 400;
    font-family: ${({ theme }) => theme ? `${theme.typography.type.assistant}` : null};
    color: ${({ theme }) => theme.marketing.colors.blackPearl80};

    svg {
        margin-left: 8px;
    }

    &:hover {
        cursor: pointer;
        text-decoration: ${({ underline }) => underline ? "underline" : null};
    }
`;

export const RightSideMenuWrapper = styled.div`
    display: flex;
    align-items: center;
    
    > div {

        &:first-child {
            display: none;
            margin-right: 16px;

            ${media.custom(550)} {
                display: block;
            }
        }

        &:nth-child(3) {
            margin-left: 16px;

            ${media.custom(S_BREAKPOINT)} {
                display: none;
            }

            svg {
                display: block;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
`

export const MarketingLogo = styled.img`
    width: 131px;
    height: auto;
    display: block;

    ${media.custom(SM_BREAKPOINT)} {
        width: 170px;
    }
`;

export const MenuPaneContentWrapper = styled.div<{ maxWidth?: number }>`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 48px 48px 0 48px;
    justify-content: space-between;
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "670px")};

    a {
        &:hover {
            text-decoration: none;
        }
    }

    > div {
        width: calc(50% - 45px);
        max-height: 86px;
        max-width: 400px;
        margin-bottom: 48px;

        &:nth-child(2n) {
            margin-right: 30px;
        }
    }
`;

export const MenuUseCaseContentWrapper = styled(MenuPaneContentWrapper)`
    min-width: 750px;

    > div {
        width: calc(50% - 30px);

        &:nth-child(2n) {
            margin-right: 0;
        }

        &:not(:nth-child(3n)) {
            margin-right: 0;
        }
    }
`;

export const MMShowMobile = styled.div`
    display: block;
    
    ${media.custom(SM_BREAKPOINT)} {
        display: none;
    }
`;

export const MMShowDesktop = styled.div`
    display: none;
    
    ${media.custom(SM_BREAKPOINT)} {
        display: block;
    }
`;

const UL_BR = 860;

export const MarketingHeaderUL = styled.ul`
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    left: -3px;

    ${media.custom(UL_BR)} {
        flex-direction: row;
    }

    > li {
        &:not(:last-child) {
            ${media.custom(UL_BR)} {
                margin-right: 27px;
            }
        }

        > img {
            margin-right: 3px;
        }
    }

    ${V3Body} {
        ${media.phone} {
            font-size: 15px;
        }
    }
`;

export const MarketingHeaderLi = styled(V3Body)`
    display: flex;
    align-items: center;
`;

export const MarketingHeaderTry = styled(V3HeadlineH5)`
    color: ${({ theme }) => theme.v3.colors.blue};
    font-size: 18px;
`;


const HeaderButtonBr = 950;

export const MarketingSimpleHeaderButton = styled(V3Body)`
    display: flex;
    align-items: center;

    > div {
        &:first-child {
            ${media.custom(HeaderButtonBr)} {
                margin-right: 15px;
            }
        }

        &:nth-child(2){
            display: none;

            ${media.custom(HeaderButtonBr)} {
                display: block;
            }
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
`;
