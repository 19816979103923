import styled from "styled-components";
import { V3HeadlineH5 } from "../Typography";
import { ClampMixin, ClampMixinType } from "../ReusableStyledComponents/ClampMixin";
import { V3BodyGrey48 } from "../ReusableStyledComponents/V3BodyGrey48";
import SimpleBar from "simplebar-react";

export const ManualSelectItemsWraper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.v3.colors.grey96};
    }

    > div {
        &:first-child,
        &:last-child {
            flex-shrink: 0;
        }

        &:nth-child(2) {    
            width: calc(100% - 237px);
        }

        &:last-child {
            margin-left: 18px;
        }
    }
`;

export const MSCTitle = styled(V3HeadlineH5) <ClampMixinType>`
    ${ClampMixin};
`;

export const MSCSourceLabel = styled(V3BodyGrey48)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100%);
`;

export const MSCItemDateSourceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > * {
        &:first-child {
            margin-right: 20px;
            flex-shrink: 0;
        }
    }
`;

export const MSCFilterWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 24px 22px 28px 22px;
    border-top: 1px solid ${({ theme }) => theme.v3.colors.grey88};
    border-bottom: 1px solid ${({ theme }) => theme.v3.colors.grey88};
    justify-content: flex-start;

    > div {
        &:first-child {
            width: 100%;
            margin-right: 20px;
        }
    }
`;

export const MSCScrollWrapper = styled(SimpleBar).attrs<{ $boxShadow?: string }>(
    ({ $boxShadow }) => ({
        style: {
            boxShadow: $boxShadow ? $boxShadow : undefined
        },
    })
)`
    height: 360px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;
`;