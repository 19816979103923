import styled from "styled-components";

export const InlineLink = styled.a`
    color: ${({ theme }) => theme.colors.dodgerBlue};
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;