import { PayPalSVG } from "../SignUpImages/paypal";
import { V3Body, V3Caption } from "../Typography";
import { V3StyledButton } from "./V3Button.styles";
import { V3ButtonColor, V3ButtonIconPosition, V3ButtonProps, V3ButtonVariant, V3PaypalButtonProps } from "./V3Button.types";

export const V3Button = ({
    text,
    color = V3ButtonColor.primary,
    variant = V3ButtonVariant.large,
    iconPosition = V3ButtonIconPosition.left,
    className,
    icon,
    autoWidth,
    skipIconStyling = false,
    relativePosition = false,
    minWidth,
    ...rest
}: V3ButtonProps) => {

    const BtnText = variant === V3ButtonVariant.large ? V3Body : V3Caption;

    return <V3StyledButton
        {...rest}
        color={color}
        $variant={variant}
        $iconPosition={iconPosition}
        $hasText={!!text}
        className={className}
        $autoWidth={autoWidth}
        $skipIconStyling={skipIconStyling}
        $minWidth={minWidth}
        $relativePosition={relativePosition}
    >
        {icon && <div>{icon}</div>}
        {text && <BtnText as="div"><strong>{text}</strong></BtnText>}
    </V3StyledButton>
}

export const V3PayPalButton = ({
    color = V3ButtonColor.primary,
    variant = V3ButtonVariant.large,
    iconPosition = V3ButtonIconPosition.left,
    ...rest
}: V3PaypalButtonProps) => {
    return <V3StyledButton
        color={color}
        $variant={variant}
        $iconPosition={iconPosition}
        $hasText={false}
        $skipIconStyling
        {...rest}
        style={{
            height: "auto",
            maxWidth: 240,
        }}
    >
        <span style={{ color: "white", marginRight: 10, }}>Go to</span> <PayPalSVG white />
    </V3StyledButton>
}