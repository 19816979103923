import styled from "styled-components";
import { media, theme } from "../../theme/theme";
import { LEFT_SIDE_MENU_MOBILE_HEIGHT, TOP_MENU_HEIGHT } from "../../layout/SideBarLayout/SidebarLayout.styles";

export const V3ContentSizer = styled.div`
    min-height: calc(100vh - ${TOP_MENU_HEIGHT}px - ${LEFT_SIDE_MENU_MOBILE_HEIGHT}px);
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        min-height: calc(100vh - ${TOP_MENU_HEIGHT}px);
    }
`;