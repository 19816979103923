import styled from "styled-components";
import { V3BodyGrey48 } from "../ReusableStyledComponents/V3BodyGrey48";

export const V3LoaderSpinner = styled.div<{ $width?: number, $height?: number, $top?: string }>`
    border: 2px solid #f3f3f3;
    border-top: 2px solid ${({ theme }) => theme.v3.colors.blue};
    border-radius: 50%;
    width: ${({ $width }) => $width ? `${$width}px` : "24px"};
    height: ${({ $height }) => $height ? `${$height}px` : "24px"};
    animation: spinV3Loader 2s linear infinite;

    @keyframes spinV3Loader {
        0% { transform: rotate(0deg); }
        100% { transform:  rotate(360deg); }
    }
`;

const LoaderWrapper = styled.div<{ $height?: number, $fullScreen: boolean, $justify?: string, $background?: string, $direction?: string }>`
    display:flex;
    align-items: center;
    flex-direction: ${({ $direction }) => $direction || "row"};
    justify-content: ${({ $justify }) => $justify ? $justify : "center"};
    position: ${({ $fullScreen }) => $fullScreen ? `fixed` : "relative"};
    height: ${({ $height, $fullScreen }) => $fullScreen ? "100%" : $height ? `${$height}px` : undefined};
    width: ${({ $fullScreen }) => $fullScreen ? "100%" : null};
    z-index: ${({ $fullScreen }) => $fullScreen ? "10000" : null};
    background: ${({ $background }) => $background ? $background : "white"};
    transform: translate3d(0, 0, 0);
    will-change: contents;
    top: 0;
    left: 0;

    > div {
        &:first-child {
            margin-right: 18px;
            margin-bottom: ${({ $direction }) => $direction === "column" ? "20px" : undefined};
        }
    }
`

type V3LoaderProps = {
    height?: number,
    fullScreen?: boolean,
    justify?: string
    background?: string,
    loadingText?: string
    direction?: string,
}

export const V3Loader = ({ height, fullScreen = false, justify, background, loadingText = "Loading...", direction }: V3LoaderProps) => {
    return <LoaderWrapper $height={height} $fullScreen={fullScreen} $justify={justify} $background={background} $direction={direction}>
        <div>
            <V3LoaderSpinner />
        </div>
        <div>
            <V3BodyGrey48><strong>{loadingText}</strong></V3BodyGrey48>
        </div>
    </LoaderWrapper>
}