import { Dispatch, SetStateAction } from "react";

export function collapseSection(setState: Dispatch<SetStateAction<boolean>>, setIsTransitioning: Dispatch<SetStateAction<boolean>>, element: HTMLDivElement) {
    setIsTransitioning(true);

    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we 
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;

        // on the next frame (as soon as the previous style change has taken effect),
        // have the element transition to height: 0
        requestAnimationFrame(function () {
            element.style.height = 0 + 'px';
            setState(false);
            setIsTransitioning(false);
        });
    });
}

export function expandSection(setState: Dispatch<SetStateAction<boolean>>, setIsTransitioning: Dispatch<SetStateAction<boolean>>, element: HTMLDivElement) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + 'px';

    setIsTransitioning(true);

    const myHandler = function () {
        // remove "height" from the element's inline styles, so it can return to its initial value
        element.style.height = "auto";

        element.removeEventListener('transitionend', myHandler);

        setState(true);
        setIsTransitioning(false);
    }

    // when the next css transition finishes (which should be the one we just triggered)
    element.addEventListener('transitionend', myHandler);
}
