import { useContext, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { SetFooterVisible } from "../../layout/SideBarLayout"
import { V3Button } from "../Button"
import { V3ButtonColor } from "../Button/V3Button.types"
import { VerticalSpacing } from "../VerticalSpacing"
import { IPFWrapper } from "./InfinitePaginationFooter.styles"

export const MAX_PAGES_LOADED = 2;

interface InfinitePaginationFooterProps {
    contentLoading: boolean,
    contentCanFetchmore: boolean,
    numPagesLoaded: number,
    loadMore: () => void,
}

export const InfinitePaginationFooter = ({ contentLoading, numPagesLoaded, contentCanFetchmore, loadMore }: InfinitePaginationFooterProps) => {

    const setFooterVisible = useContext(SetFooterVisible);

    const IDLE_ABLE_TO_FETCH = !contentLoading && contentCanFetchmore;

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: "0px 0px 400px",
        initialInView: false
    });

    if (inView && IDLE_ABLE_TO_FETCH) {
        loadMore();
    }

    const canShowLoadMoreButton = numPagesLoaded > MAX_PAGES_LOADED;


    useEffect(() => {
        if ((canShowLoadMoreButton || !contentCanFetchmore)) {
            setFooterVisible(true);
        } else {
            setFooterVisible(false);
        }
    }, [canShowLoadMoreButton, contentCanFetchmore]);

    return <>
        {!contentLoading && IDLE_ABLE_TO_FETCH && canShowLoadMoreButton &&
            <VerticalSpacing top={20}>
                <IPFWrapper>
                    <V3Button
                        text="Load more"
                        onClick={loadMore}
                        color={V3ButtonColor.secondary}
                        autoWidth
                        minWidth={150}
                    />
                </IPFWrapper>
            </VerticalSpacing>
        }
        {IDLE_ABLE_TO_FETCH && !canShowLoadMoreButton && <div ref={ref} />}
    </>
}