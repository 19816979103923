interface V3IconAlertCircleProps extends React.SVGProps<SVGSVGElement> {
    stroke?: string;
    size?: number,
    strokeWidth?: string,
}

export const V3IconAlertCircle = ({ stroke = "#ABB0BA", size = 16, strokeWidth = "1.5" }: V3IconAlertCircleProps) => <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12ZM8 5.333V8M8 10.667h.007" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
</svg>




