import { BaseTracker } from "../BaseTracker";
import { getPageViewPayload } from "../utils/getPageViewData";
import { logError } from '../../logging/logSentryError';
import { UserStatus } from "authory-api-types/dist/enums";
import { AuthenticatedUser } from "../../types/user";

const getUserDataforIntercom = (user: (AuthenticatedUser)) => ({
    key: user ? user.slug : "visitor",
    user_id: user && user.communicationId,
    user_hash: user && user.intercomAuthenticationHash,
    email: user && user.email,
    name: user && `${user.firstName} ${user.lastName}`,
    active: !!(user && user.status === UserStatus.Active),
    url: user && `https://authory.com/${user.slug}`,
    firstName: user && user.firstName,
    lastName: user && user.lastName,
    custom_launcher_selector: '#open-intercom-bubble'
})

const getDateNow = () => Math.floor(Date.now() / 1000);

const shouldHideIntercomBubble = () => {
    if (
        window &&
        window.innerWidth &&
        window.innerWidth > 600
    ) {
        return false;
    }
    return true;
}

/**
 * IntercomTracker class responsible for tracking intercom events
 */
export class IntercomTracker extends BaseTracker {
    private hideIntercomBubble;

    constructor(user: AuthenticatedUser, hideIntercomBubble = false) {
        super(user, process.env.NEXT_PUBLIC_INTERCOM_APP_ID);

        const isGodMode = user?.isGodMode === true;
        if (isGodMode) return;

        this.hideIntercomBubble = hideIntercomBubble;

        if (process.browser && window.Intercom) {
            window.Intercom("update", {
                hide_default_launcher: shouldHideIntercomBubble() || hideIntercomBubble,
                ...getUserDataforIntercom(this.user),
            } as Intercom_.IntercomSettings);

            if (hideIntercomBubble) window.Intercom("hide");
        }
    }

    protected initializeInternal() {
        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                //@ts-ignore
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    //@ts-ignore
                    i.c(arguments);
                };
                //@ts-ignore
                i.q = [];
                //@ts-ignore
                i.c = function (args) {
                    //@ts-ignore
                    i.q.push(args);
                };
                //@ts-ignore
                w.Intercom = i;
                var l = function () {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
                    var x = d.getElementsByTagName('script')[0];
                    //@ts-ignore
                    x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === 'complete') {
                    l();
                    //@ts-ignore
                } else if (w.attachEvent) {
                    //@ts-ignore
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();

        window.Intercom('boot', {
            app_id: this.providerToken,
            // Hide intercom bubble if mobile or ir page requests it
            hide_default_launcher: shouldHideIntercomBubble() || this.hideIntercomBubble,
            ...getUserDataforIntercom(this.user),
        } as Intercom_.IntercomSettings);
    }

    protected trackPageViewInternal() {
        window.Intercom("trackEvent", "page_view", getPageViewPayload());
        window.Intercom("update", { last_request_at: getDateNow() } as Intercom_.IntercomSettings);
    }

    protected trackEventInternal(key: string, data: any) {
        window.Intercom("trackEvent", key, data);
        window.Intercom("update", { last_request_at: getDateNow() } as Intercom_.IntercomSettings);
    }

    protected shutdownInternal() {
        window.Intercom("shutdown");
    }

    public startSurvey(surveyId: number) {
        try {
            Intercom('startSurvey', surveyId);
        } catch { }
    }

    getVisitorId() {
        if (this.providerCanRun()) {
            try {
                return window.Intercom("getVisitorId");
            } catch (err) {
                logError({
                    error: "Error on MixpanelTracker - identify",
                    err
                });
            }
        }
        return null;
    }
}