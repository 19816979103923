export const CookieKeys = {
    auth_token: "token",
    referral_key: "refKey",
    referral_user: "refUser",
    referral_name: "refName",
    business_key: "businessKey",
    business_user: "businessUser",
    business_name: "businessName",
    follow_popup_closed: "follow_popup_closed",
    subscription_plan: "subscription_plan",
    pricing_pre_selected_plan: "pricing_pre_selected_plan",
    legacy_paypal_migration_key: "legacy_paypal_migration_key",
};

export const COOKIE_TOKEN_PATH = "/";
export const COOKIE_TOKEN_AGE = 10 * 365 * 24 * 60 * 60;