import { logError } from '../logging/logSentryError';
import { AuthenticatedUser } from '../types/user';
import { dedupedTrack, PAGE_VIEW_EVENT } from './utils/dedupedTrack';

/**
    * This class is meant to be used by each of the tracking providers we use
    * @param {AuthenticatedUser} user - the User data object
    * @param {string} providerToken - the identifier for the provider initilization
    * @param {string} dedupe - set if events for this provider should be deduped or not
    * @param {string} dedupeKey - the key used in local storage to track events for deduplication
*/
export abstract class BaseTracker {
    private static initialized: string[] = [];
    protected abstract initializeInternal(): void
    protected abstract trackPageViewInternal(data?: any): void
    protected abstract shutdownInternal(): void
    protected abstract trackEventInternal(key: string, data: any): void

    protected user
    protected providerToken
    protected dedupe
    protected dedupeKey

    constructor(user: AuthenticatedUser, providerToken: string | undefined, dedupe = false, dedupeKey = "") {
        this.user = user;
        this.providerToken = providerToken;
        this.dedupe = dedupe;
        this.dedupeKey = dedupeKey;

        this.trackPageView = this.trackPageView.bind(this);
        this.trackEvent = this.trackEvent.bind(this);
        this.initialize = this.initialize.bind(this);
        this.providerCanRun = this.providerCanRun.bind(this);

        if (this.providerCanRun()) this.initialize();
    }

    protected providerCanRun() {
        const isBrowser = process.browser;
        const envVarIsSet = !!this.providerToken;
        const isGodMode = this.user?.isGodMode === true;

        if (isBrowser && envVarIsSet && !isGodMode) {
            return true;
        }
        return false;
    }

    private initialize() {
        if (this.providerCanRun() && !BaseTracker.initialized.includes(this.providerToken || "")) {
            BaseTracker.initialized.push(this.providerToken || "");

            try {
                this.initializeInternal();
            } catch (err) {
                logError({
                    error: "Error on BaseTracker - initialize",
                    err,
                });
            }
        }
    }

    trackPageView(data?: any) {
        if (this.providerCanRun()) {
            try {
                if (!this.dedupe) {
                    this.trackPageViewInternal(data);
                } else {
                    dedupedTrack(this.dedupeKey, PAGE_VIEW_EVENT, this.trackPageViewInternal);
                }
            } catch (err) {
                logError({
                    error: "Error on BaseTracker - trackPageView",
                    err
                });
            }
        }
    }

    trackEvent(key: string, data: any = {}) {
        if (this.providerCanRun()) {
            try {
                if (!this.dedupe) {
                    this.trackEventInternal(key, data);
                } else {
                    dedupedTrack(this.dedupeKey, key, () => this.trackEventInternal(key, data));
                }
            } catch (err) {
                logError({
                    error: "Error on BaseTracker - trackEvent",
                    key,
                    err
                });
            }
        }
    }

    shutdown() {
        if (this.providerCanRun()) {
            try {
                this.shutdownInternal();
            } catch (err) {
                logError({
                    error: "Error on BaseTracker - shutdown",
                    err
                });
            }
        }
    }
}
