import styled from "styled-components";
import { media, theme } from "../../theme/theme";

export const V3LightPanelItem = styled.div``;

export const LPToggleTittle = styled.div`
    display: flex;
    align-items: center;

    > div {
        &:first-child {
            margin-right: 26px;
        }
    }
`;

export const V3LightPanelWrapper = styled.div<{ $hideMobileFrame?: boolean, $minHeight?: string }>`
    padding: ${({ $hideMobileFrame }) => !$hideMobileFrame ? "24px" : "0px"};
    border: ${({ $hideMobileFrame, theme }) => !$hideMobileFrame ? `1px solid ${theme.v3.colors.grey88}` : undefined};
    border-radius: ${({ $hideMobileFrame }) => !$hideMobileFrame ? "18px" : "0px"};
    min-height:  ${({ $minHeight }) => $minHeight ? $minHeight : undefined};

    ${media.custom(theme.breakpoints.phone)} {
        padding: 24px 36px;
        border: 1px solid ${({ theme }) => theme.v3.colors.grey88};
        border-radius: 18px;
    }

    > ${V3LightPanelItem} {
        &:not(:last-child) {
            margin-bottom: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid ${({ theme }) => theme.v3.colors.grey88};
        }
    }

    > ${LPToggleTittle} + ${V3LightPanelItem} {
        padding-top: 24px;
    }
`;