
import { Placement } from "@floating-ui/react";
import { V3Dropdown } from "../Dropdown"
import { V3IconChevronDown } from "../Icons"
import { V3CaptionGrey48 } from "../ReusableStyledComponents/V3CaptionGrey48";
import { V3HeadlineH5, V3Body } from "../Typography";
import { VerticalSpacing } from "../VerticalSpacing"
import { SSCPLabelWrapper, SSCPOptionTitleWrapper, SSCPSelectDropdownTrigger } from "./SidebarCustomizedSelect.styles";

interface SidebarCustomizedSelectProps {
    title?: string,
    optionTitle: string | JSX.Element,
    optionTitleIcon?: JSX.Element,
    caption?: string | JSX.Element,
    mobileTitle?: string,
    renderPopperElement: (active: boolean, setShowPopper: React.Dispatch<React.SetStateAction<boolean>>) => JSX.Element,
    maxWidth?: number,
    onShowChange?: (show: boolean) => void,
    placement?: Placement,
}

export const SidebarCustomizedSelect = ({ title, mobileTitle, caption, optionTitle, optionTitleIcon, renderPopperElement, maxWidth = 340, onShowChange, placement }: SidebarCustomizedSelectProps) => {
    const hasTitle = title && !!title.length;

    return <>
        <SSCPLabelWrapper>
            {hasTitle && <div><V3HeadlineH5>{title}</V3HeadlineH5></div>}
            <div> </div>
        </SSCPLabelWrapper>
        <VerticalSpacing top={hasTitle ? 6 : 0} bottom={hasTitle ? 24 : 0}>
            <V3Dropdown
                maxWidth={maxWidth}
                title={mobileTitle}
                renderReferenceElement={(_active, setShowPopper) => <SSCPSelectDropdownTrigger
                    tabIndex={0}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            setShowPopper(true);
                        }
                    }}
                >
                    <SSCPOptionTitleWrapper>
                        {optionTitleIcon}
                        <V3Body as="div"> {optionTitle}</V3Body>
                    </SSCPOptionTitleWrapper>
                    <div>
                        <V3IconChevronDown />
                    </div>
                </SSCPSelectDropdownTrigger>}
                renderPopperElement={renderPopperElement}
                strategy="fixed"
                placement={placement}
                onShowChange={onShowChange}
            />
            {caption && <VerticalSpacing top={6}>
                <V3CaptionGrey48 >{caption}</V3CaptionGrey48>
            </VerticalSpacing>}
        </VerticalSpacing>
    </>
}