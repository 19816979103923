
import { useEffect, useState } from "react";
import { SharedInputComponent } from "../SharedInputComponent";
import {
    V3Ipt,
    V3TA
} from "./V3TextInput.styles";
import { V3InputProps, V3StripeInputProps, V3TextAreaProps } from "./V3TextInput.types";

export const V3TextInput = ({ label, isRounded, caption, icon, error, actionText, actionClickHandler, hasClearIcon, onClearIconHandler, characterLimit, value, onChange, disableIconHover, tooltipContent, tooltipMaxWidth, ...rest }: V3InputProps) => {

    const hasError = !!error;

    const [val, setVal] = useState<string | number | undefined | readonly string[]>(value || "");

    useEffect(() => {
        if (typeof value !== "undefined") setVal(value);
    }, [value]);

    return <SharedInputComponent
        label={label}
        isRounded={isRounded}
        caption={caption}
        icon={icon}
        error={error}
        actionText={actionText}
        actionClickHandler={actionClickHandler}
        hasError={hasError}
        hasClearIcon={hasClearIcon}
        onClearIconHandler={onClearIconHandler}
        characterLimit={characterLimit}
        inputValue={val}
        disableIconHover={disableIconHover}
        tooltipContent={tooltipContent}
        tooltipMaxWidth={tooltipMaxWidth}
    >
        <V3Ipt
            as="input"
            type="text"
            $hasIcon={!!icon}
            $isRounded={isRounded}
            $hasError={hasError}
            $hasCharacterLimit={!!characterLimit}
            $hasRightIcon={hasError || hasClearIcon}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (characterLimit) {
                    e.target.value = e.target.value.substr(0, characterLimit);
                }
                if (onChange) {
                    onChange(e);
                } {
                    setVal(e.target.value);
                }
            }}
            value={val}
            {...rest}
        />
    </SharedInputComponent>
}

export const V3TextArea = ({ label, isRounded, caption, icon, error, actionText, actionClickHandler, rows = 3, hasClearIcon, onClearIconHandler, characterLimit, value, onChange, tooltipContent, tooltipMaxWidth, ...rest }: V3TextAreaProps) => {

    const hasError = !!error;

    const [val, setVal] = useState(value || "");

    useEffect(() => {
        if (typeof value !== "undefined") setVal(value);
    }, [value]);

    return <SharedInputComponent
        label={label}
        isRounded={isRounded}
        caption={caption}
        icon={icon}
        error={error}
        actionText={actionText}
        hasError={hasError}
        hasClearIcon={hasClearIcon}
        onClearIconHandler={onClearIconHandler}
        characterLimit={characterLimit}
        inputValue={val}
        actionClickHandler={actionClickHandler}
        tooltipContent={tooltipContent}
        tooltipMaxWidth={tooltipMaxWidth}
    >
        {/* @ts-ignore */}
        <V3TA
            type="text"
            $hasIcon={!!icon}
            $isRounded={isRounded}
            $hasError={hasError}
            rows={rows}
            $hasRightIcon={hasError || hasClearIcon}
            $hasCharacterLimit={!!characterLimit}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (characterLimit) {
                    e.target.value = e.target.value.substr(0, characterLimit);
                }
                if (onChange) {
                    onChange(e);
                } {
                    setVal(e.target.value);
                }
            }}
            value={val}
            {...rest}
        />
    </SharedInputComponent>
}

export const V3StripeInput = ({ label, error, inputField }: V3StripeInputProps) => {

    const hasError = !!error;

    return <SharedInputComponent
        label={label}
        error={error}
        hasError={hasError}
    >
        <V3Ipt as="span" style={{ display: "block" }}>
            {inputField}
        </V3Ipt>
    </SharedInputComponent>
}