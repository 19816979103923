
import { useRouter } from "next/router";
import { useContext } from "react";
import { CookieContext } from "../../context/cookieContext";
import { APPLICATION_ROUTES } from "../../types/routes";
import { getAnalyticsStatus } from "../../utils/analyticsStatus";
import { getAuthenticatedUserClient } from "../../utils/getAuthenticatedUser";
import { V3IconOverview, V3IconWeb } from "../Icons";
import { V3LinksPanel } from "./V3LinksPanel";

export const V3AnalyticsLinksPanel = () => {
    const router = useRouter();
    const token = useContext(CookieContext);
    const { authenticatedUser: { data } } = getAuthenticatedUserClient(token, router, true);
    const { isWebInactive } = getAnalyticsStatus(data);

    const links: any[] = [{
        icon: <V3IconOverview />,
        label: "Overview",
        href: APPLICATION_ROUTES.ANALYTICS,
    }];

    if (!isWebInactive) links.push({
        icon: <V3IconWeb />,
        label: "Details per article",
        href: APPLICATION_ROUTES.ANALYTICS_WEB,
    });

    return <V3LinksPanel
        links={links}
    />
}
