import styled from "styled-components";
import { media } from "../../theme/theme";
import { MarketingCTA } from "../MarketingCTA";
import { H4, MediumText } from "../Typography";

export const MobileMenuPanel = styled.div<{ open: boolean }>`
    width: 100%;
    padding: 16px 16px;
    height: calc(100vh - 68px);
    background: white;
    position: fixed;
    top: 68px;
    transform: translateX(${({ open }) => open ? "0%" : "100%"});
    overflow-y: scroll;
    z-index: -2;

    a {
        &:hover{
            text-decoration: none;
        }
    }

    > * {
        margin: 24px 0;
    }
`
export const MobilePanelH4 = styled(H4)`
    line-height: 21px;

    a {
        display: inline-block;
        color:  ${({ theme }) => theme ? theme.marketing.colors.dodgerBlue100 : null};

        &:hover{
            color:  ${({ theme }) => theme ? theme.marketing.colors.chathamsBlue : null};
        }
    }
`;

export const HR = styled.hr`
    border: ${({ theme }) => theme ? `1px solid ${theme.marketing.colors.blackPearl40}` : null};
    margin: 0;
`;

export const MobileLinkWrapper = styled.div`
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;

    > div {
        width: 50%;
        margin-bottom: 24px;

        ${media.custom(550)} {
            width: 33%;
        }

        ${media.custom(750)} {
            width: 25%;
        }
    }
`;

export const MobileLink = styled.div`
    display: flex;
    align-items: center;

    &:hover {
        ${MediumText} {
            color:  ${({ theme }) => theme ? theme.marketing.colors.chathamsBlue : null};
        }
    }

    > div {
        &:first-child {
            display: inline-flex;
            margin-right: 8px;
        }
    }

    ${MediumText} {
        font-weight: 300;
        color:  ${({ theme }) => theme ? theme.marketing.colors.dodgerBlue100 : null};
    }
`;

export const MobileLinkSimple = styled(MediumText)`
    color:  ${({ theme }) => theme ? theme.marketing.colors.blackPearl70 : null};
    font-weight: 300;

    &:hover {
        color:  ${({ theme }) => theme ? theme.marketing.colors.blackPearl100 : null};
    }
`;

export const MobilePanelButtonsWrapper = styled.div`
    display: flex;
    margin-top: 40px;
    padding-bottom: 120px;

    > div {
        margin-right: 15px;
    }

    
`;

export const MobileButtonCTA = styled(MarketingCTA)`
    padding-left: 25px;
    padding-right: 25px;
`;